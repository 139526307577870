import ScheduleIcon from '@mui/icons-material/Schedule';
import {
  TimePicker
} from '@mui/lab';
import { Box, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlerts } from '../../context/alert';
import { secondsFromDate } from '../../utils/dates';
import TimeWindowForm from './TimeWindowForm';
import WeeklyScheduleForm from './WeeklyScheduleForm';
import momentTimezone from "moment-timezone";
import MomentAdapter from "@material-ui/pickers/adapter/moment";

const useStyles = makeStyles((theme) => ({
  verticalSpacing: {
    marginTop: theme.spacing(2)
  }
}));

const PeriodicSlotForm = ({ slot, onChangeSlot, timezone }) => {
  const { t, i18n } = useTranslation();
  const { moment } = new MomentAdapter({ instance: momentTimezone });

  const { showError } = useAlerts();
  const classes = useStyles();
  const [valueFrequency, setValueFrequency] = useState(60);

  useEffect(() => {
    console.log("LOAD PERIODIC", slot);
    let time = moment();
    time.hour(0);
    time.minute(0);
    time.second(0);
    time.seconds(slot.periodicFrequency);
    setValueFrequency(time)
  }, [slot.periodicFrequency]);

  return (
    <>
      <TimeWindowForm slot={slot} onChangeSlot={onChangeSlot} timezone={timezone} />
      <WeeklyScheduleForm slot={slot} onChangeSlot={onChangeSlot} />
      <Box display="flex" flexDirection={"column"} justifyContent={'center'} alignItems="streach">
        <TimePicker
          margin="normal"
          label={t('slot.freq')}
          inputVariant="outlined"
          fullWidth
          keyboardIcon={<ScheduleIcon />}
          size="small"
          value={valueFrequency}
          ampm={false}
          inputFormat="HH:mm:ss"
          minutesStep={1}
          views={['hours', 'minutes', 'seconds']}
          onChange={(date) => {
            if (date) {
              const frequency = moment(date);
              const numberFrequency = frequency.hour() * 3600 + frequency.minute() * 60 + frequency.seconds();
              if (numberFrequency < 60) {
                showError('La frequenza deve essere almeno 1 minuto!');
                return;
              }
              slot.periodicFrequency = numberFrequency
              onChangeSlot(slot);
            }
          }}
          renderInput={props => <TextField className={classes.verticalSpacing} {...props} />}
        />
      </Box>
    </>
  );
};

PeriodicSlotForm.propTypes = {
  slot: PropTypes.object,
  onChangeSlot: PropTypes.func
};

export default PeriodicSlotForm;
