/* eslint-disable max-len */
import { Card, Container, Step, StepLabel, Stepper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../../components/Page';
import CreateCreativeAddAssets from './CreateCreativeAddAssets';
import CreateCreativeInfoStep from './CreateCreativeInfoStep';

const steps = [
  {
    label: "Informazioni sull'Ad Unit",
    component({ ...props }) { return <CreateCreativeInfoStep {...props} />; },
  },
  {
    label: 'Aggiunta Assets',
    component({ ...props }) { return <CreateCreativeAddAssets {...props} />; }
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  header: {
    width: '100%',
    marginBottom: 15,
    padding: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%'
  },
}));

const CreateCreative = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);
  const [idCreative, setIdCreative] = useState('');

  return (
    <Page className={classes.root} title="Customers">
      <Container className={classes.container}>
        <Card className={classes.header}>
          <Stepper activeStep={activeStep}>
            {steps.map(({ label }) => (
              <Step key={label}>
                <StepLabel>{t(label)}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Card>

        {steps[activeStep].component({
          next: () => setActiveStep(activeStep + 1),
          onCreateAdUnit: (id) => setIdCreative(id),
          idAdUnit: idCreative
        })}
      </Container>
    </Page>
  );
};

export default CreateCreative;
