import {
  Box,
  Card, CardContent, CardHeader, Divider, useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Bar } from 'react-chartjs-2';

function MultiLineGraph({
  dataset, title, action
}) {
  const theme = useTheme();

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: true },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
        },
      ],
    },
    tooltips: {
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
    },
  };
  return (
    <Card>
      <CardHeader
        title={title}
        action={action}
      />
      <Divider />
      <CardContent>
        <Box
          height={400}
          position="relative"
        >
          <Bar
            data={dataset}
            options={options}
          />
        </Box>
      </CardContent>
    </Card>
  );
}

MultiLineGraph.propTypes = {
  dataset: PropTypes.object,
  title: PropTypes.string,
  action: PropTypes.node
};

export default MultiLineGraph;
