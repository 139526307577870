import {
    Box, Card, CardContent, Typography, Alert, CardHeader, Divider, Table, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody
} from '@mui/material';
import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import momentTimezone from "moment-timezone";
import MomentAdapter from "@material-ui/pickers/adapter/moment";

const ScheduleList = ({ scheduleUrl, type }) => {

    const { moment } = new MomentAdapter({ instance: momentTimezone });
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [list, setList] = useState([])
    const [error, setError] = useState(false);
    const errorCount = useRef(0);

    const timeInterval = useRef(null);

    const filterData = (res) => {
        let Filtered = [];
        if (type === "ALL") {
            Filtered = res.events.filter(value => value.h_ty.toUpperCase() === "PROGRAMME" || value.h_ty.toUpperCase() === "AUTOPROMO")
        } else {
            Filtered = res.events.filter(value => value.h_ty.toUpperCase() === type.toUpperCase())
        }
        setList(Filtered);
    }

    const fetchList = () => {
        const req = new XMLHttpRequest();
        req.addEventListener("load", (e) => {
            filterData(JSON.parse(e.currentTarget.responseText))
        })
        req.addEventListener("error", (e) => {
            setError(true);
            if (errorCount.current < 10) {
                errorCount.current = errorCount.current + 1;
            } else {
                clearInterval(timeInterval.current)
                timeInterval.current = null;
                errorCount.current = 0;
            }
        })
        req.open("GET", scheduleUrl);
        req.send();
    }

    useEffect(() => {
        fetchList();

        if (timeInterval.current === null) {
            timeInterval.current = setInterval(() => {
                fetchList();
            }, 120000)
        }

        return () => {
            if (timeInterval.current !== null) {
                clearInterval(timeInterval.current)
                timeInterval.current = null;
                errorCount.current = 0;
            }
        }
    }, [])

    return (
        <Card style={{ marginTop: '15px' }}>
            <CardHeader
                title={t('campaign.automatic.gpt.titleScheduler')}
            />
            <Divider />
            <CardContent>
                {error ?
                    <Alert severity="warning">
                        {t('campaign.automatic.gpt.noEventsError')}
                    </Alert>
                    : list.length > 0 ?
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Alias</TableCell>
                                        {type === "ALL" ? <TableCell>Alias</TableCell> : null}
                                        <TableCell align="right">START</TableCell>
                                        <TableCell align="right">END</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {list.map(item => {
                                        return <TableRow>
                                            <TableCell>{item.h_ti}</TableCell>
                                            {type === "ALL" ? <TableCell>{item.h_ty?.toUpperCase()}</TableCell> : null}
                                            <TableCell align="right">{moment(item.start_date_iso).format("DD/MM/YYYY HH:mm:SS")}</TableCell>
                                            <TableCell align="right">{moment(item.end_date_iso).format("DD/MM/YYYY HH:mm:SS")}</TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        : t('campaign.automatic.gpt.loading')}
            </CardContent>
        </Card>

    );
};

ScheduleList.propTypes = {
    scheduleUrl: PropTypes.string,
    type: PropTypes.string,
};

export default ScheduleList;
