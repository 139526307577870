import { Check } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import Requests from '../../../BackofficeRequests/Requests';
import Page from '../../../components/Page';
import SearchBar from '../../../components/SearchBar';
import { useAlerts } from '../../../context/alert';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));

const Campaigns = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { showError, showConfirm } = useAlerts();

  const [channels, setChannels] = useState([]);
  const [channelsCount, setChannelsCount] = useState(0);
  const [automaticCampaigns, setAutomaticCampaigns] = useState([]);
  const [automaticCampaignsCount, setAutomaticCampaignsCount] = useState(0);
  const [manualCampaigns, setManualCampaigns] = useState([]);
  const [manualCampaignsCount, setManualCampaignsCount] = useState(0);
  const [tablePageAutomatic, setTablePageAutomatic] = useState(0);
  const [rowsLimitAutomatic, setRowsLimitAutomatic] = useState(10);
  const [tablePageManual, setTablePageManual] = useState(0);
  const [rowsLimitManual, setRowsLimitManual] = useState(10);
  const [searchString, setSearchString] = useState('');
  const [selectedChannel, setSelectedChannel] = useState(null);

  const fetchCampaigns = async () => {
    try {
      let data = null;

      setManualCampaigns([]);
      setAutomaticCampaigns([]);
      setManualCampaignsCount(0);
      setAutomaticCampaignsCount(0);

      if (selectedChannel === null || (channels[selectedChannel] !== undefined && !channels[selectedChannel].isBroadbandy)) {
        const reqManual = await Requests.instance.campaign.getList(tablePageManual + 1, rowsLimitManual, searchString, channels[selectedChannel] !== undefined ? channels[selectedChannel].advertisingScheduleIds[0] : 0, 'manual');
        console.log("reqManual", reqManual);
        data = reqManual.data;
        setManualCampaigns(data.docs.reverse());
        setManualCampaignsCount(parseInt(data.totalDocs, 10));
        if (+data.totalDocs > 0 && data.docs.length === 0) setTablePageManual(tablePageManual - 1);

      } else {
        const reqAutomatic = await Requests.instance.campaign.getList(tablePageAutomatic + 1, rowsLimitAutomatic, searchString, channels[selectedChannel] !== undefined ? channels[selectedChannel].advertisingScheduleIds[0] : 0, 'automatic');
        console.log("reqAutomatic", reqAutomatic);
        data = reqAutomatic.data;
        setAutomaticCampaigns(data.docs.reverse());
        setAutomaticCampaignsCount(parseInt(data.totalDocs, 10));
        if (+data.totalDocs > 0 && data.docs.length === 0) setTablePageAutomatic(tablePageAutomatic - 1);

        const reqManual = await Requests.instance.campaign.getList(tablePageManual + 1, rowsLimitManual, searchString, channels[selectedChannel] !== undefined ? channels[selectedChannel].advertisingScheduleIds[0] : 0, 'manual');
        data = reqManual.data;
        setManualCampaigns(data.docs.reverse());
        setManualCampaignsCount(parseInt(data.totalDocs, 10));
        if (+data.totalDocs > 0 && data.docs.length === 0) setTablePageManual(tablePageManual - 1);

      }
    } catch (err) {
      showError(err.message);
    }
  };

  const fetchChannels = async (page, limit, filter) => {
    try {
      const { data } = await Requests.instance.channel.getList(
        page + 1, limit, filter,
      );
      const _default = [{ name: 'ALL', advertisingScheduleIds: ['0'] }]
      const result = _default.concat(data.docs)

      setChannels(result);
      setChannelsCount(result.length);
      setSelectedChannel(0);
    } catch (err) {
      showError(err.message);
    }
  };

  useEffect(() => {
    fetchChannels(0, 300, '');
  }, [])

  useEffect(() => {
    if (channels.length > 0) {
      fetchCampaigns();
    }
  }, [tablePageManual, tablePageAutomatic, rowsLimitManual, rowsLimitAutomatic, searchString, selectedChannel, channels]);

  const handleSearchStringChange = (event) => {
    const search = event.target.value.toLowerCase();
    if (search.length > 0) setSearchString(search);
    else setSearchString('');
  };

  const handleDeleteCampaig = async (id) => {
    try {
      const res = await showConfirm(t('campaign.confirmDel'));
      if (!res) return;
      await Requests.instance.campaign.delete(id);
      fetchCampaigns();
    } catch (err) {
      showError(err.message);
    }
  };

  return (
    selectedChannel !== null ?
      <Page className={classes.root} title="Campaign List">
        <Container>

          <Grid container
            mb={3}
          >
            <Grid item xs={8}>
              {channels.length > 1 ?
                <FormControl fullWidth>
                  <InputLabel id="select-channel-label">{t('campaign.filterByChannel')}</InputLabel>
                  <Select
                    labelId="select-channel-label"
                    id="select-channel"
                    value={selectedChannel}
                    label={t('campaign.filterByChannel')}
                    onChange={(data) => {
                      console.log("CHANGE SELECTED CHANNEL:", data.target);
                      setSelectedChannel(data.target.value)
                    }}
                    renderValue={(selected) => channels[selected].name}
                  >
                    {channels.length > 0 ?
                      channels.map((item, index) => item !== undefined ? typeof item.advertisingScheduleIds === 'object' ? <MenuItem name={item.name} value={index}>
                        <Checkbox checked={selectedChannel === index} />
                        <ListItemText primary={item.name} />
                      </MenuItem> : null : null)
                      : null}
                  </Select>
                </FormControl>
                : null}
            </Grid>
            <Grid item xs={4} display='flex' alignItems='center' justifyContent='flex-end'>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                to="/app/campaigns/new"
                component={Link}
              >
                {t('campaign.new')}
              </Button>
            </Grid>
          </Grid>

          <SearchBar
            placeholder={t('campaign.search')}
            onRefresh={() => fetchCampaigns()}
            onSearchStringChange={handleSearchStringChange}
          />

          {automaticCampaigns.length > 0 ?
            <Box mt={2}>
              <h3>Campagne Automatiche</h3>
              <Card>
                <PerfectScrollBar>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('campaign.title')}</TableCell>
                        <TableCell>{t('campaign.desc')}</TableCell>
                        <TableCell />
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(automaticCampaigns.map((campaign, index) => (
                        <TableRow key={campaign.id}>
                          <TableCell style={{ maxWidth: 200 }}>
                            <Typography noWrap>{campaign.title}</Typography>
                          </TableCell>
                          <TableCell style={{ maxWidth: 200 }}>
                            <Typography noWrap>{t(campaign.description)}</Typography>
                          </TableCell>
                          <TableCell padding="checkbox">
                            <Button
                              size="small"
                              endIcon={<ChevronRightIcon />}
                              component={Link}
                              to={`/app/campaigns/${campaign.id}`}
                            >
                              {t('more')}
                            </Button>
                          </TableCell>
                          <TableCell padding="checkbox">
                            {/* <IconButton
                              style={{ color: '#dc3545' }}
                              onClick={() => handleDeleteCampaig(campaign.id)}
                              size="large">
                              <DeleteIcon />
                            </IconButton> */}
                          </TableCell>
                        </TableRow>
                      )))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    component="div"
                    count={automaticCampaignsCount}
                    rowsPerPage={rowsLimitAutomatic}
                    page={tablePageAutomatic}
                    rowsPerPageOptions={[5, 10, 25]}
                    onPageChange={(e, page) => setTablePageManual(page)}
                    labelRowsPerPage={t('Righe per pagina')}
                    onRowsPerPageChange={(e) => {
                      setRowsLimitAutomatic(parseInt(e.target.value, 10));
                      setTablePageAutomatic(0);
                    }}
                  />
                </PerfectScrollBar>
              </Card>
            </Box>
            : null}

          <Box mt={2}>
            {automaticCampaigns.length > 0 ? <h3>Campagne manuali</h3> : null}
            <Card>
              <PerfectScrollBar>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('campaign.title')}</TableCell>
                      <TableCell>Publisher</TableCell>
                      <TableCell>{t('Costo')}</TableCell>
                      <TableCell>{t('campaign.desc')}</TableCell>
                      <TableCell>{t('campaign.impLim')}</TableCell>
                      <TableCell>{t('campaign.dayLim')}</TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(manualCampaigns.map((campaign, index) => (
                      <TableRow key={campaign.id}>
                        <TableCell style={{ maxWidth: 200 }}>
                          <Typography noWrap>{campaign.title}</Typography>
                        </TableCell>
                        <TableCell style={{ maxWidth: 200 }}>
                          <Typography noWrap>{campaign.publisher}</Typography>
                        </TableCell>
                        <TableCell>{campaign.cost}</TableCell>
                        <TableCell style={{ maxWidth: 200 }}>
                          <Typography noWrap>{campaign.description}</Typography>
                        </TableCell>
                        <TableCell>{campaign.limit}</TableCell>
                        <TableCell>{campaign.dayLimit}</TableCell>
                        <TableCell padding="checkbox">
                          <IconButton
                            style={{ color: '#dc3545' }}
                            onClick={() => handleDeleteCampaig(campaign.id)}
                            size="large">
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell padding="checkbox">
                          <Button
                            size="small"
                            endIcon={<ChevronRightIcon />}
                            component={Link}
                            to={`/app/campaigns/${campaign.id}`}
                          >
                            {t('more')}
                          </Button>
                        </TableCell>
                      </TableRow>
                    )))}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={manualCampaignsCount}
                  rowsPerPage={rowsLimitManual}
                  page={tablePageManual}
                  rowsPerPageOptions={[5, 10, 25]}
                  onPageChange={(e, page) => setTablePageManual(page)}
                  labelRowsPerPage={t('Righe per pagina')}
                  onRowsPerPageChange={(e) => {
                    setRowsLimitManual(parseInt(e.target.value, 10));
                    setTablePageManual(0);
                  }}
                />
              </PerfectScrollBar>
            </Card>
          </Box>
        </Container>
      </Page>
      : <Page className={classes.root} title="Campaign List"><Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '80vh' }}><CircularProgress disableShrink /></Container></Page>
  );
};

export default Campaigns;
