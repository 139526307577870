import { Box, IconButton, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
// material
import { alpha } from '@mui/material/styles';
import { useCallback, useRef, useState } from 'react';
// components
import MenuPopover from '../../components/MenuPopover';
import i18n, { getStoredLang, locales, setStoredLang } from '../../utils/i18n';

// ----------------------------------------------------------------------

const LANGS = Object.keys(locales).map(locale => ({
  value: locale,
  label: locales[locale],
  icon: `/static/icons/ic_flag_${locale}.svg`
}));

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(() => getStoredLang());
  const lang = LANGS.find(l => l.value === currentLanguage);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const updateLanguage = useCallback((langCode) => {
    setStoredLang(langCode);
    setCurrentLanguage(langCode);
    i18n.changeLanguage(langCode);
    handleClose();
  }, [setCurrentLanguage, handleClose]);

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          width: 30,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <img src={lang.icon} alt={lang.label} />
      </IconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLanguage}
              onClick={() => updateLanguage(option.value)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <Box component="img" alt={option.label} src={option.icon} style={{ width: 30}}/>
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
