import { AxiosInstance, AxiosResponse } from 'axios';

export default abstract class AbstractRequests<T> {
  protected instance: AxiosInstance;

  private baseURL: string;

  public constructor(instance: AxiosInstance, baseUrl: string) {
    this.instance = instance;
    this.baseURL = baseUrl;
  }

  public abstract getList(page: number, limit: number, filter: string): Promise<AxiosResponse<any>>;

  public getById(id: string): Promise<AxiosResponse<any>> {
    return this.instance.get(this.baseURL + id);
  }

  public create(data: T): Promise<AxiosResponse<any>> {
    return this.instance.post(this.baseURL, data);
  }

  public delete(id: string): Promise<AxiosResponse<any>> {
    return this.instance.delete(this.baseURL + id);
  }

  public update(id: string, data: T): Promise<AxiosResponse<any>> {
    return this.instance.patch(this.baseURL + id, data);
  }
}
