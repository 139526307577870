import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Card,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import Requests from '../../BackofficeRequests/Requests';
import Page from '../../components/Page';
import SearchBar from '../../components/SearchBar';
import { useAlerts } from '../../context/alert';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const AdUnitsView = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [adUnits, setAdUnits] = useState([]);
  const [adUnitsCount, setAdUnitsCount] = useState(0);
  const [tablePage, setTablePage] = useState(0);
  const [rowsLimit, setRowsLimit] = useState(10);
  const [searchString, setSearchString] = useState('');
  const { showError, showConfirm } = useAlerts();

  const fetchAdUnits = async (page, limit, filter) => {
    try {
      const { data } = await Requests.instance.creative.getList(page + 1, limit, filter);
      setAdUnits(data.docs.reverse());
      setAdUnitsCount(parseInt(data.totalDocs, 10));
      if (+data.totalDocs > 0 && data.docs.length === 0) setTablePage(tablePage - 1);
    } catch (error) {
      showError(error.message);
    }
  };

  useEffect(() => {
    fetchAdUnits(tablePage, rowsLimit, searchString);
  }, [tablePage, rowsLimit, searchString]);

  const handleDeleteAdUnit = async (id) => {
    try {
      const res = await showConfirm(t('adUnit.confirmDel'));
      if (!res) return;
      await Requests.instance.creative.delete(id);
      fetchAdUnits(tablePage, rowsLimit, searchString);
    } catch (error) {
      showError(error.message);
    }
  };

  const handleSearchStringChange = (event) => {
    const search = event.target.value.toLowerCase();
    if (search.length > 0) setSearchString(search);
    else setSearchString('');
  };

  return (
    <Page className={classes.root} title="Customers">
      <Container>
        <Box
          display="flex"
          justifyContent="flex-end"
          mb={1}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            to="/app/creatives/new"
            component={Link}
          >
            {t('adUnit.new')}
          </Button>
        </Box>
        <SearchBar
          onRefresh={() => fetchAdUnits(tablePage, rowsLimit, searchString)}
          onSearchStringChange={handleSearchStringChange}
          placeholder={t('adUnit.search')}
        />
        <Box mt={2}>
          <Card>
            <PerfectScrollBar>
              <Box minWidth={600}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('Titolo')}</TableCell>
                      <TableCell>{t('Descrizione')}</TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {adUnits.map((ad) => (
                      <TableRow key={ad.id}>
                        <TableCell style={{ maxWidth: 200 }}>
                          <Typography noWrap>{ad.title}</Typography>
                        </TableCell>
                        <TableCell style={{ maxWidth: 250 }}>
                          <Typography noWrap>{ad.description}</Typography>
                        </TableCell>
                        <TableCell padding="checkbox">
                          <IconButton
                            color='error'
                            onClick={() => handleDeleteAdUnit(ad.id)}
                            size="large">
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell padding="checkbox">
                          <Button
                            size="small"
                            endIcon={<ChevronRightIcon />}
                            component={Link}
                            to={`/app/creatives/${ad.id}`}
                          >
                            {t('more')}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={adUnitsCount}
                  rowsPerPage={rowsLimit}
                  page={tablePage}
                  rowsPerPageOptions={[5, 10, 25]}
                  onPageChange={(e, page) => setTablePage(page)}
                  labelRowsPerPage={t('Righe per pagina')}
                  onRowsPerPageChange={(e) => {
                    setRowsLimit(parseInt(e.target.value, 10));
                    setTablePage(0);
                  }}
                />
              </Box>
            </PerfectScrollBar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default AdUnitsView;
