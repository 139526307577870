import { AxiosInstance, AxiosResponse } from 'axios';
import {
  DailySlot, PeriodicSlot, SlotModel, SlotType
} from '../models/slot';
import { secondsFromDate, timeFromDate } from '../utils/dates';

export default class SlotRequests {
  private instance: AxiosInstance;

  public constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  public async getList(idSchedule: string): Promise<AxiosResponse<any>> {
    const slotsResponse = await this.instance.get(
      `/advertising-schedules/${idSchedule}/schedule-slots`
    );

    const slots = slotsResponse.data.docs as unknown as SlotModel[];

    slots.forEach((s) => {
      if (s.windowStart) {
        (s.windowStart as any) = new Date(s.windowStart);
      }
      if (s.windowEnd) {
        (s.windowEnd as any) = new Date(s.windowEnd);
      }
    });

    return slotsResponse;
  }

  public async getByCampaign(
    campaignId: string,
    type: SlotType,
    page = 1,
    limit = 1
  ): Promise<AxiosResponse<any>> {
    const where = { campaignId, type };
    const slotsResponse = await this.instance.get('/schedule-slots', {
      params: { where: JSON.stringify(where), page, limit }
    });

    const slots = slotsResponse.data.docs as unknown as SlotModel[];

    slots.forEach((s) => {
      if (s.windowStart) {
        (s.windowStart as any) = new Date(s.windowStart);
      }

      if (s.windowEnd) {
        (s.windowEnd as any) = new Date(s.windowEnd);
      }
    });

    return slotsResponse;
  }

  public createFixed(
    idSchedule: string,
    fixedTime: Date,
    duration: number,
    priority: number,
    preload: boolean,
    preloadTime: number,
    campaignId?: string
  ): Promise<AxiosResponse<any>> {
    return this.instance.post(
      `/advertising-schedules/${idSchedule}/schedule-slots`,
      {
        type: SlotType.FIXED,
        fixedTime,
        duration,
        preload,
        preloadTime,
        priority,
        campaignId
      }
    );
  }

  public createDaily(
    idSchedule: string,
    slot: DailySlot,
    campaignId: string
  ): Promise<AxiosResponse<any>> {
    return this.instance.post(
      `/advertising-schedules/${idSchedule}/schedule-slots`,
      {
        type: SlotType.DAILY,
        dailyTime: slot.dailyTime,
        windowStart: slot.windowStart,
        windowEnd: slot.windowEnd,
        weeklySchedule: slot.weeklySchedule,
        duration: slot.duration,
        preload: slot.preload,
        preloadTime: slot.preloadTime,
        priority: slot.priority,
        campaignId
      }
    );
  }

  public createPeriodic(
    idSchedule: string,
    slot: PeriodicSlot,
    campaignId: string
  ): Promise<AxiosResponse<any>> {
    return this.instance.post(
      `/advertising-schedules/${idSchedule}/schedule-slots`,
      {
        type: SlotType.PERIODIC,
        windowStart: slot.windowStart,
        windowEnd: slot.windowEnd,
        weeklySchedule: slot.weeklySchedule,
        periodicTimeStart: slot.periodicTimeStart,
        periodicTimeEnd: slot.periodicTimeEnd,
        periodicFrequency: slot.periodicFrequency,
        duration: slot.duration,
        preload: slot.preload,
        preloadTime: slot.preloadTime,
        priority: slot.priority,
        campaignId
      }
    );
  }

  public delete(slotId: string): Promise<AxiosResponse<any>> {
    return this.instance.delete(`/schedule-slots/${slotId}`);
  }

  public update(slot: any): Promise<AxiosResponse<any>> {
    let data;
    let fixedTime;
    switch (slot.type) {
      case SlotType.FIXED:
        fixedTime = slot.fixedDateTime;
        data = {
          duration: slot.duration,
          preload: slot.preload,
          preloadTime: slot.preloadTime,
          priority: slot.priority,
          fixedTime
        };
        break;
      case SlotType.DAILY:
        data = {
          duration: slot.duration,
          preload: slot.preload,
          preloadTime: slot.preloadTime,
          dailyTime: slot.dailyTime,
          priority: slot.priority,
          windowStart: slot.windowStart,
          windowEnd: slot.windowEnd,
          weeklySchedule: slot.weeklySchedule
        };
        break;
      case SlotType.PERIODIC:
        data = {
          duration: slot.duration,
          preload: slot.preload,
          preloadTime: slot.preloadTime,
          priority: slot.priority,
          windowStart: slot.windowStart,
          windowEnd: slot.windowEnd,
          weeklySchedule: slot.weeklySchedule,
          periodicTimeStart: slot.periodicTimeStart,
          periodicTimeEnd: slot.periodicTimeEnd,
          periodicFrequency: secondsFromDate(slot.periodicFrequency)
        };
        break;
      default:
        break;
    }
    return this.instance.patch(`/schedule-slots/${slot.id}`, data);
  }
}
