export const timeFromDate = (date: Date, sec = false): string => {
  const res = date.toLocaleTimeString();
  if (!sec) return res.substr(0, 5);
  return res;
};

export const secondsFromDate = (date: any): number => {
  console.log("SECONDS FROM DATE", date);
  if (typeof date === "number") {
    return date;
  } else {
    return date.hour() * 3600 + date.minute() * 60 + date.second();
  }
};

export const timeFromString = (date: string): Date => {
  const d = new Date();
  const x = date.split(':');
  if (x.length === 2) d.setHours(+x[0], +x[1]);
  else if (x.length === 3) d.setHours(+x[0], +x[1], +x[2]);
  return d;
};

export const timeFromSeconds = (sec: string): Date => {
  const d = new Date();
  const s = +sec % 60;
  const m = Math.floor(+sec / 60);
  const h = Math.floor(+sec / 3600);
  d.setHours(h, m, s);
  return d;
};

export const datesAreOnSameDay = (first: Date, second: Date): boolean => {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
};
