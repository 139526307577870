import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Card, CardContent, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  card: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
    height: 1
  },
  cardContent: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    paddingLeft: 40,
    paddingRight: 40,
  }
}));

const GenericStep = ({
  children, title, onBack, onNext, onDone, notScroll = false
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>

        {title
          ? (
          <Box marginBottom={3}>
            <Typography variant="h2" align="center" style={{ marginBottom: 4 }}>{title}</Typography>
            <Divider />
          </Box>
          )
          : undefined}
        <Box flexGrow={1} flexShrink={1} flexBasis="auto" style={!notScroll ? { overflowY: 'scroll' } : undefined}>
          {children}
        </Box>
        {onNext || onDone
          ? (
            <>
              <Divider />
              <Box width="100%" display="flex" justifyContent="flex-end" marginTop={1}>
                {onBack
                  ? (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                    onClick={onBack}
                    style={{ marginRight: 8 }}
                  >
                    {t('back')}
                  </Button>
                  )
                  : undefined}
                {onNext
                  ? (
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowForwardIcon />}
                    onClick={onNext}
                  >
                    {t('next')}
                  </Button>
                  )
                  : undefined}
                {onDone
                  ? (
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<DoneIcon />}
                    onClick={onDone}
                  >
                    {t('finish')}
                  </Button>
                  )
                  : undefined}
              </Box>
            </>
          )
          : undefined}
      </CardContent>
    </Card>
  );
};

GenericStep.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  onNext: PropTypes.func,
  onDone: PropTypes.func,
  onBack: PropTypes.func,
  notScroll: PropTypes.bool
};

export default GenericStep;
