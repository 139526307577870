import {
  Box,
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle, Typography
} from '@mui/material';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const DialogConfirmDelAlert = ({
  openDialog,
  dialogText,
  dialogTitle,
  onClose,
  onDelete
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Dialog open={openDialog} maxWidth="xs">
      <Box p={3}>
        <DialogTitle>
          <Box textAlign="center">
            <HighlightOffTwoToneIcon
              style={{ fontSize: 54 }}
              color="error"
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="h4"
            align="center"
            style={{ marginBottom: 15 }}
          >
            {dialogTitle !== '' ? dialogTitle : t('Sei sicuro?')}
          </Typography>
          <Typography align="center">{dialogText}</Typography>
        </DialogContent>
        <DialogActions style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 10,
          marginLeft: 15,
          marginRight: 15
        }}
        >
          <Button variant="contained" onClick={onClose}>
            {t('Annulla')}
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: 'rgb(220, 53, 69)',
              color: '#fff'
            }}
            onClick={onDelete}
          >
            {t('Elimina')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
DialogConfirmDelAlert.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  dialogText: PropTypes.string.isRequired,
  dialogTitle: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};
export default DialogConfirmDelAlert;
