import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translationEN.json';
import translationIT from '../locales/it/translationIT.json';
import translationUS from '../locales/us/translationUS.json';

const resources = {
  it: {
    translation: translationIT
  },
  en: {
    translation: translationEN
  },
  us: {
    translation: translationUS
  }
};

const LANG_STORAGE_KEY = 'ui-language';

export const getStoredLang = () => {
  const lang = localStorage.getItem(LANG_STORAGE_KEY);

  if (lang === null || !(lang in resources)) {
    return Object.keys(resources)[0];
  }
  return lang;
};

export const setStoredLang = (lang) => {
  localStorage.setItem(LANG_STORAGE_KEY, lang);
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: getStoredLang(),
    resources,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

export const locales = {
  it: 'Italiano',
  en: 'English',
  us: 'English (US)',
  es: 'Espanol'
};
