import PropTypes from 'prop-types';
import React, { forwardRef, HTMLAttributes, Ref } from 'react';
import { Helmet } from 'react-helmet';

interface PageProps extends HTMLAttributes<HTMLElement> {
  title?: string;
}

const Page = forwardRef(({
  children,
  title = '',
  ...rest
}: PageProps, ref: Ref<HTMLDivElement>) => {
  return (
    <div
      ref={ref}
      {...rest}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
});

Page.displayName = 'Page';

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string,
};

export default Page;
