import momentTimezone from "moment-timezone";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
const { moment } = new MomentAdapter({ instance: momentTimezone });

/* eslint-disable max-classes-per-file */
export type WeeklyScedule = {
  mon: boolean,
  tue: boolean,
  wed: boolean,
  thu: boolean,
  fri: boolean,
  sat: boolean,
  sun: boolean
};

export interface SlotModel {
  id: string;
  type: SlotType;
  fixedTime?: string;
  dailyTime?: string;
  windowStart?: string;
  windowEnd?: string;
  weeklySchedule?: WeeklyScedule;
  periodicTimeStart?: string;
  periodicTimeEnd?: string;
  periodicFrequency?: number;
  duration: number;
  priority: number;
  preload?: boolean;
  preloadTime?: number;
  advertisingScheduleId: string;
}

export enum SlotType {
  FIXED = 'fixed',
  DAILY = 'daily',
  PERIODIC = 'periodic'
}

export class Slot {
  id: string;

  duration: number;

  advertisingScheduleId: string;

  priority: number;

  preload: boolean;

  preloadTime: number;

  campaignId: string;

  type: string;

  constructor() {
    this.id = '';
    this.duration = 15;
    this.advertisingScheduleId = '';
    this.priority = 1;
    this.preload = false;
    this.preloadTime = 0;
    this.campaignId = '';
    this.type = '';
  }
}

export class FixedSlot extends Slot {
  fixedDateTime: any;

  constructor(fixedTime: any = null) {
    super();
    console.log("NEW FIXED SLOT", this);
    this.type = SlotType.FIXED;
  }

  get fixedTime(): Date {
    return this.fixedDateTime;
  }
}

export class DailySlot extends Slot {
  windowStart: any = null;
  windowEnd: any = null;
  dailyTime: any = null;
  weeklySchedule: WeeklyScedule;

  constructor() {
    super();
    this.type = SlotType.DAILY;
    this.weeklySchedule = {
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
      sun: true
    };
  }
}

export class PeriodicSlot extends Slot {
  windowStart: any;
  windowEnd: any;
  weeklySchedule: WeeklyScedule;
  periodicTimeStart: any;
  periodicTimeEnd: any;
  periodicFrequency: number = 60;

  constructor() {
    super();
    this.type = SlotType.PERIODIC;
    this.weeklySchedule = {
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
      sun: true
    };
  }
}
