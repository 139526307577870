/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-mixed-operators */
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SvgIcon,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Requests from '../../BackofficeRequests/Requests';
import GenericStep from '../../components/GenericStep';
import { useAlerts } from '../../context/alert';
import ArrowIcon from '../../icons/Arrow';

const useStyles = makeStyles(() => ({
  title: {
    height: 30,
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10
  }
}));

const actionButtons = [
  { id: 'red', value: 'Rosso', color: 'red', icon: undefined, disabled: true },
  { id: 'green', value: 'Verde', color: 'green', icon: undefined, disabled: true },
  { id: 'yellow', value: 'Giallo', color: 'yellow', icon: undefined, disabled: true },
  { id: 'blue', value: 'Blue', color: 'blue', icon: undefined, disabled: true},
  { id: 'ok', value: 'OK Button', color: undefined, icon: undefined, },
  { id: 'left', value: 'Arrow Left', color: undefined, icon: 'left' },
  { id: 'right', value: 'Arrow Right', color: undefined, icon: 'right' },
  { id: 'up', value: 'Arrow Up', color: undefined, icon: 'up' },
  { id: 'down', value: 'Arrow Down', color: undefined, icon: 'down' }
];

const actionTypes = [
  { id: 'open_minisite', value: 'Apri minisite' },
  { id: 'enter_application', value: 'Deep-link' },
  { id: 'open_video', value: 'Apri il video' },
  { id: 'open_image', value: 'Apri immagine' }
];

const CreateCreativeInfoStep = ({
  back, next, onCreateAdUnit, canSelectAdUnit = false, idAdUnit
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [actionButton, setActionButton] = useState('');
  const [actionType, setActionType] = useState('');
  const [actionValue, setActionValue] = useState('');
  const [isAction, setIsAction] = useState(false);
  const [error, setError] = useState(false);
  const [isCreate, setIsCreate] = useState(!canSelectAdUnit);

  const [adUnits, setAdUnits] = useState([]);
  const [selectedAdUnit, setSelectedAdUnit] = useState(idAdUnit);
  const [searchString, setSearchString] = useState('');
  const { showError } = useAlerts();

  const fetchAdUnits = async () => {
    try {
      const res = await Requests.instance.creative.getList(1, 100, searchString);
      setAdUnits(res.data.docs);
    } catch (err) {
      showError(err.message);
    }
  };

  useEffect(() => {
    if (canSelectAdUnit) fetchAdUnits();
  }, [searchString]);

  const checkActionValue = () => {
    if (!isAction) return true;
    if (actionValue === '') return false;
    if (actionType === 'open_video') {
      const regexp = /^http.*mp4|m3u8|mpd$/;
      return Boolean(actionValue.match(regexp));
    }
    return true;
  };

  const handleSubmit = async () => {
    if (canSelectAdUnit && !isCreate) {
      if (selectedAdUnit === '') { showError('Devi selezionare una creative'); return; }
      try {
        onCreateAdUnit(selectedAdUnit);
        next(2);
        return;
      } catch (err) {
        showError(err.message);
      }
    }
    if (title === '' ||
      isAction && (actionButton === '' || actionType === '' || !checkActionValue())) {
      setError(true);
      return;
    }
    try {
      const res = await Requests.instance.creative.createCreative(
        title, desc, isAction, actionButton, actionType, actionValue
      );
      onCreateAdUnit(res.data.id);
      next();
    } catch (err) {
      showError(err.message);
    }
  };

  return (
    <GenericStep
      title={t('adUnit.stepTitle')}
      onNext={handleSubmit}
      onBack={canSelectAdUnit ? () => back() : undefined}
    >
      <Grid container spacing={3} justifyContent="center">
        {canSelectAdUnit
          ? (
            <Grid item>
              <ButtonGroup size="small" color="primary">
                <Button
                  variant={isCreate ? 'outlined' : 'contained'}
                  onClick={() => (isCreate ? setIsCreate(false) : undefined)}
                >
                  {t('adUnit.select')}
                </Button>
                <Button
                  variant={isCreate ? 'contained' : 'outlined'}
                  onClick={() => (isCreate ? undefined : setIsCreate(true))}
                >
                  {t('adUnit.createNew')}
                </Button>
              </ButtonGroup>
            </Grid>
          )
          : undefined}
        <Grid item sm={12} />
        {isCreate
          ? (
            <>
              <Grid item md={4}>
                <Typography variant="h3" className={classes.title}>Info</Typography>
                <TextField
                  label={t('Titolo')}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder={t('Titolo')}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  error={error && title === ''}
                  helperText={error ? t('adUnit.titleReq') : ''}
                />
                <TextField
                  label={t('Descrizione')}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder={t('Descrizione Ad Unit (facoltativo)')}
                  multiline
                  rows={4}
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                />
              </Grid>

              <Grid item md={1} />

              <Grid item md={4}>
                <Typography variant="h3" className={classes.title}>
                  Action
                  <Switch
                    size="small"
                    value={isAction}
                    onChange={(e) => setIsAction(e.target.checked)}
                  />
                </Typography>
                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={isAction && error && actionButton === ''}
                  disabled={!isAction}
                >
                  <InputLabel id="action-button">{t('Pulsante Azione')}</InputLabel>
                  <Select
                    labelId="action-button"
                    label={t('Pulsante Azione')}
                    value={actionButton}
                    onChange={(e) => setActionButton(e.target.value)}
                  >
                    {actionButtons.map((btn) => (
                      <MenuItem disabled={btn.disabled ?? false} key={btn.id} value={btn.id}>
                        <Box display="flex" alignItems="center">
                          {btn.color && <span style={{ height: 18, width: 18, backgroundColor: btn.color, borderRadius: 50, marginRight: 10 }} />}
                          {btn.icon && <ArrowIcon direction={btn.id} color="#fff" size={18} containerStyle={{ marginRight: 10, display: "flex", alignItems: 'center' }} />}
                          {t(btn.value)}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                  {isAction && error && actionButton === ''
                    ? (
                      <FormHelperText>{t('adUnit.abReq')}</FormHelperText>
                    )
                    : undefined}
                </FormControl>

                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={isAction && error && actionType === ''}
                  disabled={!isAction}
                >
                  <InputLabel id="action-type">{t('Tipo Azione')}</InputLabel>
                  <Select
                    labelId="action-type"
                    label={t('Tipo Azione')}
                    value={actionType}
                    onChange={(e) => setActionType(e.target.value)}
                  >
                    {actionTypes.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {t(type.value)}
                      </MenuItem>
                    ))}
                  </Select>
                  {isAction && error && actionType === ''
                    ? (
                      <FormHelperText>{t('adUnit.atReq')}</FormHelperText>
                    )
                    : undefined}
                </FormControl>

                <TextField
                  label="URL"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder="URL"
                  value={actionValue}
                  onChange={(e) => setActionValue(e.target.value)}
                  error={error && !checkActionValue()}
                  helperText={error && !checkActionValue() ? t('URL non valida!') : ''}
                  disabled={!isAction}
                />
              </Grid>
            </>
          )
          : (
            <Paper variant="outlined" style={{ minWidth: '50%' }}>
              <Box paddingLeft={2} paddingRight={2}>
                <TextField
                  fullWidth
                  margin="dense"
                  placeholder={t('Cerca')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                    endAdornment: searchString !== ''
                      ? (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setSearchString('')}
                            size="small"
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )
                      : undefined
                  }}
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                />
              </Box>
              <TableContainer style={{ height: 320 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>{t('Titolo')}</TableCell>
                      <TableCell>{t('Descrizione')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {adUnits.map((ad) => (
                      <TableRow key={ad.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={ad.id === selectedAdUnit}
                            onClick={() => setSelectedAdUnit(ad.id)}
                          />
                        </TableCell>
                        <TableCell>{ad.title}</TableCell>
                        <TableCell>{ad.description}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
      </Grid>
    </GenericStep>
  );
};

CreateCreativeInfoStep.propTypes = {
  back: PropTypes.func,
  next: PropTypes.func.isRequired,
  onCreateAdUnit: PropTypes.func.isRequired,
  canSelectAdUnit: PropTypes.bool,
  idAdUnit: PropTypes.string
};

export default CreateCreativeInfoStep;
