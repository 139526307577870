import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Requests from '../../../BackofficeRequests/Requests';
import { useAlerts } from '../../../context/alert';
import ArrowIcon from '../../../icons/Arrow';

const actionButtons = [
  { id: 'red', value: 'Rosso', color: 'red', icon: undefined, disabled: true },
  { id: 'green', value: 'Verde', color: 'green', icon: undefined, disabled: true },
  { id: 'yellow', value: 'Giallo', color: 'yellow', icon: undefined, disabled: true },
  { id: 'blue', value: 'Blue', color: 'blue', icon: undefined, disabled: true },
  { id: 'ok', value: 'OK Button', color: undefined, icon: undefined, },
  { id: 'left', value: 'Arrow Left', color: undefined, icon: 'left' },
  { id: 'right', value: 'Arrow Right', color: undefined, icon: 'right' },
  { id: 'up', value: 'Arrow Up', color: undefined, icon: 'up' },
  { id: 'down', value: 'Arrow Down', color: undefined, icon: 'down' }
];

const actionTypes = [
  { id: 'open_minisite', value: 'Apri minisite' },
  { id: 'enter_application', value: 'Deep-link' },
  { id: 'open_video', value: 'Apri il video' },
  { id: 'open_image', value: 'Apri immagine' }
];

const useStyles = makeStyles(() => ({
  title: {
    height: 30,
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10
  }
}));

const CreativeInfoDetail = ({ idCreative }) => {
  const { showError, showMessage } = useAlerts();
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [actionType, setActionType] = useState('');
  const [actionButton, setActionButton] = useState('');
  const [actionValue, setActionValue] = useState('');
  const [isAction, setIsAction] = useState(false);
  const [creative, setCreative] = useState();

  const [modifyInfo, setModifyInfo] = useState(false);

  const fetchCreative = async () => {
    try {
      const { data } = await Requests.instance.creative.getById(idCreative);
      setCreative(data);
      setTitle(data.title);
      setDesc(data.description);
      if (data.actionType) {
        setIsAction(true);
        setActionType(data.actionType);
        setActionButton(data.actionButton);
        setActionValue(data.actionValue);
      } else {
        setIsAction(false);
        setActionType('');
        setActionButton('');
        setActionValue('');
      }
    } catch (err) {
      showError(err.message);
    }
  };

  useEffect(() => {
    fetchCreative();
  }, []);

  const checkActionValue = () => {
    if (!isAction) return true;
    if (actionValue === '') return false;
    if (actionType === 'open_video') {
      const regexp = /^http.*mp4$/;
      return Boolean(actionValue.match(regexp));
    }
    return true;
  };

  const handleSaveInfo = async () => {
    const newCreative = { ...creative };
    if (title === '') {
      showError('Devi inserire un titolo!');
      return;
    }
    newCreative.title = title;
    newCreative.description = desc;
    if (isAction) {
      if (actionButton === '' || actionType === '') {
        showError("Devi completare i campi per l'azione!");
        return;
      }
      if (!checkActionValue()) {
        showError('Inserisci una URL valida!');
        return;
      }
      newCreative.actionButton = actionButton;
      newCreative.actionType = actionType;
      newCreative.actionValue = actionValue;
    } else if (creative.actionType) {
      newCreative.$unset = {
        actionButton: true,
        actionType: true,
        actionValue: true
      };
    }
    try {
      await Requests.instance.creative.update(idCreative, newCreative);
      fetchCreative();
      setModifyInfo(false);
      showMessage('Info aggiornate correttamente!');
    } catch (err) {
      showError(err.message);
    }
  };

  return (
    <Card>
      <CardHeader
        title={<Typography variant="h4">Creative Info</Typography>}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2} justifyContent="space-around">
          <Grid item md={4} sm={12}>
            <Typography variant="h3" className={classes.title}>Info</Typography>
            <TextField
              label={t('Titolo')}
              variant="outlined"
              fullWidth
              margin="normal"
              placeholder={t('Titolo')}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              disabled={!modifyInfo}
            />
            <TextField
              label={t('Descrizione')}
              variant="outlined"
              fullWidth
              margin="normal"
              placeholder={t('Descrizione Ad Unit (facoltativo)')}
              multiline
              rows={5}
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              disabled={!modifyInfo}
            />
          </Grid>
          <Grid item md={4} sm={12}>
            <Typography variant="h3" className={classes.title}>
              Action
              <Switch
                size="small"
                checked={isAction}
                onChange={(e) => setIsAction(e.target.checked)}
                disabled={!modifyInfo}
              />
            </Typography>
            <TextField
              label={t('Pulsante Azione')}
              placeholder={t('Pulsante Azione')}
              variant="outlined"
              fullWidth
              margin="normal"
              select
              value={actionButton}
              onChange={(e) => setActionButton(e.target.value)}
              disabled={!modifyInfo || !isAction}
            >
              {actionButtons.map((btn) => {
                return (
                  <MenuItem disabled={btn.disabled ?? false} key={btn.id} value={btn.id}>
                    <Box display="flex" alignItems="center">
                      {btn.color && <span style={{ height: 18, width: 18, backgroundColor: btn.color, borderRadius: 50, marginRight: 10 }} />}
                      {btn.icon && <ArrowIcon direction={btn.id} color="#fff" size={18} containerStyle={{ marginRight: 10, display: "flex", alignItems: 'center' }} />}
                      {t(btn.value)}
                    </Box>
                  </MenuItem>
                )
              })}
            </TextField>
            <TextField
              label={t('Tipo Azione')}
              placeholder={t('Tipo Azione')}
              variant="outlined"
              fullWidth
              margin="normal"
              select
              value={actionType}
              onChange={(e) => setActionType(e.target.value)}
              disabled={!modifyInfo || !isAction}
            >
              {actionTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {t(type.value)}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="URL"
              variant="outlined"
              fullWidth
              margin="normal"
              placeholder="URL"
              value={actionValue}
              onChange={(e) => setActionValue(e.target.value)}
              disabled={!modifyInfo || !isAction}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box display="flex" justifyContent="flex-end" pr={2} pt={1} pb={1}>
        {modifyInfo
          ? (
            <Button
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSaveInfo}
            >
              {t('campaign.save')}
            </Button>
          )
          : (
            <Button
              color="primary"
              variant="contained"
              startIcon={<SettingsIcon />}
              onClick={() => setModifyInfo(true)}
            >
              {t('campaign.update')}
            </Button>
          )}
      </Box>
    </Card>
  );
};

CreativeInfoDetail.propTypes = {
  idCreative: PropTypes.string
};

export default CreativeInfoDetail;
