import { Alert, Box, Button, Container, Snackbar, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Requests from '../../BackofficeRequests/Requests';
import Page from '../../components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const RegisterView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [emailSignIn, setEmailSignIn] = useState('');

  useEffect(() => {
    if (!firebase.auth().isSignInWithEmailLink(window.location.href)) navigate('/login', { replace: true });
    const params = new URLSearchParams(window.location.search);
    const email = params.get('emailSignin');
    setEmailSignIn(email || '');
  });

  const [snackAlert, setSnackAlert] = useState({ open: false, text: '' });

  const submit = async (values) => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      try {
        const authResult = await firebase.auth().signInWithEmailLink(emailSignIn,
          window.location.href);

        await authResult.user.updatePassword(values.password);
        const token = await authResult.user.getIdToken();
        await Requests.instance.finalizeRegistration(values.name, token);
        navigate('/app/dashboard', { replace: true });
      } catch (err) {
        setSnackAlert({ open: true, text: err.message });
      }
    }
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackAlert({ open: false, text: '' });
  };

  return (
    <Page
      className={classes.root}
      title="Register"
    >
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackAlert.open}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert
          severity="error"
          color="error"
          elevation={6}
          variant="filled"
          onClose={handleCloseSnack}
        >
          {snackAlert.text}
        </Alert>
      </Snackbar>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              name: '',
              password: '',
            }}
            validationSchema={
              Yup.object().shape({
                name: Yup.string().max(255).required(t('registration.requiredName')),
                password: Yup.string()
                  .max(255)
                  .required(t('registration.requiredPassword'))
                  .min(8, t('registration.shortPassword')),
              })
            }
            onSubmit={submit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    {t('registration.title')}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    {t('registration.subtitle')}
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label={t('registration.name')}
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Email"
                  margin="normal"
                  name="email"
                  type="email"
                  value={emailSignIn}
                  variant="outlined"
                  disabled
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t('registration.button')}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default RegisterView;
