/* eslint-disable no-await-in-loop */
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Alert,
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  Divider,
  Grid,
  IconButton, Skeleton, TablePagination,
  Toolbar,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import Requests from '../../../BackofficeRequests/Requests';
import { useAlerts } from '../../../context/alert';
import { useConfig } from '../../../context/config';
import AssetBuilder from '../../asset/AssetBuilder';
import { ScreenWidgetDisplayType as DisplayType } from '../../asset/AssetBuilder/ScreenWidget';

const useStyles = makeStyles(() => ({
  mainTitle: {
    fontSize: 24,
  },
  assetTitle: {
    fontSize: 18,
  },
  deleteButton: {
    backgroundColor: '#dc3545',
    color: 'white',
  },
  assetImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: 168,
  },
  title: {
    height: 30,
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10
  }
}));

const CreativeAssetsDetail = ({ idCreative }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showError, showConfirm, showMessage } = useAlerts();
  const config = useConfig();

  const [isLoading, setIsLoading] = useState(true);
  const [assetList, setAssetList] = useState([]);
  const [assetsCount, setAssetsCount] = useState(0);
  const [tablePage, setTablePage] = useState(0);
  const [rowsLimit, setRowsLimit] = useState(8);
  const [openBuilder, setOpenBuilder] = useState(false);

  const fetchFilters = async (docs) => {
    const newDocs = [];
    try {
      for (let i = 0; i < docs.length; ++i) {
        const a = { ...docs[i] };
        const { data } = await Requests.instance.asset.getFilters(a.id);
        let arr = JSON.parse(data[0].value);
        if (arr.length > 0) a[data[0].type] = arr.join(', ');
        arr = JSON.parse(data[1].value);
        if (arr.length > 0) a[data[1].type] = arr.join(', ');
        newDocs.push(a);
      }
      setAssetList(newDocs);
    } catch (err) {
      showError(err.message);
    }
  };

  const fetchAssets = async (page, limit) => {
    try {
      const { data } = await Requests.instance.creative.getAssetList(idCreative, page + 1, limit);

      if (data.docs && data.docs.length > 0) {
        await Promise.all(
          data.docs.filter((asset) => asset.type === DisplayType.ANIMATED)
            .map(async (asset) => {
              const animationRequest = await axios.get(asset.publicUrl);

              asset.animationJSON = animationRequest.data;
            })
        );
      }

      setAssetList(data.docs);
      setAssetsCount(+data.totalDocs);
      if (+data.totalDocs > 0 && data.docs.length === 0) setTablePage(tablePage - 1);

      await fetchFilters(data.docs);
    } catch (err) {
      showError(err.message);
    }
  };

  useEffect(() => {
    setIsLoading(false);
    fetchAssets(tablePage, rowsLimit).then(() => {
      setIsLoading(false);
    });
  }, [tablePage, rowsLimit]);

  const handleDeleteAsset = async (idAsset) => {
    try {
      if (!(await showConfirm('Sicuro di voler eliminare questo asset?'))) return;
      await Requests.instance.asset.delete(idAsset);
      showMessage('Asset eliminato!');
      fetchAssets(tablePage, rowsLimit);
    } catch (err) {
      showError(err.message);
    }
  };

  const assetsContent = useMemo(() => {
    if (assetList.length === 0) {
      return (
        <Grid item>
          <Alert severity="warning">
            {t('Non ci sono asset in questa creative!')}
          </Alert>
        </Grid>
      );
    }

    return assetList.map((asset) => {
      let assetImage;

      if (asset.type === DisplayType.ANIMATED) {
        assetImage = (
          <Lottie
            className={classes.assetImage}
            height={168}
            options={{
              animationData: asset.animationJSON,
              loop: true,
              autoplay: true,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid meet'
              }
            }}
            isPaused={false}
            isStopped={false}
          />
        );
      } else if (asset.type === DisplayType.INTERACTIVE_OVERLAY
        || asset.type === DisplayType.INTERACTIVE_VIDEO_SCALING) {
        assetImage = (
          <a href={asset.publicUrl} target="_blank" rel="noreferrer">
            <img className={classes.assetImage} alt="banner" src="/static/images/iframe-icon.svg" />
          </a>
        );
      } else {
        assetImage = (
          <img className={classes.assetImage} alt="banner" src={asset.publicUrl} />
        );
      }

      return (
        <Grid key={asset.id} item sm={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h4" align="center">{asset.title.toUpperCase()}</Typography>
              <Divider />
              <Box display="flex" justifyContent="center" mt={1} mb={1}>
                <Chip
                  color="primary"
                  label={asset.type}
                  size="small"
                />
              </Box>
              {config.tags ? (
                <Typography>
                  <b>TAGS:</b>
                  {' '}
                  {asset.tag ?? 'Not set'}
                </Typography>
              ) : undefined}
              {asset.vendor ? (
                <Typography>
                  <b>VENDORS:</b>
                  {` ${asset.vendor}`}
                </Typography>
              ) : null}
              <Box display="flex" justifyContent="center" height={200} padding={2}>
                {assetImage}
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  className={classes.deleteButton}
                  startIcon={<DeleteForeverIcon />}
                  variant="contained"
                  onClick={() => handleDeleteAsset(asset.id)}
                >
                  {t('Elimina')}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      );
    });
  }, [assetList, t]);

  return (
    <Card>
      <CardHeader
        title={'Assets'}
        action={(
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenBuilder(true)}
          >
            {t('Aggiungi')}
          </Button>
        )}
        titleTypographyProps={{ variant: 'h4' }}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={3} justify="center">
          {isLoading ? (
            <Skeleton variant="rect" height={168} width={112} />
          ) : assetsContent}
        </Grid>
      </CardContent>
      <Divider />
      <Box m={1}>
        <TablePagination
          component="div"
          count={assetsCount}
          rowsPerPage={rowsLimit}
          page={tablePage}
          rowsPerPageOptions={[4, 8, 24]}
          onPageChange={(e, page) => setTablePage(page)}
          labelRowsPerPage={t('Righe per pagina')}
          onRowsPerPageChange={(e) => {
            setRowsLimit(parseInt(e.target.value, 10));
            setTablePage(0);
          }}
        />
      </Box>

      <Dialog
        fullScreen
        open={openBuilder}
        onClose={() => setOpenBuilder(false)}
      >
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              onClick={() => {
                setOpenBuilder(false);
                fetchAssets(tablePage, rowsLimit);
              }}
              size="large">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <AssetBuilder
          idAdUnit={idCreative}
          onUpload={() => {
            setOpenBuilder(false);
            fetchAssets(tablePage, rowsLimit);
          }}
        />
      </Dialog>
    </Card>
  );
};

CreativeAssetsDetail.propTypes = {
  idCreative: PropTypes.string,
};

export default CreativeAssetsDetail;
