import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import TimeWindowForm from './TimeWindowForm';
import WeeklyScheduleForm from './WeeklyScheduleForm';

const DailySlotForm = ({ slot, onChangeSlot, timezone }) => {
  const { t, i18n } = useTranslation();

  return (
    <Fragment>
      <TimeWindowForm slot={slot} onChangeSlot={onChangeSlot} timezone={timezone} />
      <WeeklyScheduleForm slot={slot} onChangeSlot={onChangeSlot} timezone={timezone} />
    </Fragment>
  );
};

DailySlotForm.propTypes = {
  slot: PropTypes.object,
  onChangeSlot: PropTypes.func
};

export default DailySlotForm;
