/* eslint-disable no-await-in-loop */
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Requests from '../../BackofficeRequests/Requests';
import GenericStep from '../../components/GenericStep';
import { useAlerts } from '../../context/alert';
import { useConfig } from '../../context/config';

const SelectAssetStep = ({
  next, back, onCreateAdUnit, idAdUnit
}) => {
  const { t, i18n } = useTranslation();

  const [adUnits, setAdUnits] = useState([]);
  const [selectedAdUnit, setSelectedAdUnit] = useState(idAdUnit);
  const [searchString, setSearchString] = useState('');
  const { showError } = useAlerts();

  const [openModal, setOpenModal] = useState(false);
  const [assets, setAssets] = useState([]);
  const config = useConfig();

  const fetchAdUnits = async () => {
    try {
      const res = await Requests.instance.creative.getList(1, 100, searchString);
      setAdUnits(res.data.docs);
    } catch (err) {
      showError(err.message);
    }
  };

  useEffect(() => {
    fetchAdUnits();
  }, [searchString]);

  const handleSubmit = () => {
    if (selectedAdUnit === '') {
      showError(t('adUnit.creativeSelectionError'));
      return;
    }
    onCreateAdUnit(selectedAdUnit);
    next();
  };

  const fetchFilters = async (docs) => {
    const newDocs = [];
    try {
      for (let i = 0; i < docs.length; ++i) {
        const a = { ...docs[i] };
        const { data } = await Requests.instance.asset.getFilters(a.id);
        let arr = JSON.parse(data[0].value);
        if (arr.length > 0) a[data[0].type] = arr.join(', ');
        arr = JSON.parse(data[1].value);
        if (arr.length > 0) a[data[1].type] = arr.join(', ');
        newDocs.push(a);
      }
      setAssets(newDocs);
    } catch (err) {
      showError(err.message);
    }
  };

  const openInfoBox = async (id) => {
    try {
      const { data } = await Requests.instance.creative.getAssetList(id, 1, 20);
      setAssets(data.docs);
      setOpenModal(true);
      fetchFilters(data.docs);
    } catch (err) {
      showError(err.message);
    }
  };

  const closeInfoBox = () => {
    setOpenModal(false);
    setAssets([]);
  };

  return (
    <GenericStep
      title={t('adUnit.select')}
      onNext={handleSubmit}
      onBack={() => {
        back();
      }}
    >
      <Grid container spacing={3} justifyContent="center">
        <Grid item>
          <Paper variant="outlined" style={{ minWidth: '50%' }}>
            <Box paddingLeft={2} paddingRight={2}>
              <TextField
                fullWidth
                margin="dense"
                placeholder={t('Cerca')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                  endAdornment: searchString !== ''
                    ? (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setSearchString('')}
                          size="small"
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    )
                    : undefined
                }}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              />
            </Box>
            <TableContainer style={{ height: 320 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>{t('Titolo')}</TableCell>
                    <TableCell>{t('Descrizione')}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adUnits.map((ad) => (
                    <TableRow key={ad.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={ad.id === selectedAdUnit}
                          onClick={() => setSelectedAdUnit(ad.id)}
                        />
                      </TableCell>
                      <TableCell>{ad.title}</TableCell>
                      <TableCell>{ad.description}</TableCell>
                      <TableCell padding="checkbox">
                        <IconButton onClick={() => openInfoBox(ad.id)} size="large">
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={openModal}
        onClose={closeInfoBox}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Typography variant="h4">Assets</Typography>
        </DialogTitle>
        <DialogContent dividers>
          {assets.length > 0
            ? (
              <Grid container justifyContent="center" spacing={2}>
                {assets.map((asset) => (
                  <Grid item sm={4} key={asset.id}>
                    <Card>
                      <CardContent>
                        <Typography variant="h4" align="center">{asset.title.toUpperCase()}</Typography>
                        <Divider />
                        <Box display="flex" justifyContent="center" mt={1} mb={1}>
                          <Chip
                            color="primary"
                            label={asset.type}
                            size="small"
                          />
                        </Box>
                        {config.tags
                          ? (
                            <Typography>
                              <b>TAGS:</b>
                              {' '}
                              {asset.tag ?? '_'}
                            </Typography>
                          )
                          : undefined}
                        <Typography>
                          <b>VENDORS:</b>
                          {' '}
                          {asset.vendor ?? ''}
                        </Typography>
                        <Box height={200} padding={2}>
                          <img
                            style={{
                              maxWidth: '100%',
                              maxHeight: 168,
                            }}
                            alt="banner"
                            src={asset.publicUrl}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )
            : (
              <Box display="flex" justifyContent="center" pt={2} pb={10}>
                <Alert severity="warning">
                  Questa creative non contiene assets
                </Alert>
              </Box>
            )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeInfoBox}
            variant="contained"
          >
            {t('Chiudi')}
          </Button>
        </DialogActions>
      </Dialog>
    </GenericStep>
  );
};

SelectAssetStep.propTypes = {
  next: PropTypes.func,
  back: PropTypes.func,
  onCreateAdUnit: PropTypes.func,
  idAdUnit: PropTypes.string
};

export default SelectAssetStep;
