import { AxiosInstance, AxiosResponse } from 'axios';
import { NewAssetModel } from '../models/asset';
import { CreativeModel } from '../models/creative';
import escapeRegExp from '../utils/escapeRegExp';
import AbstractRequests from './AbstractRequests';

export default class CreativeRequests extends AbstractRequests<CreativeModel> {
  private static BASE_URL = '/creatives/';

  public constructor(instance: AxiosInstance) {
    super(instance, CreativeRequests.BASE_URL);
  }

  public getList(page: number, limit: number, filter: string): Promise<AxiosResponse<any>> {
    const matchString = escapeRegExp(filter);
    const where = (typeof filter === 'string' && filter.length > 0)
      ? { title: { like: matchString, options: 'i' } }
      : {};
    return this.instance.get(CreativeRequests.BASE_URL, {
      params: { page, limit, where }
    });
  }

  public createCreative(
    title: string,
    description: string,
    isAction: boolean,
    actionButton: string,
    actionType: string,
    actionValue: string,
  ): Promise<AxiosResponse<any>> {
    const data = !isAction
      ? { title, description }
      : {
        title, description, actionButton, actionType, actionValue
      };
    return this.instance.post(CreativeRequests.BASE_URL, data);
  }

  public getAssetList(creativeId: string, page = 1, limit = 10): Promise<AxiosResponse<any>> {
    return this.instance.get(`${CreativeRequests.BASE_URL + creativeId}/assets`, {
      params: { page, limit }
    });
  }

  public addAsset(
    idCreative: string,
    file: Blob,
    asset: NewAssetModel
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('asset', JSON.stringify(asset));

    return this.instance({
      method: 'post',
      url: `/creatives/${idCreative}/assets`,
      data: formData,
    });
  }

  public addAssetWithExternalURL(
    idCreative: string,
    url: string,
    asset: NewAssetModel
  ): Promise<AxiosResponse<any>> {
    return this.instance({
      method: 'post',
      url: `/creatives/${idCreative}/assetsExternalURL`,
      data: { ...asset, publicUrl: url, },
    });
  }
}
