import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Backdrop, CircularProgress } from '@mui/material';
import React, { useMemo } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Navigate, useRoutes } from 'react-router-dom';
import { AlertProvider } from './context/alert';
import { useAuthState } from './context/auth';
import { ConfigProvider } from './context/config';
import MainLayout from './layouts/MainLayout';
import './mixins/chartjs';
import routes from './routes';
import ThemeConfig from './theme';
import LoginView from './views/auth/LoginView';
import RegisterView from './views/auth/RegisterView';
import NotFoundView from './views/errors/NotFoundView';

const loginRoutes = [{
  path: '/',
  element: <MainLayout />,
  children: [
    { path: 'login', element: <LoginView /> },
    { path: 'register', element: <RegisterView /> },
    { path: '404', element: <NotFoundView /> },
    { path: '/', element: <Navigate to="/login" /> },
    { path: '*', element: <Navigate to="/login" replace /> },
  ],
},
];

const App = () => {
  const routing = useRoutes(routes);
  const { loading, loggedIn } = useAuthState();

  const routingLogin = useRoutes(loginRoutes);

  const authRoutes = useMemo(() => {
    if (loading) {
      return (
        <Backdrop open>
          <CircularProgress />
        </Backdrop>
      );
    }
    if (loggedIn) return routing;
    return routingLogin;
  }, [loading, loggedIn, routing, routingLogin]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ConfigProvider>
        <ThemeConfig>
          <AlertProvider>
            {authRoutes}
          </AlertProvider>
        </ThemeConfig>
      </ConfigProvider>
    </LocalizationProvider>
  );
};

export default App;
