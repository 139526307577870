import { AxiosInstance, AxiosResponse } from 'axios';
import { ScheduleModel } from '../models/schedule';
import escapeRegExp from '../utils/escapeRegExp';
import AbstractRequests from './AbstractRequests';

export default class ScheduleRequests extends AbstractRequests<ScheduleModel> {
  private static BASE_URL = '/advertising-schedules/';

  public constructor(instance: AxiosInstance) {
    super(instance, ScheduleRequests.BASE_URL);
  }

  public getList(page = 1, limit = 10, filter = ''): Promise<AxiosResponse<any>> {
    const matchString = escapeRegExp(filter);
    const where = (typeof filter === 'string' && filter.length > 0)
      ? { title: { like: matchString, options: 'i' } }
      : {};
    return this.instance.get(ScheduleRequests.BASE_URL, {
      params: { page, limit, where: JSON.stringify(where) }
    });
  }

  public update(id: string, data: any): Promise<AxiosResponse<any>> {
    const { channels, ...schedule } = data;
    return this.instance.patch(ScheduleRequests.BASE_URL + id, schedule);
  }
}
