import { AxiosInstance, AxiosResponse } from 'axios';
import { ChannelModel } from '../models/channel';
import escapeRegExp from '../utils/escapeRegExp';
import AbstractRequests from './AbstractRequests';

export default class ChannelRequests extends AbstractRequests<ChannelModel> {
  private static BASE_URL = '/channels/';

  public constructor(instance: AxiosInstance) {
    super(instance, ChannelRequests.BASE_URL);
  }

  public getList(page = 1, limit = 10, filter = ''): Promise<AxiosResponse<any>> {
    const matchString = escapeRegExp(filter);
    const where = (typeof filter === 'string' && filter.length > 0)
      ? { name: { like: matchString, options: 'i' } }
      : {};
    return this.instance.get(ChannelRequests.BASE_URL, {
      params: {
        page, limit, where: JSON.stringify(where),
      },
    });
  }

  public async isValidChannel(name: string, adReference: string): Promise<boolean> {
    try {
      const res = await this.instance.get(ChannelRequests.BASE_URL, {
        params: {
          where: {
            or: [
              { name },
              { adReference }
            ]
          }
        }
      });
      if (+res.data.totalDocs > 0) return false;
    } catch (err) {
      return false;
    }
    return true;
  }
}
