import { Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    paddingTop: 64,
  },
}));

const DashboardLayout = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openLock, setOpenLock] = useState(false);

  return (
    <div className={classes.root}>
      <NavBar
        open={open || openLock}
        onMouseEnter={() => { setOpen(true) }}
        onMouseLeave={() => { setOpen(false) }}
      />
      <TopBar
        onLock={(locked) => { setOpenLock(locked); }}
        open={open || openLock}
      />
      <Container className={classes.wrapper}>
        <Outlet />
      </Container>
    </div>
  );
};

export default DashboardLayout;
