import { Alert, Snackbar } from '@mui/material';
import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useRef, useState
} from 'react';
import DialogConfirmDelAlert from '../components/DialogConfirmDelAlert';

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [alertType, setAlertType] = useState('info');
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [dialogTitle, setDialogTitle] = useState(null);

  const confirmRef = useRef();

  const handleCancelDialog = () => {
    if (confirmRef.current) {
      confirmRef.current.resolve(false);
    }
    setOpenDialog(false);
  };

  const handleConfirmDialog = () => {
    if (confirmRef.current) {
      confirmRef.current.resolve(true);
    }
    setOpenDialog(false);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const showMessage = (message) => {
    setAlertText(message);
    setAlertType('success');
    setOpenAlert(true);
  };

  const showError = (message) => {
    setAlertText(message);
    setAlertType('error');
    setOpenAlert(true);
  };

  const showConfirm = (message, title = '') => {
    setDialogText(message);
    setOpenDialog(true);
    setDialogTitle(title);
    return new Promise((resolve, reject) => {
      confirmRef.current = { resolve, reject };
    });
  };

  return (
    <AlertContext.Provider value={{ showMessage, showError, showConfirm }}>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
      >
        <Alert
          severity={alertType}
          color={alertType}
          elevation={6}
          variant="filled"
          onClose={handleCloseSnack}
        >
          {alertText}
        </Alert>
      </Snackbar>

      <DialogConfirmDelAlert
        openDialog={openDialog}
        dialogText={dialogText}
        dialogTitle={dialogTitle}
        onClose={handleCancelDialog}
        onDelete={handleConfirmDialog}
      />
    </AlertContext.Provider>
  );
};

AlertProvider.propTypes = {
  children: PropTypes.node
};

export const useAlerts = () => useContext(AlertContext);
