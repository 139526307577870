import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const [configState, setConfigState] = useState({
    tags: false
  });

  return (
    <ConfigContext.Provider value={configState}>
      {children}
    </ConfigContext.Provider>
  );
};

ConfigProvider.propTypes = {
  children: PropTypes.node
};

export const useConfig = () => useContext(ConfigContext);
