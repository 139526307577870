import { Alert, Box, Button, Container, Snackbar, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Page from '../../components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [snackAlert, setSnackAlert] = React.useState({ open: false, text: '' });

  const submit = async ({ email, password }) => {
    try {
      const res = await firebase.auth().signInWithEmailAndPassword(email, password);
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

      if (res.user) {
        navigate('/app/campaigns', { replace: true });
      }
    } catch (error) {
      setSnackAlert({ open: true, text: error.message });
    }
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackAlert({ open: false, text: '' });
  };

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackAlert.open}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert
          severity="error"
          color="error"
          elevation={6}
          variant="filled"
          onClose={handleCloseSnack}
        >
          {snackAlert.text}
        </Alert>
      </Snackbar>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email(t('login.errorEmail')).max(255).required(t('login.requiredEmail')),
              password: Yup.string().max(255).required(t('login.requiredPassword')),
            })}
            onSubmit={submit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    {t('login.title')}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    {t('login.subtitle')}
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t('login.button')}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
