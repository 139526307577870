/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import PeopleIcon from '@mui/icons-material/People';
import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import TodayIcon from '@mui/icons-material/Today';
import {
  Autocomplete, Box,
  Button,
  Card,
  CardContent, CardHeader, Checkbox,
  Chip, CircularProgress, Container,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as FileSaver from "file-saver";
import momentTimezone from "moment-timezone";
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as XLSX from "xlsx";
import Requests from '../../../BackofficeRequests/Requests';
import Page from '../../../components/Page';
import { useAlerts } from '../../../context/alert';
import { useConfig } from '../../../context/config';
import { getRegionsForTimezone } from "../../../utils/timezone";
import SliderComponent from '../../wizard/SliderComponent';
import EarnCard from './EarnCard';
import ScheduleList from './ScheduleList';
import UpdateSlotsCard from './UpdateSlotsCard';
import ValueCard from './ValueCard';

const tags = ['tag1', 'tag2'];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  adTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  adValue: {
    fontSize: 15
  },
  assetImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: 184,
  },
}));

const CampaignDetail = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const config = useConfig();
  const filtersRef = useRef({});
  const channelsListMap = useRef(null);

  const { id } = useParams();

  const AutocompleteRegionsRef = useRef(null);
  const [filters, setFilters] = useState({})

  const [modifyInfo, setModifyInfo] = useState(false);
  const [campaign, setCampaign] = useState();
  const [name, setName] = useState('');
  const [cost, setCost] = useState(0);
  const [publisher, setPublisher] = useState('');
  const [desc, setDesc] = useState('');
  const [limit, setLimit] = useState(10000);
  const [dayLimit, setDayLimit] = useState(1000);
  const [nolimit, setNoLimit] = useState(false);
  const [adUnit, setAdUnit] = useState();
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [modifyFilters, setModifyFilters] = useState(false);
  const [timezone, setTimezone] = useState("");
  const [channelName, setChannelName] = useState("");
  const [listRegions, setListRegions] = useState([]);
  const [channelInfo, setChannelInfo] = useState(null);
  const [GadUnit, setGAdUnit] = useState("");
  const [intervalPolling, setIntervalPolling] = useState(30);
  const [isGoogleActive, setIsGoogleActive] = useState(false);
  const [modifySettings, setModifySettings] = useState(false);
  const [isPrerollActive, setIsPrerollActive] = useState(false);
  const [prerollUrl, setPrerollUrl] = useState("");
  const [whiteList, setWhiteList] = useState("");
  const [prerollRandomBase, setPrerollRandomBase] = useState(100);
  const [isLoadingAnalytics, setIsLoadingAnalytics] = useState(true);
  const { moment } = new MomentAdapter({ instance: momentTimezone });
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(moment().hour(23).minute(59).second(59).utc(true));
  const channelRef = useRef({});

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [countries, setCountries] = useState([]);

  const [analytics, setAnalytics] = useState({ interactions: 0, dailyInteractions: 0 });

  const { showError, showMessage } = useAlerts();


  const [vendors, setVendors] = useState([]);
  const [currentVendor, setCurrentVendor] = useState("");

  const fetchAssetFilters = async (creative, docs) => {
    const newDocs = [];
    try {
      for (let i = 0; i < docs.length; ++i) {
        const a = { ...docs[i] };
        const { data } = await Requests.instance.asset.getFilters(a.id);
        let arr = JSON.parse(data[0].value);
        if (arr.length > 0) a[data[0].type] = arr.join(', ');
        arr = JSON.parse(data[1].value);
        if (arr.length > 0) a[data[1].type] = arr.join(', ');
        newDocs.push(a);
      }
      const newCreative = { ...creative };
      newCreative.assets = newDocs;
      setAdUnit(newCreative);
    } catch (err) {
      showError(err.message);
    }
  };

  const fetchAdUnit = async (idAdUnit) => {
    try {
      const { data } = await Requests.instance.creative.getById(idAdUnit);
      const res = await Requests.instance.creative.getAssetList(idAdUnit);
      fetchAssetFilters(data, res.data.docs);
    } catch (err) {
      showError(err.message);
    }
  };

  const getListOfCountries = (_countries) => {
    const list = [];

    if (_countries.length > 0) {
      _countries.forEach((country, index) => {
        if (country.regions.length > 0) {
          country.regions.forEach((region, index) => {
            let add = true;
            if (selectedRegions.length > 0) {
              selectedRegions.forEach(_region => {
                if (region.name === _region.name) {
                  add = false;
                }
              })
            }
            if (add) {
              list.push({
                ...region,
                countryName: country.name,
                id: country.id
              })
            }
          })
        }
      })
    }

    return list;
  }

  const fetchCampaign = async () => {
    try {
      const { data } = await Requests.instance.campaign.getById(id);
      let schedule = await Requests.instance.schedule.getById(data.scheduleId);
      schedule = schedule.data
      console.log("CHANNEL IS", schedule.channels[0]);
      channelRef.current = schedule.channels[0];

      if (schedule.channels[0].googleHeadUnit !== null) {
        setGAdUnit(schedule.channels[0].googleHeadUnit ?? "");
      } else {
        channelRef.current.googleHeadUnit = "";
      }
      if (schedule.channels[0].googlePollingInterval !== null) {
        setIntervalPolling(parseInt(schedule.channels[0].googlePollingInterval) ?? 30);
      } else {
        channelRef.current.googlePollingInterval = 30;
      }
      if (schedule.channels[0].googleActive !== null) {
        setIsGoogleActive(schedule.channels[0].googleActive ?? false);
      } else {
        channelRef.current.googleActive = false;
      }
      if (schedule.channels[0].prerollActive !== null) {
        setIsPrerollActive(schedule.channels[0].prerollActive);
      } else {
        channelRef.current.prerollActive = false;
      }
      if (schedule.channels[0].whiteList !== null && schedule.channels[0].whiteList !== undefined) {
        setWhiteList(schedule.channels[0].whiteList);
      }
      if (schedule.channels[0].prerollRandomBase !== null) {
        setPrerollRandomBase(parseInt(schedule.channels[0].prerollRandomBase) ?? 0);
      } else {
        channelRef.current.prerollRandomBase = 100;
      }
      if (schedule.channels[0].prerollUrl !== null) {
        setPrerollUrl(schedule.channels[0].prerollUrl);
      } else {
        channelRef.current.prerollUrl = "";
      }

      setChannelInfo(channelRef.current);

      setTimezone(schedule.timezone);
      setChannelName(schedule.title)
      if (data.type !== "automatic") {
        fetchAdUnit(data.creativeId);
      }
      const _countries = getRegionsForTimezone(schedule.timezone);
      //console.log("GET COUNTRIES", _countries, schedule.timezone);
      setCountries(_countries);
      setListRegions(getListOfCountries(_countries));
      setCampaign(data);
      setName(data.title);
      setPublisher(data.publisher);
      setCost(parseFloat(data.cost));
      setDesc(data.description);
      if (data.dayLimit && data.limit) {
        setLimit(data.limit);
        setDayLimit(data.dayLimit);
      } else setNoLimit(true);
      const createdDate = data.createdDate !== undefined ? moment.utc(data.createdDate) : moment(100000000);
      setStartDate(createdDate)
      fetchAnalytics(createdDate, endDate);
    } catch (err) {
      showError(err.message);
    }
  };

  const updateChannelInfo = (key, value) => {
    let channel = channelInfo;
    channel[key] = value;
    setChannelInfo(channel);
  }

  const fetchFilters = async () => {
    try {
      const { data } = await Requests.instance.campaign.getFilters(id);
      const actualVendors = [];
      const actualRegions = [];
      for (const filt of data) {
        if (filt.type === 'vendors') {
          actualVendors.push(...JSON.parse(filt.value));
        } else if (filt.type === 'regions') {
          actualRegions.push(...JSON.parse(filt.value));
        }
      }
      setVendors(actualVendors);
      setSelectedRegions(actualRegions);
    } catch (err) {
      showError(err.message);
    }
  };

  const handleDeleteVendor = (e) => {
    const _vendors = vendors.filter(item => item !== e.target.parentNode.dataset.vendor)
    setVendors(_vendors);
    updateFilters('vendors', _vendors);
  }

  const fetchAnalytics = async (startDateFilter, endDateFilter) => {
    try {
      setIsLoadingAnalytics(true);
      const s = startDateFilter.hour(0).minute(0).second(0).utc(true);
      const e = endDateFilter.hour(23).minute(59).second(59).utc(true);

      const { data } = await Requests.instance.campaign.analyticsFiltered(id, s, e);
      setAnalytics(data);
      setIsLoadingAnalytics(false);
    } catch (err) {
      showError(err.message);
    }
  };

  const updateFilters = (name, list) => {
    let _filters = filters;
    if (_filters[name] !== undefined) {
      _filters[name] = list;
    } else {
      _filters[name] = list;
    }
    setFilters(_filters);
    filtersRef.current = _filters;
  }

  useEffect(() => {
    fetchCampaign();
    fetchFilters();
  }, []);

  const handleSaveInfo = async () => {
    if (!nolimit && dayLimit > limit) return;
    try {
      const newCampaign = { ...campaign };
      newCampaign.title = name;
      newCampaign.publisher = publisher;
      newCampaign.cost = cost;
      newCampaign.description = desc;
      delete newCampaign.scheduleId;
      if (!nolimit) {
        newCampaign.limit = limit;
        newCampaign.dayLimit = dayLimit;
      } else if (newCampaign.limit && newCampaign.dayLimit) {
        newCampaign.$unset = {
          limit: true,
          dayLimit: true
        };
      }
      await Requests.instance.campaign.update(newCampaign.id, newCampaign);
      setModifyInfo(false);
      fetchCampaign();
      showMessage(t('Modifica effettuata!'));
    } catch (err) {
      setModifyInfo(false);
      fetchCampaign();
      showError(err.message);
    }
  };

  const handleUpdateFilters = async () => {
    try {
      for (const name in filtersRef.current) {
        console.log(name, filtersRef.current[name]);
        const d = await Requests.instance.campaign.updateFilter(id, filtersRef.current[name], name);
        console.log(d);
        if (d.data.count === 0) {
          await Requests.instance.campaign.addFilter(id, name, filtersRef.current[name]);
        }
      }
      fetchFilters();
      setModifyFilters(false);
      showMessage(t('Modifica effettuata!'));
    } catch (err) {
      fetchFilters();
      setModifyFilters(false);
      showError(err.message);
    }
  };

  const handleUpdateChannel = async () => {
    try {
      let channel = {
        name: channelInfo.name,
        adReference: channelInfo.adReference,
        logoUrl: channelInfo.logoUrl,
        timezone: channelInfo.timezone,
        isBroadbandy: channelInfo.isBroadbandy,
        broadbandySchedulerUrl: channelInfo.broadbandySchedulerUrl,
        googleActive: isGoogleActive,
        googleHeadUnit: GadUnit,
        googlePollingInterval: parseInt(intervalPolling),
        prerollActive: isPrerollActive,
        prerollUrl: prerollUrl,
        prerollRandomBase: parseInt(prerollRandomBase),
        whiteList: whiteList
      }

      const _channel = await Requests.instance.channel.update(channelInfo.id, channel);
      setModifySettings(false);
      showMessage(t('Modifica effettuata!'));
    } catch (err) {
      showError(err.message);
    }
  };

  const exportAnalyticsData = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const startDateString = startDate.format('DD-MM-YYYY');
    const endDateString = endDate.format('DD-MM-YYYY');
    const fileExtension = ".xlsx";
    const test = [
      { 'Titolo Campagna': name, 'Data Inizio': startDateString, 'Data Fine': endDateString, 'Impression Totali': analytics.filtered.impressions, 'Interazioni Totali': analytics.filtered.interactions },
    ]
    var wscols = [
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 }
    ];


    const ws = XLSX.utils.json_to_sheet(test);
    ws['!cols'] = wscols;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `${name}-export-${startDateString}-${endDateString}${fileExtension}`);
  }

  return (
    <Page className={classes.root} title="Campaign Detail">
      {campaign !== undefined ? <Container>
        <Grid container spacing={3} justifyContent="center" alignItems="stretch" direction="row">
          <Grid item sm={campaign.type !== undefined ? campaign.type === "automatic" ? 12 : 8 : 8}>
            {timezone !== "" && channelName !== "" ?
              <Card style={{ marginBottom: "30px" }}>
                <CardContent>
                  <Box pl={1} pb={1}><Typography variant="h3">{t('Channel Info')}</Typography></Box>
                  <Grid container direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <Grid item md={6} sm={6}>
                      <Box>
                        <Typography variant="p">{channelName}</Typography>
                      </Box>
                    </Grid>
                    <Grid item md={6} sm={6}>
                      <Chip label={timezone}></Chip>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              : null}
            <Card>
              <CardContent>
                <Box pl={1} pb={1} marginBottom={'3vh'} display={'flex'} justifyContent={'space-between'}>
                  <Typography variant="h3">{t('Dati Campagna')}</Typography>
                  {
                    !isLoadingAnalytics &&
                    <Button
                      size="small"
                      startIcon={<DownloadIcon />}
                      onClick={exportAnalyticsData}
                    >

                      {t('export')}
                    </Button>
                  }
                </Box>
                <Box display={'flex'} justifyContent={'space-around'}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      disableFuture
                      label={t('slot.startDate')}
                      openTo="day"
                      views={['day', 'month', 'year']}
                      value={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                      }}
                      inputFormat="DD-MM-YYYY"
                      renderInput={(params) => <TextField size="small" {...params} />}
                    />
                    <DatePicker
                      disableFuture
                      label={t('slot.endDate')}
                      openTo="day"
                      views={['day', 'month', 'year']}
                      value={endDate}
                      onChange={(date) => {
                        setEndDate(date);
                      }}
                      inputFormat="DD-MM-YYYY"
                      renderInput={(params) => <TextField size="small" {...params} />}
                    />
                  </LocalizationProvider>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => fetchAnalytics(startDate, endDate)}
                  >
                    {t('filterDate')}
                  </Button>
                </Box>
                <Grid container direction="column"
                  justifyContent="center"
                  minHeight={'27vh'}

                  alignItems="center">
                  {
                    isLoadingAnalytics ?
                      <>
                        <CircularProgress />
                      </>
                      :
                      <>
                        <Grid container spacing={2}>
                          <Grid item md={6} sm={6}>
                            <ValueCard
                              title={t("campaign.impressions")}
                              value={analytics?.filtered?.impressions ?? "0"}
                              icon={<PeopleIcon />}
                            />
                          </Grid>
                          <Grid item md={6} sm={6}>
                            <ValueCard
                              title={t("campaign.daily_impressions")}
                              value={analytics?.daily?.impressions ?? "0"}
                              icon={<TodayIcon />}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} marginBottom="40px">
                          <Grid item md={6} sm={6}>
                            <ValueCard
                              title={t("campaign.interactions")}
                              value={analytics?.filtered?.interactions ?? "0"}
                              icon={<PeopleIcon />}
                            />
                          </Grid>
                          <Grid item md={6} sm={6}>
                            <ValueCard
                              title={t("campaign.daily_interactions")}
                              value={analytics?.daily?.interactions ?? "0"}
                              icon={<TodayIcon />}
                            />
                          </Grid>

                        </Grid>
                      </>
                  }
                </Grid>
              </CardContent>
            </Card>

          </Grid>

          {campaign.type === undefined || campaign.type === "manual" ? <Grid item sm={4}>
            <EarnCard
              title={t('campaign.guadagno')}
              value={campaign?.state?.currentCost ?? 0}
            />
          </Grid> : null}
        </Grid>
        <Card style={{ marginTop: 16 }}>
          <CardHeader
            title={t('campaign.infoTitle')}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3} justifyContent="space-around">
              <Grid item sm={campaign.type !== undefined ? campaign.type === "automatic" ? 12 : 4 : 4}>
                <TextField
                  label={t('campaign.title')}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder={t('campaign.title')}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  disabled={!modifyInfo}
                />
                <TextField
                  label="Publisher"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder="Publisher"
                  value={publisher}
                  onChange={(e) => setPublisher(e.target.value)}
                  disabled={!modifyInfo}
                />
                <TextField
                  label={t('Costo')}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder={t('Costo')}
                  value={cost}
                  onChange={(e) => {
                    let val = e.target.value;
                    if (val.endsWith(',') || val.endsWith('.')) val = val.replace(',', '.');
                    else val = val === '' ? 0.0 : parseFloat(val);
                    if (Number.isNaN(val)) return;
                    setCost(val);
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{t('moneyValue')}</InputAdornment>
                  }}
                  disabled={!modifyInfo}
                />
                <TextField
                  label={t('campaign.desc')}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder={t('campaign.desc')}
                  multiline
                  rows={4}
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  disabled={!modifyInfo}
                />
              </Grid>
              <Grid item sm={6} style={{ display: campaign.type !== undefined ? campaign.type === "automatic" ? 'none' : 'block' : 'block' }}>
                <Box marginTop={1}>
                  <SliderComponent
                    label={t('campaign.impLim')}
                    value={limit}
                    onChange={(val) => {
                      if (Number.isNaN(val)) return;
                      setLimit(val);
                    }}
                    min={1000}
                    step={1000}
                    disabled={!modifyInfo || nolimit}
                  />
                  <SliderComponent
                    label={t('campaign.dayLim')}
                    value={dayLimit}
                    onChange={(val) => {
                      if (Number.isNaN(val)) return;
                      setDayLimit(val);
                    }}
                    min={100}
                    step={100}
                    disabled={!modifyInfo || nolimit}
                    error={!nolimit && dayLimit > limit}
                    err_message={t('Il limite giornaliero non può superare quello totale!')}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={nolimit}
                        onChange={(e) => setNoLimit(e.target.checked)}
                        disabled={!modifyInfo}
                      />
                    )}
                    label={t('campaign.nolim')}
                    style={{ margin: 1 }}
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" pr={2} pt={1} pb={1}>
            {campaign.type === undefined || campaign.type === "manual" ? modifyInfo
              ? (
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={handleSaveInfo}
                >
                  {t('campaign.save')}
                </Button>
              )
              : (
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<SettingsIcon />}
                  onClick={() => setModifyInfo(true)}
                >
                  {t('campaign.update')}
                </Button>
              ) : null}
          </Box>
        </Card>

        {campaign.type === "automatic" ?

          <Card style={{ marginTop: 16 }}>
            <CardHeader
              title={t('campaign.settingsCampaign')}
            />
            <Divider />
            <CardContent>
              {campaign.publisher === "AUTOMATIC-BBS-GPT" ?
                <Box fullWidth>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <FormControlLabel control={
                          <Checkbox
                            checked={isGoogleActive}
                            disabled={!modifySettings}
                            onChange={(event) => { setIsGoogleActive(event.target.checked); updateChannelInfo("googleActive", event.target.checked); }} />
                        }
                          label={t(isGoogleActive ? "channel.broadbandy.google.active" : "channel.broadbandy.google.not-active")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label={t("channel.broadbandy.google.headUnit")}
                          name="googleHeadUnit"
                          disabled={!modifySettings}
                          onChange={(e) => { setGAdUnit(e.target.value); updateChannelInfo("googleHeadUnit", e.target.value); }}
                          type="text"
                          value={GadUnit}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        <TextField
                          fullWidth
                          label={t("channel.broadbandy.google.pollingInterval")}
                          name="googlePollingInterval"
                          onChange={(e) => { setIntervalPolling(e.target.value); updateChannelInfo("googlePollingInterval", e.target.value); }}
                          type="number"
                          disabled={!modifySettings}
                          value={intervalPolling}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                :
                <Box fullWidth>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormControlLabel control={
                        <Checkbox
                          checked={isPrerollActive}
                          value={isPrerollActive}
                          disabled={!modifySettings}
                          onChange={(event) => { setIsPrerollActive(event.target.checked); updateChannelInfo("prerollActive", event.target.checked); }} />
                      }
                        label={t(isPrerollActive ? "channel.broadbandy.preroll.active" : "channel.broadbandy.preroll.not-active")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label={t("channel.broadbandy.preroll.url")}
                            name="prerollUrl"
                            disabled={!modifySettings}
                            onChange={(e) => { setPrerollUrl(e.target.value); updateChannelInfo("prerollUrl", e.target.value); }}
                            type="url"
                            value={prerollUrl}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          <TextField
                            fullWidth
                            label={t("channel.broadbandy.preroll.randomBase")}
                            name="prerollRandomBase"
                            disabled={!modifySettings}
                            onChange={(e) => { setPrerollRandomBase(e.target.value); updateChannelInfo("prerollRandomBase", e.target.value); }}
                            type="number"
                            value={prerollRandomBase}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          <TextField
                            fullWidth
                            label={t("channel.broadbandy.preroll.whiteList")}
                            name="whiteList"
                            disabled={!modifySettings}
                            onChange={(e) => { setWhiteList(e.target.value.toLowerCase().replace(/\s/g, "")); updateChannelInfo("whiteList", e.target.value.toLowerCase().replace(/\s/g, "")); }}
                            type="string"
                            value={whiteList}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              }
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" pr={2} pt={1} pb={1}>
              {modifySettings
                ? (
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={async () => await handleUpdateChannel()}
                  >
                    {t('campaign.save')}
                  </Button>
                )
                : (
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<SettingsIcon />}
                    onClick={() => setModifySettings(true)}
                  >
                    {t('campaign.update')}
                  </Button>
                )}
            </Box>
          </Card>

          : null}

        {<Box mt={2}>
          <Card>
            <CardHeader title="Filtri" />
            <Divider />
            <CardContent>
              <Grid container spacing={3} justifyContent="center">
                {/* config.tags
                  ? (
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Tags"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        placeholder="Tags"
                        select
                        onChange={(e) => setSelectedTags(selectedTags.concat(e.target.value))}
                        disabled={!modifyFilters}
                      >
                        {tags.filter((tag) => !selectedTags.includes(tag)).map((tag) => (
                          <MenuItem key={tag} value={tag}>{tag}</MenuItem>
                        ))}
                      </TextField>
                      <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap">
                        {selectedTags.map((tag) => (
                          <Chip
                            disabled={!modifyFilters}
                            key={tag}
                            label={tag}
                            onDelete={() => setSelectedTags(selectedTags.filter((ta) => ta !== tag))}
                            size="small"
                            style={{ marginLeft: 2, marginRight: 2, marginBottom: 4 }}
                          />
                        ))}
                      </Box>
                    </Grid>
                  )
                  : undefined */}
                {/* <Grid item xs={12} sm={12}>
                  <Box width="100%" display="flex" alignItems="stretch" justifyContent="space-around">
                    <Autocomplete
                      multiple
                      style={{ width: "100%" }}
                      id="grouped-timezone"
                      ref={AutocompleteRegionsRef}
                      options={listRegions}
                      disableCloseOnSelect
                      disabled={!modifyFilters}
                      value={selectedRegions}
                      groupBy={(option) => option.countryName}
                      filterSelectedOptions={true}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.id.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.id.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      onChange={async (event, value, reason) => {
                        setSelectedRegions(value);
                        updateFilters("regions", value);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          fullWidth
                          label={t('campaign.regions')}
                          name="countries"
                          variant="outlined"
                        />
                      }
                    />
                  </Box>

                  <Box width="100%" display="flex" alignItems="stretch" mt={2} justifyContent="space-around">
                    <Grid container>
                      <Grid item xs={11}>
                        <TextField
                          label={t('campaign.vendors.new')}
                          noValidate
                          autoComplete="off"
                          fullWidth
                          disabled={!modifyFilters}
                          variant="outlined"
                          value={currentVendor}
                          onChange={(e) => {
                            setCurrentVendor(e.target.value);
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                        <Button
                          variant="outlined"
                          style={{ borderRadius: '100px' }}
                          disabled={!modifyFilters}
                          onClick={() => {

                            if (currentVendor !== "") {
                              const _vendors = vendors;
                              let existsVendor = false;
                              _vendors.forEach(e => {
                                if (e === currentVendor.toUpperCase()) {
                                  existsVendor = true;
                                }
                              })
                              if (!existsVendor) {
                                _vendors.push(currentVendor.toUpperCase());
                                console.log("ADD TO VENDORS", _vendors);
                                setVendors(_vendors);
                                updateFilters('vendors', _vendors);
                                setCurrentVendor("")
                              } else {
                                showError(t('campaing.vendors.exists'))
                              }
                            }
                          }}
                        >+</Button>
                      </Grid>
                      <Grid xs={12} mt={2} style={{ minHeight: '100px', display: 'flex', justifyContent: 'flex-end', alignItems: 'cener', flexDirection: 'row-reverse' }} direction="row-reverse" spacing={1}>
                        {vendors.length > 0 ?
                          vendors.map((e, i) => {
                            return <Chip
                              label={e}
                              key={i}
                              disabled={!modifyFilters}
                              onDelete={handleDeleteVendor}
                              deleteIcon={<DeleteIcon variant="plain" data-vendor={e} color="error" />}
                              variant="outlined"
                            />
                          })
                          : null}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid> */}
                <Grid item xs={12}>
                  <Box width="100%">
                    <Typography variant="h3" align="center" marginBottom="40px">{t('campaign.regions')}</Typography>
                    <Box width="100%" display="flex" alignItems="stretch" justifyContent="space-around">
                      <Autocomplete
                        multiple
                        style={{ width: "100%" }}
                        id="grouped-timezone"
                        ref={AutocompleteRegionsRef}
                        options={listRegions}
                        disableCloseOnSelect
                        disabled={!modifyFilters}
                        value={selectedRegions}
                        groupBy={(option) => option.countryName}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${option.id.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.id.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </li>
                        )}
                        onChange={(event, value, reason) => {
                          setSelectedRegions(value);
                          updateFilters("regions", value);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            fullWidth
                            label={t('campaign.regionList')}
                            name="countries"
                            variant="outlined"
                          />
                        }
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" pr={2} pt={1} pb={1}>
              {modifyFilters
                ? (
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={async () => await handleUpdateFilters()}
                  >
                    {t('campaign.save')}
                  </Button>
                )
                : (
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<SettingsIcon />}
                    onClick={() => setModifyFilters(true)}
                  >
                    {t('campaign.update')}
                  </Button>
                )}
            </Box>
          </Card>
        </Box>}
        {adUnit
          ? (
            <Box mt={2}>
              <Card>
                <CardHeader title={t("creatives")} />
                <Divider />
                <CardContent>
                  <Grid container spacing={3} justifyContent="center">
                    <Grid item>
                      <Paper variant="outlined">
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>{t('campaign.title')}</TableCell>
                              <TableCell>{t('campaign.desc')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>{adUnit.title}</TableCell>
                              <TableCell>{adUnit.description}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} style={{ margin: 0, padding: 0 }} />
                    {adUnit.assets.map((asset) => (
                      <Grid item md={3} key={asset.id}>
                        <Card>
                          <CardContent>
                            <Typography variant="h4" align="center">{asset.title.toUpperCase()}</Typography>
                            <Divider />
                            <Box display="flex" justifyContent="center" mt={1} mb={1}>
                              <Chip
                                color="primary"
                                label={asset.type}
                                size="small"
                              />
                            </Box>
                            {config.tags
                              ? (
                                <Typography>
                                  <b>TAGS:</b>
                                  {' '}
                                  {asset.tag ?? '_'}
                                </Typography>
                              )
                              : undefined}
                            <Typography>
                              <b>VENDORS:</b>
                              {' '}
                              {asset.vendor ?? ''}
                            </Typography>
                            <Box padding={1} height={200}>
                              <img className={classes.assetImage} alt="banner" src={asset.publicUrl} />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          )
          : undefined}
        {campaign && campaign.type !== "automatic"
          ? (
            <Box mt={2}>
              <UpdateSlotsCard idCampaign={id} selectedSchedule={campaign?.scheduleId ?? ''} />
            </Box>
          )
          : undefined}
        {channelInfo.isBroadbandy && channelInfo.broadbandySchedulerUrl !== "" ?
          campaign.publisher !== "AUTOMATIC-BBS-PREROLL" ?
            <ScheduleList scheduleUrl={channelInfo.broadbandySchedulerUrl} type={campaign.publisher === "AUTOMATIC-BBS-GPT" ? "PROGRAMME" : campaign.publisher === "AUTOMATIC-BBS-GPT" ? "AUTOPROMO" : "ALL"} />
            : null
          : null}
      </Container> : null}
    </Page >
  );
};

export default CampaignDetail;
