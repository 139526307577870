import {
  Box, FormHelperText, Grid, Slider, TextField, Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const SliderComponent = ({
  label, value, onChange,
  min = 0, max, step = 1,
  disabled, error, err_message
}) => {
  const handleChangeVal = (e, val) => {
    let newVal;
    if (val) newVal = Array.isArray(val) ? val[0] : val;
    else newVal = parseInt(e.target.value, 10);
    onChange(newVal);
  };

  return (
    <Box width="100%" marginTop={1} marginBottom={1} padding={1}>
      <Typography
        variant="h4"
        style={{
          fontSize: 18,
          fontWeight: 500,
          marginBottom: 15
        }}
      >
        {label}
      </Typography>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid item sm={8}>
          <Slider
            value={value}
            onChange={handleChangeVal}
            valueLabelDisplay="auto"
            min={min}
            step={step}
            max={max || min * 100}
            disabled={disabled}
            style={error ? { color: '#f44336' } : undefined}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            type="number"
            value={value}
            onChange={handleChangeVal}
            inputProps={{
              min,
              step,
              max
            }}
            disabled={disabled}
            error={error}
          />
        </Grid>
        {error
          ? (
            <Grid item style={{ margin: 0, padding: 0 }}>
              <FormHelperText error>{err_message}</FormHelperText>
            </Grid>
          )
          : undefined}
      </Grid>
    </Box>
  );
};

SliderComponent.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  err_message: PropTypes.string
};

export default SliderComponent;
