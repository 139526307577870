/* eslint-disable class-methods-use-this */
import { AxiosInstance, AxiosResponse } from 'axios';
import { AssetModel } from '../models/asset';
import AbstractRequests from './AbstractRequests';

export default class AssetRequests extends AbstractRequests<AssetModel> {
  private static BASE_URL = '/assets/';

  public constructor(instance: AxiosInstance) {
    super(instance, AssetRequests.BASE_URL);
  }

  public getList(page: number, limit: number, filter: string): Promise<AxiosResponse<any>> {
    throw new Error('Method not implemented.');
  }

  public create(data: AssetModel): Promise<AxiosResponse<any>> {
    throw new Error('Method not implemented.');
  }

  public addFilter(idAsset: string, type: string, value: string[]): Promise<AxiosResponse<any>> {
    return this.instance.post(`/assets/${idAsset}/filters`, { type, value: JSON.stringify(value) });
  }

  public getFilters(idAsset: string): Promise<AxiosResponse<any>> {
    return this.instance.get(`/assets/${idAsset}/filters`);
  }
}
