import { AxiosInstance, AxiosResponse } from 'axios';
import { CampaignModel } from '../models/campaign';
import escapeRegExp from '../utils/escapeRegExp';
import AbstractRequests from './AbstractRequests';

export default class CampaignRequests extends AbstractRequests<CampaignModel> {
  private static BASE_URL = '/campaigns/';

  public constructor(instance: AxiosInstance) {
    super(instance, CampaignRequests.BASE_URL);
  }

  public getList(page = 1, limit = 10, filter = '', channel = 0, types = 'all'): Promise<AxiosResponse<any>> {
    const matchString = escapeRegExp(filter);
    let where = (typeof filter === 'string' && filter.length > 0)
      ? { title: { like: matchString, options: 'i' } }
      : {};

    if (channel != 0) {
      const scheduleObject = {
        scheduleId: channel
      };
      where = {
        ...where,
        ...scheduleObject
      }
    }
    if (types !== 'all') {
      let _types = {};
      if (types === "manual") {
        _types = {
          type: { ne: "automatic" }
        }
      } else {
        _types = {
          type: { eq: types }
        }
      }

      where = {
        ...where,
        ..._types
      }
    }

    let data = this.instance.get(CampaignRequests.BASE_URL, {
      params: { page, limit, where: JSON.stringify(where) }
    });

    return data;
  }

  public createCampaign(
    title: string,
    publisher: string,
    cost: number,
    description: string,
    creativeId: string,
    limit: number,
    dayLimit: number,
    nolimit: boolean,
    scheduleId: string,
    type: string = 'manual'
  ): Promise<AxiosResponse<any>> {
    const data = nolimit
      ? {
        title, cost, publisher, description, creativeId, scheduleId, type
      }
      : {
        title, publisher, cost, description, creativeId, limit, dayLimit, scheduleId, type
      };
    return this.instance.post('/campaigns', data);
  }

  public addFilter(idCampaign: string, type: string, value: string[]): Promise<AxiosResponse<any>> {
    return this.instance.post(`/campaigns/${idCampaign}/filters`, { type, value: JSON.stringify(value) });
  }

  public getFilters(idFilter: string): Promise<AxiosResponse<any>> {
    return this.instance.get(`/campaigns/${idFilter}/filters`);
  }

  public updateFilter(id: string, value: string[], type = ''): Promise<AxiosResponse<any>> {
    const where = type === '' ? {} : { type };
    const data = this.instance.patch(
      `/campaigns/${id}/filters`,
      { value: JSON.stringify(value) },
      { params: { where: JSON.stringify(where) } }
    );
    return data;
  }

  public analytics(id: string): Promise<AxiosResponse<any>> {
    return this.instance.get(`/campaigns/${id}/analytics`);
  }

  public analyticsFiltered(id: string = "", startDate: Date, endDate: Date): Promise<AxiosResponse<any>> {
    return this.instance.post(`/campaigns/${id}/analytics-filtered`, { startDate, endDate });
  }

}
