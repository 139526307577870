import {
  Checkbox, ListItemText, MenuItem, TextField
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const WeeklyScheduleForm = ({ slot, onChangeSlot }) => {
  const { t, i18n } = useTranslation();

  return (
    <TextField
      size="small"
      variant="outlined"
      margin="normal"
      select
      SelectProps={{
        multiple: true
      }}
      value={[]}
      label={t('Giorni')}
      fullWidth
    >
      {Object.entries(slot.weeklySchedule).map(([key, val]) => (
        <MenuItem key={key}>
          <Checkbox
            checked={val}
            onClick={() => {
              slot.weeklySchedule[key] = !val;
              onChangeSlot(slot);
            }}
          />
          <ListItemText primary={t(key)} />
        </MenuItem>
      ))}
    </TextField>
  );
};

WeeklyScheduleForm.propTypes = {
  slot: PropTypes.object,
  onChangeSlot: PropTypes.func
};

export default WeeklyScheduleForm;
