import {
  DateTimePicker,
  LocalizationProvider,
} from '@mui/lab';
import { Box, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import momentTimezone from "moment-timezone";
import MomentAdapter from "@material-ui/pickers/adapter/moment";

const FixedSlotForm = ({ slot, onChangeSlot, timezone }) => {
  const { t, i18n } = useTranslation();
  const { moment } = new MomentAdapter({ instance: momentTimezone });
  const [date, setDate] = useState();

  useEffect(() => {
    if (slot.fixedDateTime === null || slot.fixedDateTime === undefined) {
      const time = moment().tz(timezone).format("YYYY-MM-DD HH:mm:ss");
      setDate(time);
      slot.fixedDateTime = time;
    } else {
      const time = moment(slot.fixedDateTime).tz(timezone).format("YYYY-MM-DD HH:mm:ss");
      slot.fixedDateTime = time;
      setDate(time);
    }
  }, [])

  return (
    <Fragment>
      <LocalizationProvider dateAdapter={MomentAdapter} >
        <Box display="flex" flexDirection="column" justifyContent={'center'} alignItems="stretch">
          <DateTimePicker
            margin="normal"
            label={t('slot.fixedDate')}
            inputVariant="outlined"
            size="small"
            ampm={false}
            inputFormat='yyyy/MM/DD HH:mm:ss'
            views={['day', 'month', 'year', 'hours', 'minutes', 'seconds']}
            value={date}
            onChange={(_date, info) => {
              if (_date) {
                const time = moment(_date).tz(timezone).format("YYYY-MM-DD HH:mm:ssZZ");
                setDate(time);
                slot.fixedDateTime = time;
                onChangeSlot(slot);
                console.log("TIME CHANGE", time, moment(_date));
              }
            }}
            renderInput={props => <TextField {...props} />}
          />
        </Box>
      </LocalizationProvider>
    </Fragment>
  );
};

FixedSlotForm.propTypes = {
  slot: PropTypes.object,
  onChangeSlot: PropTypes.func
};

export default FixedSlotForm;
