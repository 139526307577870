import { colors } from '@mui/material';

export default class Color {
  private col = [
    colors.blue[500],
    colors.red[500],
    colors.lime[500],
    colors.pink[500],
    colors.indigo[500],
    colors.orange[500],
    colors.teal[500],
    colors.yellow[500],
    colors.green[500],
    colors.lightBlue[500],
    colors.lightGreen[500],
    colors.amber[500],
    colors.cyan[500],
    colors.purple[500],
    colors.blueGrey[500],
    colors.deepOrange[500],
    colors.blueGrey[500],
  ];

  private index: number;

  constructor() {
    this.index = 0;
  }

  public get color(): string {
    const i = this.index;
    this.index = (this.index + 1) % this.col.length;
    return this.col[i];
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  // eslint-disable-next-line generator-star-spacing
  public * genColors(n: number) {
    for (let i = 0; i < n; i++) yield this.color;
  }
}
