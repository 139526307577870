import { useCallback, useEffect, useState, useRef } from "react";
import GenericStep from "../../components/GenericStep"
import Requests from '../../BackofficeRequests/Requests';
import { useTranslation } from 'react-i18next';
import { useAlerts } from '../../context/alert';
import {
    Alert,
    Autocomplete,
    Box,
    Chip,
    Divider,
    MenuItem,
    TextField,
    Typography,
    Checkbox,
    Grid,
    Button
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import momentTimezone from "moment-timezone";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { getRegionsForTimezone } from "../../utils/timezone";
import DeleteIcon from '@mui/icons-material/Delete';

const CampaignChannelAndFilter = ({ back, next, isWizard = false, channel, setChannel, updateFilters, filters, setChannelList, channelList }) => {

    const [channelSelected, selectChannel] = useState(channel);
    const [error, setError] = useState(false);
    const [selectedRegions, setSelectedRegions] = useState(filters.regions ?? []);
    const [listRegions, setListRegions] = useState([]);

    const channelsListMap = useRef(null);
    const AutocompleteRegionsRef = useRef(null);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const { showError, showMessage, showConfirm } = useAlerts();
    const { t, i18n } = useTranslation();
    const { moment } = new MomentAdapter({ instance: momentTimezone });
    const [countries, setCountries] = useState([]);
    const [vendors, setVendors] = useState(filters.vendors ?? []);
    const [currentVendor, setCurrentVendor] = useState("");

    useEffect(async () => {
        console.log("FETCH DATA");
        await fetchSchedules();
        if (channel !== "") {
            const _countries = getRegionsForTimezone(channelsListMap.current.get(channel).timezone);
            setCountries(_countries);
            setListRegions([]);
            setSelectedRegions([])
        }
    }, [])

    useEffect(() => {
        if (channel !== "" && channelsListMap.current !== null) {
            const _countries = getRegionsForTimezone(channelsListMap.current.get(channel).timezone);
            setCountries(_countries);
            setListRegions([]);
            setSelectedRegions([])
        }
    }, [channel])

    const fetchSchedules = useCallback(async () => {
        try {
            const res = await Requests.instance.schedule.getList(1, 200);
            console.log("CHANNELS", res);
            if (res.data.docs) {
                let schedules = [];
                channelsListMap.current = new Map();
                res.data.docs.forEach((channel) => {
                    if (channel.enabled) {
                        schedules.push(channel);
                        channelsListMap.current.set(channel.id, channel);
                    }
                })
                setChannelList(schedules);
            }
        } catch (err) {
            showError(err.message);
        }
    }, []);

    useEffect(() => {
        if (countries.length > 0) {
            setListRegions(getListOfCountries());
        }
    }, [channel, selectedRegions, countries])

    const getListOfCountries = () => {
        const list = [];

        if (countries.length > 0) {
            countries.forEach((country, index) => {
                if (country.regions.length > 0) {
                    country.regions.forEach((region, index) => {
                        let add = true;
                        if (selectedRegions.length > 0) {
                            selectedRegions.forEach(_region => {
                                if (region.name === _region.name) {
                                    add = false;
                                }
                            })
                        }
                        if (add) {
                            list.push({
                                ...region,
                                countryName: country.name,
                                id: country.id
                            })
                        }
                    })
                }
            })
        }

        return list;
    }

    const handleSubmit = () => {
        if (channelSelected !== "") {
            next();
        } else {
            showError(t('channel.channelRequired'))
        }
    }

    const handleChangeChannel = async (e) => {
        if (channel !== "" && selectedRegions.length > 0) {
            const res = await showConfirm(t('Change Channel reset all filters'), t("Attenzione"));
            if (!res) return;
            updateFilters("regions", [])
            selectChannel(e.target.value)
            setChannel(e.target.value);
        } else {
            selectChannel(e.target.value)
            setChannel(e.target.value);
        }
    }

    const handleDeleteVendor = (e) => {
        const _vendors = vendors.filter(item => item !== e.target.parentNode.dataset.vendor)
        setVendors(_vendors);
        updateFilters('vendors', _vendors);
    }

    return (
        <GenericStep
            onBack={() => { back(isWizard ? 2 : 1) }}
            onNext={handleSubmit}>
            <Typography variant="h3" align="center" marginBottom="40px">{t('campaign.selectChannel')}</Typography>
            <Box marginLeft={5} marginRight={5} display="flex" justifyContent="space-between" marginBottom={1} alignItems="center">
                <Box width="50%">
                    <TextField
                        label={t('Canale')}
                        select
                        variant="outlined"
                        size='small'
                        style={{ width: "100%" }}
                        value={channelSelected}
                        onChange={handleChangeChannel}
                        error={error && channel === ''}
                        helperText={error && channel === '' ? t('campaign.scheduleRequired') : undefined}
                    >
                        {channelList.map((schedule) => (
                            <MenuItem key={schedule.id} value={schedule.id}>{schedule.title}</MenuItem>
                        ))}
                    </TextField>
                </Box>
                {channelSelected !== "" && channelsListMap.current !== null ?
                    <Box width="50%" display="flex" alignItems="center" justifyContent="space-around">
                        <Chip label={channelsListMap.current.get(channelSelected).timezone} />
                        <Chip label={moment().tz(channelsListMap.current.get(channelSelected).timezone).format("Z")} />
                    </Box>
                    : null}

            </Box>

            <Divider style={{ marginTop: 15 }} />

            <Box width="100%">
                <Typography variant="h3" align="center" marginBottom="40px">{t('campaign.regions')}</Typography>
                {channel !== "" ?
                    <Box width="100%" display="flex" alignItems="stretch" justifyContent="space-around">
                        <Autocomplete
                            multiple
                            style={{ width: "100%" }}
                            id="grouped-timezone"
                            ref={AutocompleteRegionsRef}
                            options={listRegions}
                            disableCloseOnSelect
                            value={selectedRegions}
                            groupBy={(option) => option.countryName}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.id.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.id.toLowerCase()}.png 2x`}
                                        alt=""
                                    />
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </li>
                            )}
                            onChange={(event, value, reason) => {
                                setSelectedRegions(value);
                                updateFilters("regions", value);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    fullWidth
                                    label={t('campaign.regionList')}
                                    name="countries"
                                    variant="outlined"
                                />
                            }
                        />
                    </Box>
                    : <Box display="flex" alignItems="center" justifyContent="center" >
                        <Alert severity="warning">
                            {t('campaign.noChannelSelected')}
                        </Alert>
                    </Box>
                }
            </Box>

            {/* <Divider style={{ marginTop: 15 }} />

            <Box width="100%">
                <Typography variant="h3" align="center" marginBottom="40px">{t('campaign.vendors.title')}</Typography>
                {channel !== "" ?
                    <Box width="100%" display="flex" alignItems="stretch" justifyContent="space-around">
                        <Grid container>
                            <Grid item xs={11}>
                                <TextField
                                    label={t('campaign.vendors.new')}
                                    noValidate
                                    autoComplete="off"
                                    fullWidth
                                    variant="outlined"
                                    value={currentVendor}
                                    onChange={(e) => {
                                        setCurrentVendor(e.target.value);
                                    }}
                                ></TextField>
                            </Grid>
                            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                <Button
                                    variant="outlined"
                                    style={{ borderRadius: '100px' }}
                                    onClick={() => {
                                        if (currentVendor !== "") {
                                            const _vendors = vendors;
                                            let existsVendor = false;
                                            _vendors.forEach(e => {
                                                if (e === currentVendor.toUpperCase()) {
                                                    existsVendor = true;
                                                }
                                            })
                                            if (!existsVendor) {
                                                _vendors.push(currentVendor.toUpperCase());
                                                console.log("ADD TO VENDORS", _vendors);
                                                setVendors(_vendors);
                                                updateFilters('vendors', _vendors);
                                                setCurrentVendor("")
                                            } else {
                                                showError(t('campaing.vendors.exists'))
                                            }
                                        }
                                    }}
                                >+</Button>
                            </Grid>
                            <Grid xs={12} mt={2} style={{ minHeight: '100px', display: 'flex', justifyContent: 'flex-end', alignItems: 'cener', flexDirection: 'row-reverse' }} direction="row-reverse" spacing={1}>
                                {vendors.length > 0 ?
                                    vendors.map((e, i) => {
                                        return <Chip
                                            label={e}
                                            key={i}
                                            onDelete={handleDeleteVendor}
                                            deleteIcon={<DeleteIcon variant="plain" data-vendor={e} color="error" />}
                                            variant="outlined"
                                        />
                                    })
                                    : null}
                            </Grid>
                        </Grid>
                    </Box>
                    : <Box display="flex" alignItems="center" justifyContent="center" >
                        <Alert severity="warning">
                            {t('campaign.noChannelSelected')}
                        </Alert>
                    </Box>
                }
            </Box > */}

        </GenericStep >
    );

}

export default CampaignChannelAndFilter;