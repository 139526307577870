import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar, Box, Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Toolbar
} from '@mui/material';
import { styled } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import firebase from 'firebase/app';
import 'firebase/auth';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlerts } from '../../context/alert';
import LanguagePopover from './LanguagePopover';
import { DRAWER_WIDTH } from './NavBar';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer - 1,
  },
  avatar: {
    width: 60,
    height: 60,
  },
}));

const NewTopBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: [DRAWER_WIDTH, '!important'],
    width: [`calc(100% - ${DRAWER_WIDTH}px)`, '!important'],
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    marginLeft: `calc(${theme.spacing(7)} + 1px)`,
    width: `calc(100% - ${theme.spacing(7)} - 1px)`,
    [theme.breakpoints.up('sm')]: {
      marginLeft: `calc(${theme.spacing(9)} + 1px)`,
      width: `calc(100% - ${theme.spacing(9)} - 1px)`,
    },
  }),
}));

const TopBar = ({
  className,
  onLock,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLocked, setIsLocked] = useState(false);
  const [anchorPopUser, setAncorPopUser] = useState(null);
  const openPopUser = Boolean(anchorPopUser);
  const { showError } = useAlerts();

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      navigate('/login', { replace: true });
    } catch (error) {
      showError(error.message);
    }
  };

  return (
    <NewTopBar
      position='fixed'
      className={clsx(classes.root, className)}
      elevation={2}
      {...rest}
    >
      <Toolbar>
        <IconButton color="inherit" onClick={() => setIsLocked(val => {
          onLock(!val);
          return !val;
        })} size="large">
          <MenuIcon />
        </IconButton>

        <Box flexGrow={1} />

        <LanguagePopover />

        {/*<IconButton color="inherit" size="large">
          <Badge
            badgeContent={notifications.length}
            color="primary"
            variant="dot"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>*/}

        <IconButton
          color="inherit"
          onClick={(e) => setAncorPopUser(e.currentTarget)}
          size="large">
          <AccountCircleIcon />
        </IconButton>
        <Popover
          open={openPopUser}
          anchorEl={anchorPopUser}
          onClose={() => setAncorPopUser(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List>
            <ListItem
              button
              onClick={() => {
                firebase.auth().currentUser.getIdToken().then(
                  async (token) => {
                    await navigator.clipboard.writeText(token);
                    alert('Copied to clipboard');
                  },
                );
              }}
            >
              <ListItemText>Copy Firebase Token</ListItemText>
            </ListItem>
            <Divider />
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText>
                Logout
              </ListItemText>
            </ListItem>
          </List>
        </Popover>

      </Toolbar>
    </NewTopBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
