import { Box, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useParams } from 'react-router';
import Page from '../../../components/Page';
import CreativeAssetsDetail from './CreativeAssetsDetail';
import CreativeInfoDetail from './CreativeInfoDetail';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));

const CreativeDetail = () => {
  const classes = useStyles();
  const { id } = useParams();

  return (
    <Page className={classes.root} title="Campaign List">
      <Container>
        <Box mb={3}>
          <CreativeInfoDetail idCreative={id} />
        </Box>
        <CreativeAssetsDetail idCreative={id} />
      </Container>
    </Page>
  );
};

export default CreativeDetail;
