import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Slider,
  FormLabel
} from '@mui/material';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Requests from '../../../BackofficeRequests/Requests';
import { useAlerts } from '../../../context/alert';
import Timezones from './Timezones';

const ChannelUpdateDialog = ({
  timezones, channel, onClose, onSubmit, ...props
}) => {
  const { t, i18n } = useTranslation();
  const { showError } = useAlerts();

  const [isBroadbandy, setIsBroadbandy] = useState(false);
  const [isPrerollActive, setIsPrerollActive] = useState(false);
  const [isGoogleActive, setIsGoogleActive] = useState(false);
  const [priorityBB, setPriorityBB] = useState(1);

  useEffect(async () => {
    setIsBroadbandy(channel.isBroadbandy);
    const campaigns = await Requests.instance.campaign.getList(1, 10, '', channel.advertisingScheduleIds[0], 'automatic')
    console.log("OBTAIN CAMPAIGNS", campaigns);
  }, [channel])

  const submit = async (values) => {
    try {
      let _values = values;
      _values.isBroadbandy = isBroadbandy;
      const _channel = await Requests.instance.channel.update(channel.id, _values);
      console.log("CHANNEL UPDATE", _channel, channel);
      const campaigns = await Requests.instance.campaign.getList(1, 10, '', channel.advertisingScheduleIds[0], 'automatic')
      console.log("OBTAIN CAMPAIGNS", campaigns);
      if (values.isBroadbandy && campaigns.data.docs.length === 0) {
        const requestCampaignGPT = await Requests.instance.campaign.createCampaign(
          "BB Service GPT",
          "AUTOMATIC-BBS-GPT",
          0,
          t('campaign.automatic.gpt.description'),
          "",
          0,
          0,
          true,
          channel.advertisingScheduleIds[0],
          "automatic"
        );
        const requestCampaignPreroll = await Requests.instance.campaign.createCampaign(
          "BB Service PREROLL",
          "AUTOMATIC-BBS-PREROLL",
          0,
          t('campaign.automatic.preroll.description'),
          "",
          0,
          0,
          true,
          channel.advertisingScheduleIds[0],
          "automatic"
        );
      } else {
        let bbGpt = false;
        let bbPreroll = false;

        campaigns.data.docs.forEach(c => {
          if (c.publisher === "AUTOMATIC-BBS-GPT") {
            bbGpt = true;
          }
          if (c.publisher === "AUTOMATIC-BBS-PREROLL") {
            bbPreroll = true;
          }
        })

        if (!bbGpt) {
          const requestCampaignGPT = await Requests.instance.campaign.createCampaign(
            "BB Service GPT",
            "AUTOMATIC-BBS-GPT",
            0,
            t('campaign.automatic.gpt.description'),
            "",
            0,
            0,
            true,
            channel.advertisingScheduleIds[0],
            "automatic"
          );
        }
        if (!bbPreroll) {
          const requestCampaignPreroll = await Requests.instance.campaign.createCampaign(
            "BB Service PREROLL",
            "AUTOMATIC-BBS-PREROLL",
            0,
            t('campaign.automatic.preroll.description'),
            "",
            0,
            0,
            true,
            channel.advertisingScheduleIds[0],
            "automatic"
          );
        }
      }
      onSubmit();
      onClose();
    } catch (err) {
      showError(err.message);
    }
  };

  return (
    <Dialog
      onClose={onClose}
      {...props}
    >
      <DialogTitle color="textPrimary" variant="h3">
        {t('channel.updateTitle')}
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={{
          name: channel.name,
          adReference: channel.adReference,
          logoUrl: channel.logoUrl,
          timezone: channel.timezone,
          isBroadbandy: channel.isBroadbandy !== undefined ? channel.isBroadbandy : false,
          broadbandySchedulerUrl: channel.broadbandySchedulerUrl || "",
          filterIP: channel.filterIP || ""
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required(t('channel.nameRequired')),
          adReference: Yup.string().max(255).required(t('channel.adRefRequired')),
          timezone: Yup.string().required(t('channel.timezoneRequired')),
          logoUrl: Yup.string().max(255),
          isBroadbandy: Yup.boolean().default(false),
          filterIP: Yup.string()
        })}
        onSubmit={submit}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label={t('Nome')}
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.name}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.adReference && errors.adReference)}
                fullWidth
                helperText={touched.adReference && errors.adReference}
                label="Ad Reference"
                margin="normal"
                name="adReference"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.adReference}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.filterIP && errors.filterIP)}
                fullWidth
                helperText={touched.filterIP && errors.filterIP}
                label="Filter IP"
                margin="normal"
                name="filterIP"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.filterIP}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.logoUrl && errors.logoUrl)}
                fullWidth
                helperText={touched.logoUrl && errors.logoUrl}
                label="Logo URL"
                margin="normal"
                name="logoUrl"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.logoUrl}
                placeholder={"Logo URL"}
                variant="outlined"
              />
              <Autocomplete
                id="grouped-timezone"
                autoHighlight={true}
                margin="normal"
                style={{ marginTop: '15px' }}
                options={timezones.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                groupBy={(option) => option.firstLetter}
                groupLabel
                onChange={(event, value, reason) => { setFieldValue('timezone', value.name); }}
                getOptionLabel={(option) => { return typeof option === 'object' ? option.name : values.timezone }}
                value={values.timezone}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    error={Boolean(touched.timezone && errors.timezone)}
                    helperText={touched.timezone && errors.timezone}
                    fullWidth
                    label="Timezone"
                    name="timezone"
                    value={values.timezone}
                    variant="outlined"
                  />
                }
              />
              <Grid container xs={12}>
                <Divider xs={{ marginTop: 1, marginBottom: 1 }} />
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      color="textPrimary"
                      variant="h4"
                    >
                      {t('channel.broadbandyName')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel control={
                      <Checkbox
                        checked={isBroadbandy}
                        value={values.isBroadbandy}
                        onChange={(event) => { setIsBroadbandy(event.target.checked); }} />
                    }
                      label={t(isBroadbandy ? "channel.broadbandyStatus.active" : "channel.broadbandyStatus.not-active")}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ display: isBroadbandy ? 'flex' : 'none' }}>
                    <Grid xs={12} container>
                      <Grid item xs={11}>
                        <TextField
                          error={Boolean(touched.broadbandySchedulerUrl && errors.broadbandySchedulerUrl)}
                          fullWidth
                          helperText={touched.broadbandySchedulerUrl && errors.broadbandySchedulerUrl}
                          label={t("channel.broadbandy.schedulerURL")}
                          name="broadbandySchedulerUrl"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="url"
                          value={values.broadbandySchedulerUrl}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Box mt={2}>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="primary"
                  size="large"
                >
                  {t('Conferma')}
                </Button>
                <Button
                  onClick={onClose}
                  size="large"
                  style={{ color: '#dc3545' }}
                >
                  {t('Annulla')}
                </Button>
              </Box>
            </DialogActions>
          </form>
        )}
      </Formik>

    </Dialog>
  );
};

ChannelUpdateDialog.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  channel: PropTypes.object,
};

export default ChannelUpdateDialog;
