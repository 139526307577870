/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  TextField
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GenericStep from '../../components/GenericStep';
import { useConfig } from '../../context/config';
import regions from '../../utils/regions';
import SliderComponent from './SliderComponent';

const tags = ['tag1', 'tag2'];

const CampaignInfoStep = ({ next, onCreateCampaign, campaign }) => {
  const { t, i18n } = useTranslation();
  const config = useConfig();

  const [name, setName] = useState(campaign?.title ?? '');
  const [publisher, setPublisher] = useState(campaign?.publisher ?? '');
  const [cost, setCost] = useState(campaign?.cost ?? 0.0);
  const [desc, setDesc] = useState(campaign?.description ?? '');
  const [limit, setLimit] = useState(campaign?.limit ?? 10000);
  const [dayLimit, setDayLimit] = useState(campaign?.dayLimit ?? 1000);
  const [nolimit, setNoLimit] = useState(campaign?.nolimit ?? false);
  const [error, setError] = useState(false);
  const [selectedTags, setSelectedTags] = useState(campaign?.tags ?? []);

  const handleSubmit = async () => {
    if (name === '' || publisher === '' || (!nolimit && dayLimit > limit)) setError(true);
    else {
      const newCampaign = {
        title: name,
        description: desc,
        publisher,
        limit,
        dayLimit,
        nolimit,
        cost,
        tags: selectedTags
      };
      onCreateCampaign(newCampaign);
      next();
    }
  };

  return (
    <GenericStep
      title={t('campaign.newCampaign')}
      onNext={handleSubmit}
    >
      <Grid container spacing={3}>
        <Grid item sm={4}>
          <TextField
            label={t('campaign.title')}
            variant="outlined"
            fullWidth
            margin="normal"
            placeholder={t('campaign.title')}
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={error && name === ''}
            helperText={error ? t('campaign.titleRequired') : ''}
          />
          <TextField
            label={t("campaign.publisher")}
            variant="outlined"
            fullWidth
            margin="normal"
            placeholder={t("campaign.publisher")}
            value={publisher}
            onChange={(e) => setPublisher(e.target.value)}
            error={error && publisher === ''}
            helperText={error ? t('campaign.pubRequired') : ''}
          />
          <TextField
            label={t('Costo')}
            variant="outlined"
            fullWidth
            margin="normal"
            placeholder={t('Costo')}
            value={cost}
            onChange={(e) => {
              let val = e.target.value;
              if (val.split('.').length > 2)
                return;
              if (val.endsWith('..') || val.endsWith('.,') || val.endsWith(',.') || val.endsWith(',,'))
                return;
              if (val.startsWith('.'))
                val = '0' + val;
              if (val.endsWith(',') || val.endsWith('.')) val = val.replace(',', '.');
              else val = val === '' ? 0.0 : val.endsWith('0') ? val : parseFloat(val);
              if (Number.isNaN(val)) return;
              setCost(val);
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">{t('moneyValue')}</InputAdornment>
            }}
          />
          <TextField
            label={t('campaign.desc')}
            variant="outlined"
            fullWidth
            margin="normal"
            placeholder={t('campaign.desc')}
            multiline
            rows={2}
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
          {config.tags
            ? (<>
              <TextField
                label="Tags"
                variant="outlined"
                fullWidth
                margin="normal"
                placeholder="Tags"
                select
                onChange={(e) => setSelectedTags(selectedTags.concat(e.target.value))}
              >
                {tags.filter((tag) => !selectedTags.includes(tag)).map((tag) => (
                  <MenuItem key={tag} value={tag}>{tag}</MenuItem>
                ))}
              </TextField>
              <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap">
                {selectedTags.map((tag) => (
                  <Chip
                    key={tag}
                    label={tag}
                    onDelete={() => setSelectedTags(selectedTags.filter((ta) => ta !== tag))}
                    size="small"
                    style={{ marginLeft: 2, marginRight: 2, marginBottom: 4 }}
                  />
                ))}
              </Box>
            </>)
            : undefined}
        </Grid>
        <Grid item sm={1} />
        <Grid item sm={7}>
          <Box marginTop={1}>
            <SliderComponent
              label={t('campaign.impLim')}
              value={limit}
              onChange={(val) => {
                if (Number.isNaN(val)) return;
                setLimit(val);
              }}
              min={1000}
              step={1000}
              disabled={nolimit}
            />
            <SliderComponent
              label={t('campaign.dayLim')}
              value={dayLimit}
              onChange={(val) => {
                if (Number.isNaN(val)) return;
                setDayLimit(val);
              }}
              min={100}
              step={100}
              disabled={nolimit}
              error={!nolimit && dayLimit > limit}
              err_message={t('Il limite giornaliero non può superare quello totale!')}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={nolimit}
                  onChange={(e) => setNoLimit(e.target.checked)}
                />
              )}
              label={t('campaign.nolim')}
              style={{ margin: 1 }}
            />
          </Box>
        </Grid>
      </Grid>
    </GenericStep>
  );
};

CampaignInfoStep.propTypes = {
  next: PropTypes.func.isRequired,
  onCreateCampaign: PropTypes.func.isRequired,
  campaign: PropTypes.object
};

export default CampaignInfoStep;
