/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import {
  Button,
  Chip,
  IconButton,
  Table, TableBody, TableCell, TableHead, TableRow,
  Box, Typography
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SlotType } from '../models/slot';
import { secondsFromDate, timeFromDate } from '../utils/dates';
import momentTimezone from "moment-timezone";
import MomentAdapter from "@material-ui/pickers/adapter/moment";

const TimezoneBox = ({ channel, channelsMap }) => {
  const { moment } = new MomentAdapter({ instance: momentTimezone });

  const render = useMemo(() => {
    if (channel && channelsMap && channelsMap.current) {
      const ch = channelsMap.current.get(channel);

      return (
        ch !== undefined ?
          <Box display="flex" justifyContent="center" alignItems="center" margin="0px">
            <Chip label={ch.timezone + " " + moment().tz(ch.timezone).format("Z")} />
          </Box>
          : null
      )
    }
  }, [channel])

  if (!channel || channel === '') return null;

  return (render);
}

const TableForSlots = ({
  slots = [],
  fixedSlots, dailySlots, periodicSlots,
  slotsType,
  onRemove, onUpdate,
  newSlots = false,
  channel,
  channelsMap,
  tz = null
}) => {
  const { t, i18n } = useTranslation();
  const { moment } = new MomentAdapter({ instance: momentTimezone });
  const tzRef = useRef(null);
  const [slotsArray, setSlotsArray] = useState([])

  useEffect(() => {
    console.log("TABLE SOLTS - LIST OF SLOTS", { slots, slotsType, isEqual: SlotType.PERIODIC === slotsType, fixedSlots, dailySlots, periodicSlots });
    switch (slotsType) {
      case SlotType.FIXED:
        if (fixedSlots) {
          setSlotsArray(fixedSlots);
        } else {
          setSlotsArray(slots);
        }
        break;
      case SlotType.DAILY:
        if (dailySlots) {
          setSlotsArray(dailySlots);
        } else {
          setSlotsArray(slots);
        }
        break;
      case SlotType.PERIODIC:
        if (periodicSlots) {
          setSlotsArray(periodicSlots);
        } else {
          setSlotsArray(slots);
        }
        break;
      default:
        setSlotsArray([]);
    }
  }, [slotsType, slots, fixedSlots, dailySlots, periodicSlots])

  const weeklyScheduleString = (ws) => {
    const s = [];
    for (const [key, val] of Object.entries(ws)) {
      if (val) s.push(t(key));
    }
    return s.join(', ');
  };

  const fixedOrDailyTime = (s) => {
    if (channelsMap !== undefined && channelsMap !== null && tzRef.current === null) {
      tzRef.current = channelsMap.current?.get(channel)?.timezone
    }
    if (slotsType === SlotType.FIXED) {
      let date = null;
      if (s.fixedTime !== null && s.fixedTime !== undefined) {
        date = moment(s.fixedTime).tz(tzRef.current);
      }
      if (s.fixedDateTime !== null && s.fixedDateTime !== undefined) {
        date = moment(s.fixedDateTime).tz(tzRef.current);
      }
      if (date !== null) {
        return date.format("yyyy/MM/DD HH:mm:ss")
      }
    }
    if (slotsType === SlotType.DAILY) {
      return moment(s.windowStart).format("yyyy/MM/DD") + " - " + moment(s.windowEnd).format("yyyy/MM/DD")
    }
    if (slotsType === SlotType.PERIODIC) {
      if (s.advertisingScheduleId !== '') {
        return moment(s.windowStart).tz(tzRef.current).format("yyyy/MM/DD HH:mm:ss") + " - " + moment(s.windowEnd).tz(tzRef.current).format("yyyy/MM/DD HH:mm:ss")
      } else {
        return moment(s.windowStart).tz(tzRef.current).format("yyyy/MM/DD HH:mm:ss") + " - " + moment(s.windowEnd).tz(tzRef.current).format("yyyy/MM/DD HH:mm:ss")
      }
    }
  };

  const dailyTime = (s) => {
    if (slotsType === SlotType.DAILY) {
      if (s.dailyTime !== null && s.dailyTime !== undefined) {
        const timeParts = s.dailyTime.split(':').map(part => parseInt(part));
        let daily = moment(s.windowStart).tz(tzRef.current).set({
          'hour': timeParts[0],
          'minute': timeParts[1],
          'second': timeParts[2]
        });
        daily = moment(daily).add(moment(daily).utcOffset(), 'minutes');
        daily = moment(daily).tz(tzRef.current).format("HH:mm:ss");
        return daily;
      }
      if (s.dailyTime === null || s.dailyTime === undefined) {
        return moment(s.windowStart).format("HH:mm:ss")
      }
    }
  }

  const periodicFreq = (s) => {
    if (s.periodicFrequency) {
      if (newSlots) return `${secondsFromDate(s.periodicFrequency)} sec`;
      return `${s.periodicFrequency} sec`;
    }
    return '';
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('Tipo')}</TableCell>
          <TableCell>{t('Timezone')}</TableCell>
          {slotsType === SlotType.DAILY
            ? (
              <>
                <TableCell>{t('Finestra di attività')}</TableCell>
                <TableCell>{t('Ora di attività')}</TableCell>
                <TableCell>{t('Giorni di attività')}</TableCell>
              </>
            )
            : undefined}
          {slotsType === SlotType.PERIODIC
            ? (
              <>
                <TableCell>{t('Finestra di periodicità')}</TableCell>
                <TableCell>Giorni</TableCell>
                <TableCell>{t('Frequenza')}</TableCell>
              </>
            )
            : undefined}
          {slotsType === SlotType.FIXED
            ? (
              <TableCell>{t('Ora')}</TableCell>
            )
            : undefined}
          <TableCell>{t('Durata')}</TableCell>
          <TableCell>{t('Priority')}</TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {slotsArray.map((s, index) => (
          <TableRow key={`row-${index}`}>
            <TableCell>
              <Chip label={slotsType} color="primary" size="small" />
            </TableCell>
            <TableCell>
              <TimezoneBox channel={channel} channelsMap={channelsMap}></TimezoneBox>
            </TableCell>
            {slotsType === SlotType.DAILY
              ? (
                <>
                  <TableCell>
                    {fixedOrDailyTime(s)}
                  </TableCell>
                  <TableCell>{dailyTime(s)}</TableCell>
                  <TableCell style={{ maxWidth: 300 }}>
                    {s.weeklySchedule ? weeklyScheduleString(s.weeklySchedule) : ''}
                  </TableCell>
                </>
              )
              : undefined}
            {slotsType === SlotType.PERIODIC
              ? (
                <>
                  <TableCell><span>{fixedOrDailyTime(s)}</span></TableCell>
                  <TableCell>{s.weeklySchedule ? weeklyScheduleString(s.weeklySchedule) : ''}</TableCell>
                  <TableCell>{periodicFreq(s)}</TableCell>
                </>
              )
              : undefined}
            {slotsType === SlotType.FIXED
              ? (
                <TableCell>{fixedOrDailyTime(s)}</TableCell>
              )
              : undefined}
            <TableCell>{`${s.duration} sec`}</TableCell>
            <TableCell>{s.priority}</TableCell>

            <TableCell padding="checkbox">
              <IconButton
                style={{ color: '#dc3545' }}
                onClick={() => onRemove(s.id, index)}
                size="large">
                <DeleteIcon />
              </IconButton>
            </TableCell>
            <TableCell padding="checkbox">
              <Button
                size="small"
                endIcon={<ChevronRightIcon />}
                onClick={() => onUpdate(s, index)}
              >
                {t('Modifica')}
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

TableForSlots.propTypes = {
  slots: PropTypes.array,
  fixedSlots: PropTypes.array,
  dailySlots: PropTypes.array,
  periodicSlots: PropTypes.array,
  slotsType: PropTypes.string,
  onRemove: PropTypes.func,
  onUpdate: PropTypes.func,
  newSlots: PropTypes.bool,
  tz: PropTypes.string
};

export default TableForSlots;
