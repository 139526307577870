import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Card,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Requests from '../../../BackofficeRequests/Requests';
import Page from '../../../components/Page';
import SearchBar from '../../../components/SearchBar';
import { useAlerts } from '../../../context/alert';
import ChannelForm from './ChannelForm';
import ChannelUpdateDialog from './ChannelUpdateDialog';
import TvIcon from '@mui/icons-material/Tv';
import { getAllTimezones } from '../../../utils/timezone';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));
/*
function* items (n) {
  for (let i = 0; i<n; ++i)
    yield { name: `Canale ${i}`, ad_ref: `Ad-ref ${i}`, timezone: `timezone ${i}` };
};
const data = Array.from(items(20)); */

const ChannelListView = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [channels, setChannels] = useState([]);
  const [channelsCount, setChannelsCount] = useState(0);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState({ name: '', ad_ref: '', timezone: '' });
  const [tablePage, setTablePage] = useState(0);
  const [rowsLimit, setRowsLimit] = useState(10);
  const [searchString, setSearchString] = useState('');
  const { showError, showConfirm } = useAlerts();
  const [tzOptions, setTzOptions] = useState([]);

  useEffect(() => {
    loadTimezoneOptions();
  }, [])

  const loadTimezoneOptions = () => {
    const tzones = getAllTimezones();
    let options = Object.keys(tzones).filter((tz) => {
      if (tzones[tz].countries && tzones[tz].countries.length > 0) return tzones[tz];
    });

    options = options.map((key) => {
      const firstLetter = tzones[key].name[0].toUpperCase();
      if (tzones[key].countries && tzones[key].countries.length > 0)
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...tzones[key],
        };
    });
    setTzOptions(options);
  }

  const fetchChannels = async (page, limit, filter) => {
    try {
      const { data } = await Requests.instance.channel.getList(
        page + 1, limit, filter,
      );
      setChannels(data.docs);
      setChannelsCount(+data.totalDocs);
      if (+data.totalDocs > 0 && data.docs.length === 0) setTablePage(tablePage - 1);
    } catch (err) {
      showError(err.message);
    }
  };

  useEffect(() => {
    fetchChannels(tablePage, rowsLimit, searchString);
  }, [tablePage, rowsLimit, searchString]);

  const handleSearchStringChange = (event) => {
    const search = event.target.value.toLowerCase();
    if (search.length > 0) setSearchString(search);
    else setSearchString('');
  };

  const handleDeleteChannel = async (id) => {
    try {
      const res = await showConfirm(t('channel.confirmDel'));
      if (!res) return;
      await Requests.instance.channel.delete(id);
      fetchChannels(tablePage, rowsLimit, searchString);
    } catch (err) {
      showError(err.message);
    }
  };

  return (
    <Page className={classes.root} title="Canali">
      <ChannelUpdateDialog
        onClose={() => { setDialogOpened(false); setSelectedChannel({ name: '', ad_ref: '', timezone: '' }); }}
        channel={selectedChannel}
        open={dialogOpened}
        onSubmit={() => fetchChannels(tablePage, rowsLimit, searchString)}
        timezones={tzOptions}
      />
      <Container>
        <Box mt={2}>
          <Card>
            <ChannelForm onSubmit={() => fetchChannels(tablePage, rowsLimit, searchString)} timezones={tzOptions} />
          </Card>
        </Box>
        <Box mt={2}>
          <SearchBar
            placeholder={t('channel.search')}
            onSearchStringChange={handleSearchStringChange}
            onRefresh={() => fetchChannels(tablePage, rowsLimit, searchString)}
          />
        </Box>
        <Box mt={2}>
          <Card>
            <PerfectScrollbar>
              <Box minWidth={600}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Logo</TableCell>
                      <TableCell>{t('Canale')}</TableCell>
                      <TableCell>Ad Reference</TableCell>
                      <TableCell>Timezone</TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {channels
                      .map((c) => (
                        <TableRow key={c.id}>
                          <TableCell>
                            <object data={c.logoUrl} style={{ height: 50, margin: '0 auto', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                              <TvIcon size={28} />{c.name}
                            </object>
                          </TableCell>
                          <TableCell style={{ maxWidth: 200 }}>
                            <Typography noWrap>{c.name}</Typography>
                          </TableCell>
                          <TableCell style={{ maxWidth: 200 }}>
                            <Typography noWrap>{c.adReference}</Typography>
                          </TableCell>
                          <TableCell>{c.timezone}</TableCell>
                          <TableCell padding="checkbox">
                            <IconButton
                              style={{ color: '#dc3545' }}
                              onClick={() => handleDeleteChannel(c.id)}
                              size="large">
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell padding="checkbox">
                            <Button
                              size="small"
                              endIcon={<ChevronRightIcon />}
                              onClick={() => {
                                setSelectedChannel(c);
                                setDialogOpened(true);
                              }}
                            >
                              {t('Modifica')}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={channelsCount}
                  rowsPerPage={rowsLimit}
                  page={tablePage}
                  rowsPerPageOptions={[5, 10, 25]}
                  onPageChange={(e, page) => setTablePage(page)}
                  labelRowsPerPage={t('Righe per pagina')}
                  onRowsPerPageChange={(e) => {
                    setRowsLimit(parseInt(e.target.value, 10));
                    setTablePage(0);
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default ChannelListView;
