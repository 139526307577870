import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import Requests from '../../../BackofficeRequests/Requests';
import Page from '../../../components/Page';
import SearchBar from '../../../components/SearchBar';
import { useAlerts } from '../../../context/alert';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  cell: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

const ScheduleList = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [schedules, setSchedules] = useState([]);
  const [schedulesCount, setSchedulesCount] = useState(0);
  const [tablePage, setTablePage] = useState(0);
  const [rowsLimit, setRowsLimit] = useState(10);
  const [searchString, setSearchString] = useState('');
  const { showError, showConfirm } = useAlerts();

  const fetchSchedules = async (page, limit, filter) => {
    try {
      const res = await Requests.instance.schedule.getList(page, limit, filter);
      setSchedules(res.data.docs);
      setSchedulesCount(parseInt(res.data.totalDocs, 10));
    } catch (err) {
      showError(err.message);
    }
  };

  useEffect(() => {
    fetchSchedules(tablePage + 1, rowsLimit, searchString);
  }, [tablePage, rowsLimit, searchString]);

  const handleChangeEnabledState = async (index, enabled) => {
    const schedule = schedules[index];
    schedule.enabled = enabled;
    try {
      await Requests.instance.schedule.update(schedule.id, schedule);
      fetchSchedules(tablePage + 1, rowsLimit, searchString);
    } catch (err) {
      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  const handleSearchStringChange = (event) => {
    const search = event.target.value.toLowerCase();
    if (search.length > 0) setSearchString(search);
    else setSearchString('');
  };

  const handleDeleteSchedule = async (id) => {
    try {
      const res = await showConfirm(t('schedule.confirmDel'));
      if (!res) return;
      await Requests.instance.schedule.delete(id);
      fetchSchedules(tablePage + 1, rowsLimit, searchString);
    } catch (err) {
      showError(err.message);
    }
  };

  return (
    <Page className={classes.root} title="Schedule List">
      <Container>

        <Box
          display="flex"
          justifyContent="flex-end"
          mb={1}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            to="/app/schedules/new"
            component={Link}
          >
            {t('schedule.new')}
          </Button>
        </Box>
        <SearchBar
          placeholder={t('schedule.search')}
          onRefresh={() => fetchSchedules(tablePage + 1, rowsLimit, searchString)}
          onSearchStringChange={handleSearchStringChange}
        />

        <Box mt={2}>
          <Card>
            <PerfectScrollBar>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('Titolo')}</TableCell>
                    <TableCell>{t('Priority')}</TableCell>
                    <TableCell>{t('Canali')}</TableCell>
                    <TableCell align="center">{t('Attiva')}</TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schedules.map((schedule, index) => (
                    <TableRow key={schedule.id}>
                      <TableCell>{schedule.title}</TableCell>
                      <TableCell>{schedule.priority}</TableCell>
                      <TableCell className={classes.cell}>
                        <Box display="flex" alignItems="center" flexWrap="wrap" maxWidth={600}>
                          {schedule.channels.map((c) => (
                            <Chip
                              key={c.id}
                              label={c.name}
                              color="primary"
                              size="small"
                              style={{ margin: 2 }}
                            />
                          ))}
                        </Box>
                      </TableCell>
                      <TableCell padding="checkbox" align="center">
                        <Checkbox
                          checked={schedule.enabled}
                          onChange={(e) => handleChangeEnabledState(index, e.target.checked)}
                        />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <IconButton
                          style={{ color: '#dc3545' }}
                          onClick={() => handleDeleteSchedule(schedule.id)}
                          size="large">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell padding="checkbox">
                        <Button
                          size="small"
                          endIcon={<ChevronRightIcon />}
                          component={Link}
                          to={`/app/schedules/update/${schedule.id}`}
                        >
                          {t('Modifica')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={schedulesCount}
                rowsPerPage={rowsLimit}
                page={tablePage}
                rowsPerPageOptions={[5, 10, 25]}
                onPageChange={(e, page) => setTablePage(page)}
                labelRowsPerPage={t('Righe per pagina')}
                onRowsPerPageChange={(e) => {
                  setRowsLimit(parseInt(e.target.value, 10));
                  setTablePage(0);
                }}
              />
            </PerfectScrollBar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default ScheduleList;
