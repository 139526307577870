// ----------------------------------------------------------------------

export default function Divider(theme) {
  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: theme.spacing(1)
        }
      }
    }
  };
}
