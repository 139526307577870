const vendors = [
  { name: 'Hisense', value: 'hisense' },
  { name: 'LG Electronics', value: 'lge' },
  { name: 'Panasonic', value: 'panasonic' },
  { name: 'Philips', value: 'philips' },
  { name: 'Samsung', value: 'samsung' },
  { name: 'Sharp', value: 'sharp' },
  { name: 'Sony', value: 'sony' },
  { name: 'Telefunken', value: 'telefunken' },
  { name: 'Toshiba', value: 'toshiba' },
  { name: 'Vestel', value: 'vestel' }
];

export default vendors;
