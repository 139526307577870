import React from 'react';

const ICONS_PATH = {
    "left": -90,
    "right": 90,
    "up": 180,
    "down": 0
}

const ArrowSVG = ({ color = '#fff', deg = 0, size = 10 }: { color: string, deg: number, size: number }) => {
    return (
        <svg style={{ transform: `rotate(${deg}deg)` }} width={size} height={size} viewBox="0 0 700 489" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>noun-arrow-2454332</title>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Artboard" transform="translate(-129.000000, -75.000000)" fill={color} fill-rule="nonzero">
                    <g id="noun-arrow-2454332" transform="translate(479.000000, 319.005000) scale(1, -1) translate(-479.000000, -319.005000) translate(129.000000, 75.000000)">
                        <polygon id="Path" points="350 0 0 488.01 350 423.987 700 488.01"></polygon>
                    </g>
                </g>
            </g>
        </svg>
    )
}

const ArrowIcon = ({ direction, color, size, containerStyle }: { direction: keyof typeof ICONS_PATH, color: string, size: number, containerStyle: object }) => {
    return (
        <div style={containerStyle}>
            <ArrowSVG color={color} deg={ICONS_PATH[direction]} size={size} />
        </div>
    )
};

export default ArrowIcon;
