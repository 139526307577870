import {
  Box, Card, CardContent, Typography
} from '@mui/material';
import MoneyIcon from '@mui/icons-material/Money';
import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EarnCard = ({ title, value }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  return (
    <Card style={{ backgroundColor: theme.palette.primary.main, height: '100%' }}>
      <CardContent>
        <MoneyIcon style={{
          color: 'white', height: 46, width: 46, marginLeft: 10
        }}
        />
        <Box pr={2}>
          <Typography
            variant="h4"
            style={{ color: 'whitesmoke' }}
            gutterBottom
            align="right"
          >
            {title.toUpperCase()}
          </Typography>
          <Typography
            style={{ color: 'white' }}
            variant="h1"
            align="right"
          >
            {`${t('moneyValue')} ${value}`}
          </Typography>
        </Box>
      </CardContent>
    </Card>

  );
};

EarnCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

export default EarnCard;
