import firebase from 'firebase';
import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useEffect, useReducer,
} from 'react';

const AuthStateContext = createContext();
const AuthMethodsContext = createContext();

export function useAuthState() {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within an AuthProvider.');
  }
  return context;
}

export function useAuthMethods() {
  const context = useContext(AuthMethodsContext);
  if (context === undefined) {
    throw new Error('useAuthMethods must be used within an AuthProvider.');
  }
  return context;
}

const AuthActionTypes = {
  UPDATE: 'UPDATE',
};

const AuthReducer = (currState, action) => {
  switch (action.type) {
    case AuthActionTypes.UPDATE:
      return {
        ...currState,
        user: firebase.auth().currentUser,
        loggedIn: firebase.auth().currentUser !== null,
        loading: false,
      };
    default:
      return currState;
  }
};

const initialState = {
  user: null,
  loggedIn: false,
  loading: true,
  errorMessage: null,
};

const AuthProvider = ({ children }) => {
  const [userState, dispatch] = useReducer(AuthReducer, initialState);

  useEffect(() => {
    const unregisterObserver = firebase.auth().onAuthStateChanged(() => {
      dispatch({ type: AuthActionTypes.UPDATE });
    });

    return () => unregisterObserver();
  }, []);

  return (
    <AuthStateContext.Provider value={userState}>
      {children}
    </AuthStateContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthProvider };
