import {
  Box, Card, CardContent, CardHeader, Divider, useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

function PieChartCard({ title, dataset }) {
  const theme = useTheme();

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: true,
      position: 'right'
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
    },
  };
  return (
    <Card>
      <CardHeader
        title={title}
      />
      <Divider />
      <CardContent>
        <Box
          height={400}
          position="relative"
        >
          <Doughnut
            data={dataset}
            options={options}
          />
        </Box>
      </CardContent>
    </Card>
  );
}

PieChartCard.propTypes = {
  title: PropTypes.string,
  dataset: PropTypes.object
};

export default PieChartCard;
