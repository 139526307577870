/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-indent */
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  TextField,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Requests from '../../BackofficeRequests/Requests';
import GenericStep from '../../components/GenericStep';
import SlotCard from '../../components/SlotCard/index';
import TableForSlots from '../../components/TableForSlots';
import { useAlerts } from '../../context/alert';
import {
  DailySlot, FixedSlot, PeriodicSlot, SlotType
} from '../../models/slot';
import { datesAreOnSameDay, secondsFromDate } from '../../utils/dates';
import momentTimezone from "moment-timezone";
import MomentAdapter from "@material-ui/pickers/adapter/moment";

const CampaignScheduleStep = ({ onSubmit, back, isWizard = false, channel, channelList = [] }) => {
  const { t, i18n } = useTranslation();
  const { moment } = new MomentAdapter({ instance: momentTimezone });

  const [fixedSlots, setFixedSlots] = useState([]);
  const [dailySlots, setDailySlots] = useState([]);
  const [periodicSlots, setPeriodicSlots] = useState([]);
  const [error, setError] = useState(false);
  const [anchorPopAdd, setAnchorPopAdd] = useState(null);
  const openPopAdd = Boolean(anchorPopAdd);
  const [slotsTab, setSlotsTab] = useState(SlotType.FIXED);
  const { showError } = useAlerts();

  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [formDialogType, setFormDialogType] = useState('');
  const [newSlot, setNewSlot] = useState();
  const [indexSlot, setIndexSlot] = useState(0);
  const [formUpdate, setFormUpdate] = useState(false);

  const [channelSelected, setChannelSelected] = useState('');
  const channelsListMap = useRef(null);

  useEffect(() => {
    channelsListMap.current = new Map();
    channelList.forEach((channel) => {
      if (channel.enabled) {
        channelsListMap.current.set(channel.id, channel);
      }
    })
    setChannelSelected(channelsListMap.current.get(channel));
  }, [])

  const addSlot = (type) => {
    switch (type) {
      case SlotType.FIXED:
        setNewSlot(new FixedSlot());
        setFormDialogOpen(true);
        setFormDialogType(SlotType.FIXED);
        break;
      case SlotType.DAILY:
        setNewSlot(new DailySlot());
        setFormDialogOpen(true);
        setFormDialogType(SlotType.DAILY);
        break;
      case SlotType.PERIODIC:
        setNewSlot(new PeriodicSlot());
        setFormDialogOpen(true);
        setFormDialogType(SlotType.PERIODIC);
        break;
      default:
        break;
    }
  };

  const appendSlot = (type, slot) => {
    if (type === SlotType.DAILY && moment(slot.windowEnd).diff(moment(slot.windowStart)) <= 0) {
      showError(t('wizard.check1'));
      return;
    }
    if (type === SlotType.PERIODIC && moment(slot.windowEnd).diff(moment(slot.windowStart)) <= 0) {
      showError(t('wizard.check2'));
      return;
    }

    const s = slot;

    if (s.windowStart && s.type === SlotType.PERIODIC) {
      const _timezone = moment(s.windowStart).format("Z");
      if (_timezone.charAt(0) === '+') {
        s.periodicTimeStart = moment(s.windowStart).subtract(_timezone).format("HH:mm:ss");
      } else {
        s.periodicTimeStart = moment(s.windowStart).add(_timezone).format("HH:mm:ss");
      }
    }
    if (s.windowEnd && s.type === SlotType.PERIODIC) {
      const _timezone = moment(s.windowEnd).format("Z");
      if (_timezone.charAt(0) === '+') {
        s.periodicTimeEnd = moment(s.windowEnd).subtract(_timezone).format("HH:mm:ss");
      } else {
        s.periodicTimeEnd = moment(s.windowEnd).add(_timezone).format("HH:mm:ss");
      }
    }

    if (s.type === SlotType.DAILY) {
      const _timezone = moment(s.windowStart).format("Z");
      if (_timezone.charAt(0) === '+') {
        s.dailyTime = moment(s.windowStart).subtract(_timezone).format("HH:mm:ss");
      } else {
        s.dailyTime = moment(s.windowStart).add(_timezone).format("HH:mm:ss");
      }
      s.windowStart = moment(s.windowStart).set({ 'hour': 0, 'minute': 0, 'second': 0 })
    }
    if (s.windowStart) {
      s.windowStart = moment(s.windowStart).toDate();
    }
    if (s.windowEnd) {
      s.windowEnd = moment(s.windowEnd).toDate();
    }

    if (formUpdate) {
      let slots;
      switch (type) {
        case SlotType.FIXED:
          slots = [...fixedSlots];
          slots[indexSlot] = setDailySlots;
          setFixedSlots(slots);
          break;
        case SlotType.DAILY:
          slots = [...dailySlots];
          slots[indexSlot] = s;
          setDailySlots(slots);
          break;
        case SlotType.PERIODIC:
          slots = [...periodicSlots];
          slots[indexSlot] = s;
          setPeriodicSlots(slots);
          break;
        default:
          break;
      }
      setFormUpdate(false);
      setFormDialogOpen(false);
      return;
    }
    switch (type) {
      case SlotType.FIXED:
        setFixedSlots([s].concat(fixedSlots));
        break;
      case SlotType.DAILY:
        setDailySlots([s].concat(dailySlots));
        break;
      case SlotType.PERIODIC:
        setPeriodicSlots([s].concat(periodicSlots));
        break;
      default:
        break;
    }
    setSlotsTab(type);
    setFormDialogOpen(false);
  };

  const handleDone = async () => {
    if (channel === '') {
      setError(true);
      return;
    }
    onSubmit(channel, fixedSlots, dailySlots, periodicSlots);
  };

  const handleRemoveSlot = (id, index) => {
    let newSlots;
    switch (slotsTab) {
      case SlotType.FIXED:
        newSlots = [...fixedSlots];
        newSlots.splice(index, 1);
        setFixedSlots(newSlots);
        break;
      case SlotType.DAILY:
        newSlots = [...dailySlots];
        newSlots.splice(index, 1);
        setDailySlots(newSlots);
        break;
      case SlotType.PERIODIC:
        newSlots = [...periodicSlots];
        newSlots.splice(index, 1);
        setPeriodicSlots(newSlots);
        break;
      default:
        break;
    }
  };

  const handleUpdateSlot = (s, index) => {
    let slot;
    switch (slotsTab) {
      case SlotType.FIXED:
        slot = { ...fixedSlots[index] };
        break;
      case SlotType.DAILY:
        slot = { ...dailySlots[index] };
        slot.weeklySchedule = { ...dailySlots[index].weeklySchedule };
        break;
      case SlotType.PERIODIC:
        slot = { ...periodicSlots[index] };
        slot.weeklySchedule = { ...periodicSlots[index].weeklySchedule };
        break;
      default:
        break;
    }
    setNewSlot(slot);
    setIndexSlot(index);
    setFormUpdate(true);
    setFormDialogType(slotsTab);
    setFormDialogOpen(true);
  };

  return (
    <GenericStep
      onDone={handleDone}
      onBack={() => back()}
    >
      <Typography variant="h2" align="center">{t('Programmazione')}</Typography>
      <Box marginLeft={5} marginRight={5} display="flex" justifyContent="space-around" marginBottom={1} alignItems="center">
        {channelSelected !== "" ?
          <Box width="50%" display="flex" justifyContent="space-around">
            <Chip label={channelSelected.title} />
            <Chip label={channelSelected.timezone} />
            <Chip label={moment().tz(channelSelected.timezone).format("Z")} />
          </Box> : null}
        <Box width="50%" display="flex" justifyContent="center">
          <Button
            disabled={channel === ''}
            startIcon={<AddIcon />}
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(e) => setAnchorPopAdd(e.currentTarget)}
            variant="contained"
            color="primary"
            style={{ borderRadius: 50 }}
          >
            {`${t('Aggiungi')} Slot`}
          </Button>

          <Popover
            open={openPopAdd}
            anchorEl={anchorPopAdd}
            onClose={() => setAnchorPopAdd(null)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <List>
              <ListItem
                button
                onClick={() => {
                  addSlot(SlotType.FIXED);
                  setAnchorPopAdd(null);
                }}
              >
                <ListItemText>Fixed Slot</ListItemText>
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  addSlot(SlotType.DAILY);
                  setAnchorPopAdd(null);
                }}
              >
                <ListItemText>Daily Slot</ListItemText>
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  addSlot(SlotType.PERIODIC);
                  setAnchorPopAdd(null);
                }}
              >
                <ListItemText>Periodic Slot</ListItemText>
              </ListItem>
            </List>
          </Popover>
        </Box>
      </Box>
      <Divider style={{ marginTop: 15 }} />
      <Box marginTop={1}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item>
            <ButtonGroup size="small" color="primary">
              {fixedSlots.length > 0
                ? (
                  <Button
                    variant={slotsTab === SlotType.FIXED ? 'contained' : 'outlined'}
                    onClick={() => setSlotsTab(SlotType.FIXED)}
                  >
                    Fixed slots
                  </Button>
                )
                : undefined}
              {dailySlots.length > 0
                ? (
                  <Button
                    variant={slotsTab === SlotType.DAILY ? 'contained' : 'outlined'}
                    onClick={() => setSlotsTab(SlotType.DAILY)}
                  >
                    Daily slots
                  </Button>
                )
                : undefined}
              {periodicSlots.length > 0
                ? (
                  <Button
                    variant={slotsTab === SlotType.PERIODIC ? 'contained' : 'outlined'}
                    onClick={() => setSlotsTab(SlotType.PERIODIC)}
                  >
                    Periodic slots
                  </Button>
                )
                : undefined}
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} style={{ margin: 0, padding: 0 }} />
          <Grid item>
            {fixedSlots.length + dailySlots.length + periodicSlots.length > 0 ? (
              <Paper variant="outlined">
                <TableForSlots
                  slotsType={slotsTab}
                  fixedSlots={fixedSlots}
                  dailySlots={dailySlots}
                  periodicSlots={periodicSlots}
                  newSlots
                  onRemove={handleRemoveSlot}
                  onUpdate={handleUpdateSlot}
                  channel={channel}
                  channelsMap={channelsListMap}

                />
              </Paper>
            ) : (
              <Alert severity="warning">
                {t('wizard.noSlotAdded')}
              </Alert>
            )}
          </Grid>
        </Grid>
      </Box>
      <Dialog open={formDialogOpen} maxWidth="xs">
        <DialogContent>
          <SlotCard
            slotType={formDialogType}
            slot={newSlot}
            onRemoveSlot={() => {
              setFormUpdate(false);
              setFormDialogOpen(false);
            }}
            onChangeSlot={(sl) => {
              setNewSlot({ ...sl });
            }}
            channel={channel}
            channelsMap={channelsListMap}
          />
        </DialogContent>
        <Box display="flex" justifyContent="center" p={1} pb={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => appendSlot(formDialogType, newSlot)}
          >
            {formUpdate ? t('Modifica') : t('Crea')}
          </Button>
        </Box>
      </Dialog>
    </GenericStep>
  );
};

CampaignScheduleStep.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  back: PropTypes.func,
  isWizard: PropTypes.bool
};

export default CampaignScheduleStep;
