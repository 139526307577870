import {
  Box,
  Chip,
  FormLabel,
  IconButton,
  InputAdornment,
  Slider,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SlotType } from '../../models/slot';
import DailySlotForm from './DailySlotForm';
import FixedSlotForm from './FixedSlotForm';
import PeriodicSlotForm from './PeriodicSlotForm';
import momentTimezone from "moment-timezone";
import MomentAdapter from "@material-ui/pickers/adapter/moment";

const TimezoneBox = ({ channel, channelsMap }) => {
  const [date, setDate] = useState('');
  const dateInterval = useRef(null);
  const timeZone = useRef(null);

  const { moment } = new MomentAdapter({ instance: momentTimezone });

  useEffect(() => {
    return () => {
      clearInterval(dateInterval.current);
      dateInterval.current = null;
    }
  }, [])

  const dispTZ = () => {
    setLocal();
    dateInterval.current = setInterval(() => {
      setLocal();
    }, 1000)
  }

  const setLocal = () => {
    if (timeZone.current !== null) {
      //let str = new Date().toLocaleString("en-US", { timeZone: tz, timeZoneName: "short", hour12: false });
      setDate(moment().tz(timeZone.current).format("yyyy/MM/DD HH:mm:ss Z"));
    }
  }

  const localeString = useMemo(() => {
    return (
      <Box marginY={1} display="flex" justifyContent="center" alignItems="center" marginBottom="20px">
        <Typography>{date}</Typography>
      </Box>
    )
  }, [date])

  const render = useMemo(() => {
    if (channel && channelsMap && channelsMap.current) {
      const ch = channelsMap.current.get(channel);
      timeZone.current = ch.timezone;
      dispTZ();

      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography marginRight={1}>{ch.title}</Typography>
          <Chip label={ch.timezone} />
        </Box>
      )
    }
  }, [channel])

  if (!channel || channel === '') return null;

  return (
    <Box marginTop={1} marginBottom={3} >
      {render}
      {localeString}
    </Box>

  );
}

const SlotCard = ({
  slotType,
  slot,
  onRemoveSlot,
  onChangeSlot,
  channel,
  channelsMap
}) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    console.log("MOUNT SLOTCARD", slotType,
      slot,
      onRemoveSlot,
      onChangeSlot,
      channel,
      channelsMap);
  }, [])

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          size="small"
          onClick={onRemoveSlot}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <TimezoneBox channel={channel} channelsMap={channelsMap} />
      {slotType === SlotType.FIXED
        ? <FixedSlotForm slot={slot} onChangeSlot={onChangeSlot} timezone={channelsMap.current.get(channel).timezone} />
        : undefined}
      {slotType === SlotType.DAILY
        ? <DailySlotForm slot={slot} onChangeSlot={onChangeSlot} timezone={channelsMap.current.get(channel).timezone} />
        : undefined}
      {slotType === SlotType.PERIODIC
        ? <PeriodicSlotForm slot={slot} onChangeSlot={onChangeSlot} timezone={channelsMap.current.get(channel).timezone} />
        : undefined}
      <TextField
        fullWidth
        margin="normal"
        label={t('slot.duration')}
        type="number"
        variant="outlined"
        InputProps={{
          startAdornment: <InputAdornment position="start">sec</InputAdornment>,
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          min: 0,
          step: 5,
        }}
        size="small"
        value={slot.duration}
        onChange={(e) => {
          let val = parseInt(e.target.value, 10);
          if (Number.isNaN(val)) val = '';
          else if (val < 0) return;
          slot.duration = val;
          onChangeSlot(slot);
        }}
      />
      <Typography
        align="center"
        variant="h3"
        style={{ fontWeight: 400, fontSize: '12pt' }}
      >
        {t('Priority')}
      </Typography>
      <Box display="flex" alignItems="center" margin={1}>
        <Slider
          aria-labelledby="continuous-slider"
          valueLabelDisplay="auto"
          value={slot.priority}
          min={1}
          onChange={(e, val) => {
            slot.priority = Array.isArray(val) ? val[0] : val;
            onChangeSlot(slot);
          }}
        />
        <FormLabel style={{ marginLeft: 15, width: 30, textAlign: 'right' }}>{slot.priority}</FormLabel>
      </Box>
      <Typography
        align="center"
        variant="h3"
        style={{ fontWeight: 400, fontSize: '12pt' }}
      >
        Preload
      </Typography>
      <Box display="flex" flex={1} alignItems="center">
        <Switch
          color="primary"
          size="small"
          checked={slot.preload}
          onChange={(e) => {
            slot.preload = e.target.checked;
            onChangeSlot(slot);
          }}
        />
        <TextField
          fullWidth
          margin="normal"
          label={t('slot.preloadTime')}
          type="number"
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start">sec</InputAdornment>,
          }}
          size="small"
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            min: 0,
            step: 1,
          }}
          disabled={!slot.preload}
          value={slot.preloadTime}
          onChange={(e) => {
            const val = parseInt(e.target.value, 10);
            if (Number.isNaN(val)) return;
            if (val < 0) return;
            slot.preloadTime = val;
            onChangeSlot(slot);
          }}
        />
      </Box>
    </Box>
  );
};

SlotCard.propTypes = {
  slotType: PropTypes.string.isRequired,
  slot: PropTypes.object.isRequired,
  onRemoveSlot: PropTypes.func.isRequired,
  onChangeSlot: PropTypes.func.isRequired
};

export default SlotCard;
