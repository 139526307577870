import {
  Box,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import React from 'react';

const SearchBar = ({
  placeholder = 'Cerca', onSearchStringChange, onRefresh, ...rest
}) => {
  return (
    <Card {...rest}>
      <CardContent>
        <Box>
          <TextField
            onChange={onSearchStringChange}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon
                    fontSize="small"
                    color="action"
                  >
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton onClick={onRefresh} size="large">
                  <SvgIcon
                    fontSize="small"
                    color="action"
                  >
                    <RefreshIcon />
                  </SvgIcon>
                </IconButton>
              ),
            }}
            placeholder={placeholder}
            variant="outlined"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onSearchStringChange: PropTypes.func.isRequired,
  onRefresh: PropTypes.func,
};

export default SearchBar;
