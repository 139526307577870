/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-await-in-loop */
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  TablePagination,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollBar from 'react-perfect-scrollbar';
import Requests from '../../../BackofficeRequests/Requests';
import SlotCard from '../../../components/SlotCard/index';
import TableForSlots from '../../../components/TableForSlots';
import { useAlerts } from '../../../context/alert';
import {
  DailySlot, FixedSlot, PeriodicSlot, SlotType
} from '../../../models/slot';
import {
  datesAreOnSameDay, secondsFromDate, timeFromSeconds, timeFromString
} from '../../../utils/dates';


import momentTimezone from "moment-timezone";
import MomentAdapter from "@material-ui/pickers/adapter/moment";

const UpdateSlotsCard = ({ idCampaign, selectedSchedule }) => {
  const { t, i18n } = useTranslation();

  const [anchorPopAdd, setAnchorPopAdd] = useState(null);
  const openPopAdd = Boolean(anchorPopAdd);
  const [slotsTab, setSlotsTab] = useState(SlotType.FIXED);

  const [oldSlots, setOldSlots] = useState([]);
  const [oldSlotsCount, setColdSlotsCount] = useState(0);
  const [tablePage, setTablePage] = useState(0);
  const [rowsLimit, setRowsLimit] = useState(10);

  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [formDialogType, setFormDialogType] = useState('');
  const [newSlot, setNewSlot] = useState();

  const { showError, showMessage, showConfirm } = useAlerts();

  const [update, setUpdate] = useState(false);
  const [channelName, setChannelName] = useState('');
  const channelsListMap = useRef(null);
  const [channelList, setChannelList] = useState([]);
  const [channel, setChannel] = useState('');
  const tzRef = useRef("");
  const { moment } = new MomentAdapter({ instance: momentTimezone });

  // Get ALL Channels
  useEffect(async () => {
    const res = await Requests.instance.schedule.getList(1, 100);
    if (res.data.docs) {
      let schedules = [];
      channelsListMap.current = new Map();
      res.data.docs.forEach((channel) => {
        if (channel.enabled) {
          schedules.push(channel);
          channelsListMap.current.set(channel.id, channel);
        }
      })
      setChannelList(schedules);
    }
  }, [])

  // Get Slots
  const fetchSlots = async (type, page, limit) => {
    try {
      const { data } = await Requests.instance.slot.getByCampaign(idCampaign, type, page + 1, limit);
      setOldSlots(data.docs);
      console.log("fetch slots", data.docs);
      setColdSlotsCount(parseInt(data.totalDocs, 10));
      if (+data.totalDocs > 0 && data.docs.length === 0) setTablePage(tablePage - 1);
    } catch (err) {
      showError(err.message);
    }
  };

  // Get Channel
  const fetchChannel = useMemo(() => async () => {
    try {
      const { data } = await Requests.instance.schedule.getById(selectedSchedule);
      setChannelName(data.channels[0].name);
      setChannel(data.id);
      tzRef.current = data.timezone;
    } catch (err) {
      showError(err.message);
    }
  }, []);

  useEffect(() => {
    fetchChannel();
    fetchSlots(slotsTab, tablePage, rowsLimit);
  }, [slotsTab, tablePage, rowsLimit]);

  const handleSubmit = async () => {
    if ((formDialogType === SlotType.DAILY || formDialogType === SlotType.PERIODIC) && moment(newSlot.windowEnd).diff(moment(newSlot.windowStart), 'seconds') < 0) {
      showError(t("slot.invalidStartDate"));
      return;
    }
    if (selectedSchedule === '') {
      showError(t("slot.genericError"));
      return;
    }

    const s = newSlot;
    if (s.windowStart && s.type === SlotType.PERIODIC) {
      const _timezone = moment(s.windowStart).format("Z");
      if (_timezone.charAt(0) === '+') {
        s.periodicTimeStart = moment(s.windowStart).subtract(_timezone).format("HH:mm:ss");
      } else {
        s.periodicTimeStart = moment(s.windowStart).add(_timezone).format("HH:mm:ss");
      }
    }
    if (s.windowEnd && s.type === SlotType.PERIODIC) {
      const _timezone = moment(s.windowEnd).format("Z");
      if (_timezone.charAt(0) === '+') {
        s.periodicTimeEnd = moment(s.windowEnd).subtract(_timezone).format("HH:mm:ss");
      } else {
        s.periodicTimeEnd = moment(s.windowEnd).add(_timezone).format("HH:mm:ss");
      }
    }

    if (s.type === SlotType.DAILY) {
      const _timezone = moment(s.windowStart).format("Z");
      if (_timezone.charAt(0) === '+') {
        s.dailyTime = moment(s.windowStart).subtract(_timezone).format("HH:mm:ss");
      } else {
        s.dailyTime = moment(s.windowStart).add(_timezone).format("HH:mm:ss");
      }
      s.windowStart = moment(s.windowStart).set({ 'hour': 0, 'minute': 0, 'second': 0 })

    }

    if (moment(s.windowStart).zone() === moment(s.windowEnd).zone()) {

      if (s.windowStart) {
        s.windowStart = moment(s.windowStart).toDate();
      }
      if (s.windowEnd) {
        s.windowEnd = moment(s.windowEnd).toDate();
      }

      //try {
      if (update) {
        await Requests.instance.slot.update(s);
        fetchSlots(slotsTab, tablePage, rowsLimit);
        setFormDialogOpen(false);
        setUpdate(false);
        showMessage(t('Slot modificato correttamente!'));
        return;
      }

      switch (formDialogType) {
        case SlotType.FIXED:
          await Requests.instance.slot.createFixed(
            selectedSchedule,
            moment(s.fixedDateTime).toDate(),
            newSlot.duration,
            newSlot.priority,
            newSlot.preload,
            newSlot.preloadTime,
            idCampaign
          );
          break;
        case SlotType.DAILY:
          await Requests.instance.slot.createDaily(
            selectedSchedule, s, idCampaign
          );
          break;
        case SlotType.PERIODIC:
          await Requests.instance.slot.createPeriodic(
            selectedSchedule, s, idCampaign
          );
          break;
        default:
          break;
      }
      fetchSlots(slotsTab, tablePage, rowsLimit);
      setFormDialogOpen(false);
      showMessage(t('Slot aggiunto correttamente!'));
    } else {
      showError(t('Lo slot schedulato si trova a cavallo di un cambio di ora legale/solare'));
    }

  };

  const handleDelete = async (idSlot) => {
    try {
      const res = await showConfirm(t('Eliminare lo slot?'));
      if (!res) return;
      await Requests.instance.slot.delete(idSlot);
      fetchSlots(slotsTab, tablePage, rowsLimit);
    } catch (err) {
      showError(err.message);
    }
  };

  const handleChangeTab = (type) => {
    setTablePage(0);
    setSlotsTab(type);
  };

  const handleOpenUpdate = (slot, index) => {
    const s = { ...slot };
    switch (slot.type) {
      case SlotType.FIXED:
        s.fixedDateTime = s.fixedDateTime !== undefined ? s.fixedDateTime : s.fixedTime;
        break;
      case SlotType.DAILY:
        const timeParts = s.dailyTime.split(':').map(part => parseInt(part));
        let daily = moment(s.windowStart).tz(tzRef.current).set({
          'hour': timeParts[0],
          'minute': timeParts[1],
          'second': timeParts[2]
        });
        daily = moment(daily).add(moment(daily).utcOffset(), 'minutes');
        daily = moment(daily).tz(tzRef.current);
        s.windowStart = moment(daily).format("YYYY-MM-DD HH:mm:ss");
        s.weeklySchedule = { ...slot.weeklySchedule };
        break;
      case SlotType.PERIODIC:
        s.periodicTimeStart = moment(s.windowStart).format("HH:mm:ss");
        s.periodicTimeEnd = moment(s.windowEnd).format("HH:mm:ss");

        console.log('START', s.periodicTimeStart)
        console.log('END', s.periodicTimeEnd)
        /* 
        const frequency = moment(s.periodicFrequency);
        s.periodicFrequency = frequency.hour() * 3600 + frequency.minute() * 60 + frequency.seconds(); */
        s.weeklySchedule = { ...slot.weeklySchedule };
        break;
      default: break;
    }
    setNewSlot(s);
    setUpdate(true);
    setFormDialogType(s.type);
    setFormDialogOpen(true);
    setAnchorPopAdd(null);
  };

  return (
    channelList.length > 0 ?
      <Card>
        <CardHeader
          title="Schedule slots"
          action={(
            <>
              <Button
                startIcon={<AddIcon />}
                endIcon={<KeyboardArrowDownIcon />}
                onClick={(e) => setAnchorPopAdd(e.currentTarget)}
                variant="contained"
                color="primary"
              >
                {`${t('Aggiungi')} Slot`}
              </Button>

              <Popover
                open={openPopAdd}
                anchorEl={anchorPopAdd}
                onClose={() => setAnchorPopAdd(null)}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <List>
                  <ListItem
                    button
                    onClick={() => {
                      setNewSlot(new FixedSlot());
                      setFormDialogOpen(true);
                      setFormDialogType(SlotType.FIXED);
                      setAnchorPopAdd(null);
                    }}
                  >
                    <ListItemText>Fixed Slot</ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      setNewSlot(new DailySlot());
                      setFormDialogOpen(true);
                      setFormDialogType(SlotType.DAILY);
                      setAnchorPopAdd(null);
                    }}
                  >
                    <ListItemText>Daily Slot</ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      setNewSlot(new PeriodicSlot());
                      setFormDialogOpen(true);
                      setFormDialogType(SlotType.PERIODIC);
                      setAnchorPopAdd(null);
                    }}
                  >
                    <ListItemText>Periodic Slot</ListItemText>
                  </ListItem>
                </List>
              </Popover>
            </>
          )}
        />
        <Divider />
        <Typography
          variant="h4"
          align="center"
          style={{ paddingTop: 10 }}
        >
          {`${t('campaign.slotsHeader')} ${channelName}`}
        </Typography>
        <Box p={2} display="flex" justifyContent="center">
          <ButtonGroup size="small" color="primary">
            <Button
              variant={slotsTab === SlotType.FIXED ? 'contained' : 'outlined'}
              onClick={() => handleChangeTab(SlotType.FIXED)}
            >
              Fixed slots
            </Button>
            <Button
              variant={slotsTab === SlotType.DAILY ? 'contained' : 'outlined'}
              onClick={() => handleChangeTab(SlotType.DAILY)}
            >
              Daily slots
            </Button>
            <Button
              variant={slotsTab === SlotType.PERIODIC ? 'contained' : 'outlined'}
              onClick={() => handleChangeTab(SlotType.PERIODIC)}
            >
              Periodic slots
            </Button>
          </ButtonGroup>
        </Box>
        {oldSlotsCount > 0
          ? (
            <Box display="flex" justifyContent="center" pt={1} pb={4}>
              <Paper variant="outlined">
                <PerfectScrollBar>
                  <TableForSlots
                    slots={oldSlots}
                    slotsType={slotsTab}
                    onRemove={handleDelete}
                    onUpdate={handleOpenUpdate}
                    channel={channel}
                    channelsMap={channelsListMap}
                  />
                  <TablePagination
                    component="div"
                    count={oldSlotsCount}
                    rowsPerPage={rowsLimit}
                    page={tablePage}
                    rowsPerPageOptions={[5, 10, 25]}
                    onPageChange={(e, page) => setTablePage(page)}
                    labelRowsPerPage={t('Righe per pagina')}
                    onRowsPerPageChange={(e) => {
                      setRowsLimit(parseInt(e.target.value, 10));
                      setTablePage(0);
                    }}
                  />
                </PerfectScrollBar>
              </Paper>
            </Box>
          )
          : (
            <Box display="flex" justifyContent="center" pt={2} pb={10}>
              <Alert severity="warning">
                {`${t("slot.emptyslot")}`}
              </Alert>
            </Box>
          )}
        <Dialog open={formDialogOpen} maxWidth="xs">
          <DialogContent>
            <SlotCard
              slotType={formDialogType}
              slot={newSlot}
              onRemoveSlot={() => {
                setUpdate(false);
                setFormDialogOpen(false);
              }}
              onChangeSlot={(sl) => {
                setNewSlot({ ...sl });
              }}
              channel={channel}
              channelsMap={channelsListMap}
            />
          </DialogContent>
          <Box display="flex" justifyContent="center" p={1} pb={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              {update ? t('Modifica') : t('Crea')}
            </Button>
          </Box>
        </Dialog>
      </Card>
      : null
  );
};

UpdateSlotsCard.propTypes = {
  idCampaign: PropTypes.string.isRequired,
  selectedSchedule: PropTypes.string.isRequired
};

export default UpdateSlotsCard;
