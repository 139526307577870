import {
  ListItemButton as MuiListItemButton, ListItemIcon as MuiListItemIcon, ListItemText
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { styled, useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ListItem = styled((props) => (<MuiListItemButton disableGutters {...props} />))(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
  width: 25,
  height: 25,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const NavItem = ({
  href,
  icon,
  title,
  isActive,
  ...rest
}) => {
  const theme = useTheme();
  const active = isActive && isActive(href);
  const navigate = useNavigate();

  const activeStyle = {
    color: theme.palette.primary.main,
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  return (
    <ListItem
      onClick={() => { navigate(href); }}
      sx={{
        ...(active && activeStyle)
      }}
      {...rest}
    >
      <ListItemIcon>{icon && icon}</ListItemIcon>
      <ListItemText sx={{ paddingLeft: theme.spacing(2) }} disableTypography primary={title} />
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
};

export default NavItem;
