import {
  DateTimePicker,
  LocalizationProvider,
} from '@mui/lab';
import { Box, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

import momentTimezone from "moment-timezone";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { SlotType } from '../../models/slot';

const useStyles = makeStyles((theme) => ({
  verticalSpacing: {
    marginTop: theme.spacing(2)
  }
}));

const TimeWindowForm = ({ slot, onChangeSlot, timezone }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const { moment } = new MomentAdapter({ instance: momentTimezone });

  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);

  useEffect(() => {
    if (slot.windowStart === null || slot.windowStart === undefined) {
      const time = moment().tz(timezone).format("YYYY-MM-DD HH:mm:ss");
      setStartDateTime(time);
      slot.windowStart = time;
    } else {
      setStartDateTime(moment(slot.windowStart).tz(timezone).format("YYYY-MM-DD HH:mm:ss"));
    }
    if (slot.windowEnd === null || slot.windowEnd === undefined) {
      const time = moment().tz(timezone).format(slot.type === SlotType.DAILY ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm:ss");
      setEndDateTime(time);
      slot.windowEnd = time;
    } else {
      setEndDateTime(moment(slot.windowEnd).tz(timezone).format(slot.type === SlotType.DAILY ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm:ss"));
    }
  }, [])

  return (
    <Fragment>
      <LocalizationProvider dateAdapter={MomentAdapter} >
        <Box display="flex" flexDirection={"column"} justifyContent={'center'} alignItems="streach">
          <Box display="flex" flexDirection={"column"} justifyContent={'center'} marginBottom="15px" alignItems="streach">
            <DateTimePicker
              margin="normal"
              label={t('slot.startDate')}
              inputVariant="outlined"
              size="small"
              ampm={true}
              inputFormat='yyyy/MM/DD HH:mm:ss'
              views={['day', 'month', 'year', 'hours', 'minutes', 'seconds']}
              value={startDateTime}
              okText='OK'
              onChange={(date) => {
                if (date) {
                  const time = moment(date).tz(timezone).format("YYYY-MM-DD HH:mm:ssZZ");
                  setStartDateTime(time);
                  slot.windowStart = time;
                  if (slot.type === SlotType.DAILY) {
                    slot.dailyTime = moment(time).tz(timezone).format("HH:mm:ssZZ");
                  }
                  onChangeSlot(slot);
                }
              }}
              renderInput={props => <TextField {...props} />}
            />
          </Box>
          <Box display="flex" flexDirection={"column"} justifyContent={'center'} alignItems="streach">
            <DateTimePicker
              css
              margin="normal"
              marginTop="10px"
              label={t('slot.endDate')}
              inputVariant="outlined"
              size="small"
              ampm={true}
              inputFormat={slot.type === SlotType.DAILY ? 'yyyy/MM/DD' : 'yyyy/MM/DD HH:mm:ss'}
              views={slot.type === SlotType.DAILY ? ['day', 'month', 'year'] : ['day', 'month', 'year', 'hours', 'minutes', 'seconds']}
              value={endDateTime}
              onChange={(date) => {
                if (date) {
                  let time = null;
                  if (slot.type === SlotType.DAILY) {
                    time = moment(date).format("YYYY-MM-DD HH:mm:ssZZ");
                  } else {
                    time = moment(date).tz(timezone).format("YYYY-MM-DD HH:mm:ssZZ");
                  }
                  setEndDateTime(time);
                  slot.windowEnd = time;
                  onChangeSlot(slot);
                }
              }}
              renderInput={props => <TextField {...props} />}
            />
          </Box>
        </Box>
      </LocalizationProvider>
    </Fragment>
  );
};

TimeWindowForm.propTypes = {
  slot: PropTypes.object,
  onChangeSlot: PropTypes.func
};

export default TimeWindowForm;
