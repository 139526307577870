import makeStyles from '@mui/styles/makeStyles';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import clsx from 'clsx';
import React, {
  HTMLAttributes, useLayoutEffect, useRef, useState
} from 'react';
import Draggable from 'react-draggable';
import Lottie from 'react-lottie';
import { ResizableBox } from 'react-resizable';

const useStyles = makeStyles((/* theme: Theme */) => ({
  root: {
    display: 'flex',
    width: '100%',
    paddingTop: '56.25%',
    borderRadius: '6px',
    position: 'relative',
    backgroundColor: '#333',
    '&.typeResize': {
      '&& $innerContainer': {
        backgroundColor: '#2E9FD0',
      },
      '&& $adBox': {
        backgroundColor: '#777',
      },
    },
    '&.typeOverlay': {
      '&& $innerContainer': {
        backgroundColor: '#777',
      },
      '&& $adBox': {
        backgroundColor: '#2E9FD0',
      },
    },
    '&.typeInteractiveOverlay': {
      '&& $innerContainer': {
        backgroundColor: '#2E9FD0',
      }
    }
  },
  innerContainer: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'absolute',
    top: 5,
    left: 5,
    right: 5,
    bottom: 5,
  },
  adBox: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative'
  },
  iframe: {
    border: 0,
    margin: 5,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  animatedDrag: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

export enum ScreenWidgetDisplayType {
  OVERLAY = 'banner',
  VIDEO_SCALING = 'resize',
  INTERACTIVE_VIDEO_SCALING = 'interactiveFrame',
  INTERACTIVE_OVERLAY = 'interactiveBanner',
  ANIMATED = 'animated'
}

export type PositionChangedCallback = (x: number, y: number) => void;

export type SizeChangedCallback = (width: number, height: number) => void;

export interface ScreenWidgetProps extends HTMLAttributes<HTMLDivElement> {
  type: ScreenWidgetDisplayType;
  x: number;
  y: number;
  width: number;
  height: number;
  lockRatio?: boolean;
  image: HTMLImageElement | null;
  onPositionChange?: PositionChangedCallback;
  onSizeChange?: SizeChangedCallback;
  frameURL: string;
  animationJSON: unknown;
}

const ScreenWidget = (props: ScreenWidgetProps) => {
  const classes = useStyles();

  const rootRef = useRef<HTMLDivElement>(null);
  const [displaySize, setDisplaySize] = useState({ width: 1920, height: 1080 });
  const [scaleRatio, setScaleRatio] = useState({
    x: 1, y: 1, x720p: 1.5, y720p: 1.5
  });

  useLayoutEffect(() => {
    const updateFunc = () => {
      if (rootRef.current) {
        const w = rootRef.current.offsetWidth;
        const h = rootRef.current.offsetHeight;
        setScaleRatio({
          x: w / 1920,
          y: h / 1080,
          x720p: w / 1280,
          y720p: h / 720,
        });
        setDisplaySize({
          width: w,
          height: h,
        });
      }
    };

    updateFunc();

    window.addEventListener('resize', updateFunc);

    return () => window.removeEventListener('resize', updateFunc);
  }, []);

  const {
    x, y, lockRatio = true,
    type, image,
    width, height,
    onPositionChange,
    onSizeChange,
    frameURL,
    animationJSON,
    // ...otherProps
  } = props;

  const realX = x * scaleRatio.x;
  const realY = y * scaleRatio.y;
  const realWidth = width * scaleRatio.x;
  const realHeight = height * scaleRatio.y;

  return (
    <div
      className={clsx(
        classes.root,
        {
          typeResize: type === ScreenWidgetDisplayType.VIDEO_SCALING || type === ScreenWidgetDisplayType.INTERACTIVE_VIDEO_SCALING,
          typeOverlay: type === ScreenWidgetDisplayType.OVERLAY || type === ScreenWidgetDisplayType.ANIMATED,
          typeInteractiveOverlay: type === ScreenWidgetDisplayType.INTERACTIVE_OVERLAY
        },
      )}
    >
      {type === ScreenWidgetDisplayType.INTERACTIVE_VIDEO_SCALING
        ? (
          <iframe
            src={frameURL}
            title="frame"
            className={classes.iframe}
            style={{
              transform: `scale(${scaleRatio.x720p},${scaleRatio.y720p})`,
              transformOrigin: 'top left',
              width,
              height,
            }}
          />
        ) : null}
      <div
        ref={rootRef}
        className={classes.innerContainer}
        style={{
          backgroundImage: image && type === ScreenWidgetDisplayType.VIDEO_SCALING
            ? `url(${image.src})` : 'none',
          backgroundColor: type === ScreenWidgetDisplayType.INTERACTIVE_VIDEO_SCALING
            ? 'rgb(0,0,0,0)' : undefined,
        }}
      >
        {type === ScreenWidgetDisplayType.INTERACTIVE_OVERLAY
          ? (
            <iframe
              src={frameURL}
              title="interactiveOverlay"
              style={{
                border: 0,
                transform: `scale(${scaleRatio.x720p},${scaleRatio.y720p})`,
                transformOrigin: '0 0',
                width,
                height,
              }}
            />
          )
          : (
            <Draggable
              bounds="parent"
              grid={[1, 1]}
              position={{
                x: realX,
                y: realY,
              }}
              onDrag={(/* e, ui */) => {
                // setX(ui.x);
                // setY(ui.y);
              }}
              onStop={(e, ui) => {
                if (onPositionChange) {
                  const scaledX = Math.round(ui.x / scaleRatio.x);
                  const scaledY = Math.round(ui.y / scaleRatio.y);
                  onPositionChange(scaledX, scaledY);
                }
              }}
              handle=".dragHandle"
            >
              <ResizableBox
                height={realHeight}
                width={realWidth}
                className={classes.adBox}
                axis="both"
                maxConstraints={[displaySize.width - realX, displaySize.height - realY]}
                lockAspectRatio={lockRatio}
                style={{
                  backgroundImage: image && type === ScreenWidgetDisplayType.OVERLAY
                    ? `url(${image.src})` : 'none',
                }}
                onResize={(e, d) => {
                  if (onSizeChange) {
                    const scaledWidth = Math.round(d.size.width / scaleRatio.x);
                    const scaledHeight = Math.round(d.size.height / scaleRatio.y);
                    onSizeChange(scaledWidth, scaledHeight);
                  }
                }}
              >
                <>
                  {type === ScreenWidgetDisplayType.ANIMATED
                    ? (
                      <Lottie
                        options={{
                          animationData: animationJSON,
                          loop: true,
                          autoplay: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid meet'
                          }
                        }}
                        isStopped={false}
                        isPaused={false}
                      />
                    ) : null}
                  <strong
                    className={clsx(
                      'dragHandle',
                      {
                        [classes.animatedDrag]: type === ScreenWidgetDisplayType.ANIMATED
                      },
                    )}
                    style={{ cursor: 'move' }}
                  >
                    <DragHandleIcon style={{ color: 'white', position: 'absolute', top: 0 }} />
                  </strong>
                </>
              </ResizableBox>
            </Draggable>
          )}
      </div>
    </div>
  );
};

export default ScreenWidget;
