import {
  Avatar, Box, Typography
} from '@mui/material';
import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const ValueCard = ({ title, value, icon }) => {
  const theme = useTheme();
  return (
    <Box display="flex" p={1}>
      <Avatar style={{ height: 34, width: 34, backgroundColor: theme.palette.primary.main }}>
        {icon}
      </Avatar>
      <Box ml={2}>
        <Typography
          variant="h6"
          color="textSecondary"
          gutterBottom
        >
          {title.toUpperCase()}
        </Typography>
        <Typography
          color="textPrimary"
          variant="h3"
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

ValueCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.node
};

export default ValueCard;
