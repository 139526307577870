/* eslint-disable no-await-in-loop */
/* eslint-disable max-len */
import CloseIcon from '@mui/icons-material/Close';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import PublishIcon from '@mui/icons-material/Publish';
import RefreshIcon from '@mui/icons-material/Refresh';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-resizable/css/styles.css';
import Requests from '../../../BackofficeRequests/Requests';
import Page from '../../../components/Page';
import { useAlerts } from '../../../context/alert';
import { useConfig } from '../../../context/config';
import vendors from '../../../utils/vendors';
import ScreenWidget, { ScreenWidgetDisplayType as DisplayType } from './ScreenWidget';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  pageTitle: {
    fontWeight: 300,
  },
  sectionTitle: {
    fontWeight: 300,
    fontSize: '16pt',
  },
  extIcon: {
    marginRight: theme.spacing(1),
  },
  gridBreak: {
    padding: '0 !important',
    margin: '0 !important',
  },
}));

// return aspect ratio of the dimensions w, h (Es. if 16/9 return [16, 9])
const scaleFraction = (w: number, h: number) => {
  let a; let b; let
    tmp;
  if (w > h) {
    a = w;
    b = h;
  } else {
    a = h;
    b = w;
  }
  while (b !== 0) {
    tmp = b;
    b = a % b;
    a = tmp;
  }
  return [w / a, h / a];
};

interface SizeInterface {
  height: number;
  width: number;
}

type AssetBuilderProps = {
  idAdUnit: string,
  isActionCreative: boolean,
  onUpload: () => void
};

const tags = ['tag1', 'tag2'];

const AssetBuilder = ({
  idAdUnit,
  isActionCreative,
  onUpload
}: AssetBuilderProps) => {
  const classes = useStyles();
  const { t: trans } = useTranslation();
  const config = useConfig();

  const uploadInputRef = useRef<HTMLInputElement>(null);

  const screenSize = { height: 1080, width: 1920 };
  const defaultBannerSize = { height: 120, width: 450 };
  const defaultRate = 75;

  const [title, setTitle] = useState('');
  const [type, setType] = useState(DisplayType.OVERLAY);
  const [height, setHeight] = useState(defaultBannerSize.height);
  const [width, setWidth] = useState(defaultBannerSize.width);
  const [x, setX] = useState((screenSize.width - width) / 2);
  const [y, setY] = useState((screenSize.height - height) / 2);
  const [lockRatio, setLockRatio] = useState(true);
  const [scaleRate, setScaleRate] = useState(defaultRate);
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [animationJSON, setAnimationJSON] = useState<unknown | null>(null);
  const [defaultImageSize, setDefaultImageSize] = useState<SizeInterface | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedVendors, setSelectedVendors] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorFile, setErrorFile] = useState(false);
  const [fileName, setFileName] = useState('No file selected.');

  const [interURL, setInterURL] = useState('');
  const [interURLText, setInterURLText] = useState('');

  const { showError } = useAlerts();

  const initForVideoScaling = () => {
    const size = {
      height: screenSize.height * (defaultRate / 100),
      width: screenSize.width * (defaultRate / 100),
    };
    setHeight(size.height);
    setWidth(size.width);
    setY((screenSize.height - size.height) / 2);
    setX((screenSize.width - size.width) / 2);
    setLockRatio(true);
  };

  const initForOverlay = (size?: { height: number, width: number } | null) => {
    if (!size) size = image ? defaultImageSize : defaultBannerSize;
    if (size) {
      setHeight(size.height);
      setWidth(size.width);
      setY(Math.round((screenSize.height - size.height) / 2));
      setX(Math.round((screenSize.width - size.width) / 2));
    }
  };

  const initForInteractiveOverlay = () => {
    setHeight(screenSize.height);
    setWidth(screenSize.width);
    setY(0);
    setX(0);
  };

  const reloadBuilder = (t: DisplayType) => {
    switch (t) {
      case DisplayType.VIDEO_SCALING:
        initForVideoScaling();
        break;
      case DisplayType.INTERACTIVE_VIDEO_SCALING:
        initForVideoScaling();
        break;
      case DisplayType.INTERACTIVE_OVERLAY:
        initForInteractiveOverlay();
        break;
      case DisplayType.OVERLAY:
        initForOverlay();
        break;
      case DisplayType.ANIMATED:
        initForOverlay();
        break;
      default:
    }
  };

  const handleChangeType = (event: ChangeEvent<HTMLInputElement>) => {
    let t: DisplayType;
    if (event.target.value === DisplayType.OVERLAY.toString()) t = DisplayType.OVERLAY;
    else if (event.target.value === DisplayType.VIDEO_SCALING.toString()) t = DisplayType.VIDEO_SCALING;
    else if (event.target.value === DisplayType.INTERACTIVE_VIDEO_SCALING.toString()) t = DisplayType.INTERACTIVE_VIDEO_SCALING;
    else if (event.target.value === DisplayType.INTERACTIVE_OVERLAY.toString()) t = DisplayType.INTERACTIVE_OVERLAY;
    else t = DisplayType.ANIMATED;
    setType(t);
    reloadBuilder(t);
  };

  const handleChangeHeight = (event: ChangeEvent<HTMLInputElement>) => {
    const newHeight = parseInt(event.target.value, 10);
    if (Number.isNaN(newHeight)) return;
    if (newHeight < 20 || newHeight + y > screenSize.height) return;
    if (lockRatio) {
      const newWidth = Math.round(newHeight * (width / height));
      if (newWidth < 20 || newWidth + x > screenSize.width) return;
      setWidth(newWidth);
    }
    setHeight(newHeight);
  };

  const handleChangeWidth = (event: ChangeEvent<HTMLInputElement>) => {
    const newWidth = parseInt(event.target.value, 10);
    if (Number.isNaN(newWidth)) return;
    if (newWidth < 20 || newWidth + x > screenSize.width) return;
    if (lockRatio) {
      const newHeight = Math.round(newWidth * (height / width));
      if (newHeight < 20 || newHeight + y > screenSize.height) return;
      setHeight(newHeight);
    }
    setWidth(newWidth);
  };

  const handleChangeX = (event: ChangeEvent<HTMLInputElement>) => {
    const newX = parseInt(event.target.value, 10);
    if (Number.isNaN(newX)) return;
    if (newX >= 0 && newX + width <= screenSize.width) setX(newX);
  };

  const handleChangeY = (event: ChangeEvent<HTMLInputElement>) => {
    const newY = parseInt(event.target.value, 10);
    if (Number.isNaN(newY)) return;
    if (newY >= 0 && newY + height <= screenSize.height) setY(newY);
  };

  const handleChangeResizeRate = (event: ChangeEvent<HTMLInputElement>) => {
    const rate = parseInt(event.target.value, 10);
    if (Number.isNaN(rate)) return;
    if (rate < 20 || rate > 100) return;
    const newHeight = screenSize.height * (rate / 100);
    const newWidth = screenSize.width * (rate / 100);
    if (newHeight + y <= screenSize.height && newWidth + x <= screenSize.width) {
      setHeight(newHeight);
      setWidth(newWidth);
      setScaleRate(rate);
    }
  };

  const handleCenterVertically = () => {
    setY(Math.round((screenSize.height - height) / 2));
  };

  const handleCenterHorizontally = () => {
    setX(Math.round((screenSize.width - width) / 2));
  };

  const handleLoadImage = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null && event.target.files.length > 0) {
      const file = event.target.files[0];
      setFileName(file.name);
      const img = new Image();
      img.src = URL.createObjectURL(file);
      setImage(img);
      img.onload = () => {
        if (type === DisplayType.OVERLAY) {
          let [w, h] = scaleFraction(img.width, img.height);
          if (w < 10) {
            w *= 100;
            h *= 100;
          } else if (w < 50 || h < 50) {
            w *= 10;
            h *= 10;
          }
          while (w > screenSize.width || h > screenSize.height) {
            w /= 2;
            h /= 2;
          }
          const size = { height: Math.round(h), width: Math.round(w) };
          setDefaultImageSize(size);
          initForOverlay(size);
        } else initForVideoScaling();
      };
    } else {
      setFileName('No file selected.');
      setImage(null);
    }
  };

  const handleLoadAnimated = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null && event.target.files.length > 0) {
      const file = event.target.files[0];
      setFileName(file.name);
      const reader = new FileReader();
      reader.addEventListener('loadend', (e) => {
        setAnimationJSON(JSON.parse(reader.result?.toString() ?? '{}'));
        setLockRatio(false);
      });

      // Start reading the blob as text.
      reader.readAsText(file);
    } else {
      setFileName(trans('builder.noFileSelected'));
      setImage(null);
    }
  };

  const submitForm = async () => {
    if (!title) {
      showError(trans('builder.noTitleMessage'));
      return;
    }

    if (type === DisplayType.INTERACTIVE_VIDEO_SCALING
      || type === DisplayType.INTERACTIVE_OVERLAY) {
      setIsLoading(true);
      try {
        const { data } = await Requests.instance.creative.addAssetWithExternalURL(idAdUnit, interURLText, {
          title,
          frame: {
            top: y,
            left: x,
            width,
            height,
          },
          priority: 1000,
          type,
        });

        await Requests.instance.asset.addFilter(data.id, 'tag', selectedTags);
        await Requests.instance.asset.addFilter(data.id, 'vendor', selectedVendors);

        setIsLoading(false);
        onUpload();
      } catch (err: any) {
        showError(err.message);
      }
    } else if (uploadInputRef.current?.files) {
      setIsLoading(true);
      const file = uploadInputRef.current.files[0];
      if (!file || title === '') {
        setErrorFile(!file);
        setErrorTitle(title === '');
        setIsLoading(false);
        return;
      }
      try {
        const { data } = await Requests.instance.creative.addAsset(idAdUnit, file, {
          title,
          frame: {
            top: y,
            left: x,
            width,
            height,
          },
          priority: 1000,
          type,
        });

        await Requests.instance.asset.addFilter(data.id, 'tag', selectedTags);
        await Requests.instance.asset.addFilter(data.id, 'vendor', selectedVendors);

        setIsLoading(false);
        onUpload();
      } catch (err: unknown) {
        if (err instanceof Error) {
          showError(err.message);
        }
      }
    }
  };

  return (
    <Page className={classes.root} title="Builder">
      <Container>
        { /* Topbar */}
        <Card>
          <CardHeader title={(
            <Typography className={classes.pageTitle} variant="h2">
              {trans('builder.title')}
            </Typography>
          )}
          />
          <CardContent>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <TextField
                  label={trans('builder.name')}
                  variant="outlined"
                  fullWidth
                  InputProps={{ id: 'asset-title' }}
                  placeholder={trans('builder.name')}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  error={errorTitle}
                  helperText={errorTitle ? trans('builder.noTitleMessage') : undefined}
                />
              </Grid>
              <Grid item sm={12} className={classes.gridBreak} />
              <Grid item>
                <Typography className={classes.sectionTitle}>{trans('builder.viewType')}</Typography>
                <FormControl>
                  <RadioGroup row value={type} onChange={handleChangeType}>
                    <FormControlLabel
                      value={DisplayType.VIDEO_SCALING}
                      control={<Radio />}
                      disabled={false}
                      label="Video Scaling"
                    />
                    <FormControlLabel
                      value={DisplayType.OVERLAY}
                      control={<Radio />}
                      disabled={false}
                      label="Overlay"
                    />
                    <FormControlLabel
                      value={DisplayType.ANIMATED}
                      control={<Radio />}
                      label="Animated Overlay"
                    />
                    <FormControlLabel
                      value={DisplayType.INTERACTIVE_VIDEO_SCALING}
                      control={<Radio />}
                      disabled={isActionCreative}
                      label="Interactive Video Scaling"
                    />
                    <FormControlLabel
                      value={DisplayType.INTERACTIVE_OVERLAY}
                      control={<Radio />}
                      disabled={isActionCreative}
                      label="Interactive Overlay"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item sm={12} className={classes.gridBreak} />
              {/* File upload dialog */}
              {type === DisplayType.INTERACTIVE_VIDEO_SCALING || type === DisplayType.INTERACTIVE_OVERLAY ? (
                <Grid item sm={8} xs={12}>
                  <TextField
                    label="URL"
                    variant="outlined"
                    fullWidth
                    placeholder="url"
                    value={interURLText}
                    onChange={(e) => setInterURLText(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            onClick={() => setInterURL(interURLText)}
                            startIcon={<RefreshIcon />}
                            size="small"
                          >
                            Ricarica
                          </Button>
                        </InputAdornment>
                      ),
                      startAdornment: interURLText !== '' ? (
                        <InputAdornment position="start">
                          <IconButton
                            onClick={() => setInterURLText('')}
                            size="small"
                          >
                            <CloseIcon style={{ fontSize: 16 }} />
                          </IconButton>
                        </InputAdornment>
                      ) : undefined
                    }}
                  />
                </Grid>
              ) : (
                <Grid item sm={6}>
                  <Typography className={classes.sectionTitle}>{trans('builder.select')}</Typography>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={errorFile}
                  >
                    <OutlinedInput
                      type="text"
                      readOnly
                      fullWidth
                      style={errorFile ? { color: 'red' } : undefined}
                      value={fileName}
                      endAdornment={(
                        <InputAdornment position="end">
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => uploadInputRef.current?.click()}
                          >
                            {trans('Seleziona')}
                          </Button>
                        </InputAdornment>
                      )}
                    />
                    <input
                      ref={uploadInputRef}
                      type="file"
                      accept={type === DisplayType.ANIMATED ? 'application/json' : 'image/*'}
                      style={{ display: 'none' }}
                      onChange={type === DisplayType.ANIMATED ? handleLoadAnimated : handleLoadImage}
                    />
                    {errorFile ? (<FormHelperText>Devi caricare un asset!</FormHelperText>) : undefined}
                  </FormControl>
                </Grid>
              )}
              <Grid item sm={12} className={classes.gridBreak} />
              {config.tags
                ? (
                  <Grid item sm={6} md={4}>
                    <TextField
                      label="Tags"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      placeholder="Tags"
                      select
                      onChange={(e) => setSelectedTags(selectedTags.concat(e.target.value))}
                    >
                      {tags.filter((t) => !selectedTags.includes(t)).map((tag) => (
                        <MenuItem key={tag} value={tag}>{tag}</MenuItem>
                      ))}
                    </TextField>
                    <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap">
                      {selectedTags.map((t) => (
                        <Chip
                          key={t}
                          label={t}
                          onDelete={() => setSelectedTags(selectedTags.filter((tag) => tag !== t))}
                          size="small"
                          style={{ marginLeft: 2, marginRight: 2, marginBottom: 4 }}
                        />
                      ))}
                    </Box>
                  </Grid>
                )
                : undefined}
              <Grid item sm={6} md={4}>
                <TextField
                  label={trans('Filtro vendor')}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder="Vendor"
                  select
                  onChange={(e) => setSelectedVendors(selectedVendors.concat(e.target.value))}
                >
                  {vendors.filter((v) => !selectedVendors.includes(v.value)).map((vendor) => (
                    <MenuItem key={vendor.value} value={vendor.value}>{vendor.name}</MenuItem>
                  ))}
                </TextField>
                <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap">
                  {vendors.filter((v) => selectedVendors.includes(v.value)).map((vendor) => (
                    <Chip
                      key={vendor.value}
                      label={vendor.name}
                      onDelete={() => setSelectedVendors(selectedVendors.filter((ven) => ven !== vendor.value))}
                      size="small"
                      style={{ marginLeft: 2, marginRight: 2, marginBottom: 4 }}
                    />
                  ))}
                </Box>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              {/* Editor toolbar */}
              {type === DisplayType.VIDEO_SCALING || type === DisplayType.INTERACTIVE_VIDEO_SCALING ? (
                <Grid item sm={2} />
              ) : undefined}
              <Grid item sm={type === DisplayType.OVERLAY || type === DisplayType.ANIMATED || type === DisplayType.INTERACTIVE_OVERLAY ? 6 : 2} style={{ paddingBottom: 0 }}>
                <FormLabel style={{
                  fontSize: 14, textAlign: 'center', marginLeft: 5
                }}
                >
                  {type === DisplayType.OVERLAY || type === DisplayType.ANIMATED ? trans('Dimensioni Banner') : trans('Dimensioni Video')}
                </FormLabel>
              </Grid>
              <Grid item sm={4} style={{ paddingBottom: 0 }}>
                <FormLabel style={{
                  fontSize: 14, textAlign: 'center', marginLeft: 5,
                }}
                >
                  {type === DisplayType.OVERLAY || type === DisplayType.ANIMATED || type === DisplayType.INTERACTIVE_OVERLAY ? trans('Posizione Banner') : trans('Posizione Video')}
                </FormLabel>
              </Grid>
              <Grid item sm={type === DisplayType.OVERLAY || type === DisplayType.ANIMATED || type === DisplayType.INTERACTIVE_OVERLAY ? 2 : 4} />

              {type === DisplayType.OVERLAY || type === DisplayType.ANIMATED || type === DisplayType.INTERACTIVE_OVERLAY ? (
                <Grid item sm={2}>
                  <TextField
                    fullWidth
                    label={trans('Altezza')}
                    type="number"
                    variant="outlined"
                    value={height}
                    disabled={type === DisplayType.INTERACTIVE_OVERLAY}
                    onChange={handleChangeHeight}
                    size="small"
                  />
                </Grid>
              ) : undefined}
              {type === DisplayType.OVERLAY || type === DisplayType.ANIMATED || type === DisplayType.INTERACTIVE_OVERLAY ? (
                <Grid item sm={2}>
                  <TextField
                    fullWidth
                    label={trans('Larghezza')}
                    type="number"
                    variant="outlined"
                    value={width}
                    onChange={handleChangeWidth}
                    disabled={type === DisplayType.INTERACTIVE_OVERLAY}
                    size="small"
                  />
                </Grid>
              ) : undefined}
              {type === DisplayType.OVERLAY || type === DisplayType.ANIMATED || type === DisplayType.INTERACTIVE_OVERLAY ? (
                <Grid item sm={2}>
                  <FormControlLabel
                    label={<FormLabel style={{ fontSize: 12 }}>{trans('Fissa Proporzione')}</FormLabel>}
                    control={(
                      <Switch
                        size="small"
                        checked={lockRatio}
                        onChange={(e) => setLockRatio(e.target.checked)}
                        disabled={type === DisplayType.INTERACTIVE_OVERLAY}
                      // disabled={type === TYPES.VIDEO_SCALING}
                      />
                    )}
                  />
                </Grid>
              ) : undefined}
              {type === DisplayType.VIDEO_SCALING || type === DisplayType.INTERACTIVE_VIDEO_SCALING ? (
                <Grid item sm={2}>
                  <TextField
                    fullWidth
                    label={trans('Scala')}
                    type="number"
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                    value={scaleRate}
                    onChange={handleChangeResizeRate}
                    size="small"
                  />
                </Grid>
              ) : undefined}

              <Grid item sm={2}>
                <TextField
                  fullWidth
                  label="X"
                  type="number"
                  variant="outlined"
                  value={x}
                  onChange={handleChangeX}
                  disabled={type === DisplayType.INTERACTIVE_OVERLAY}
                  size="small"
                />
              </Grid>
              <Grid item sm={2}>
                <TextField
                  fullWidth
                  label="Y"
                  type="number"
                  variant="outlined"
                  value={y}
                  onChange={handleChangeY}
                  disabled={type === DisplayType.INTERACTIVE_OVERLAY}
                  size="small"
                />
              </Grid>
              <Grid item sm={2}>
                <Box>
                  <Button
                    style={{ fontSize: 9, textTransform: 'capitalize', textAlign: 'left' }}
                    startIcon={<VerticalAlignCenterIcon />}
                    onClick={handleCenterVertically}
                    disabled={type === DisplayType.INTERACTIVE_OVERLAY}
                    size="small"
                  >
                    {trans('Centra verticalmente')}
                  </Button>
                  <Button
                    style={{ fontSize: 9, textTransform: 'capitalize', textAlign: 'left' }}
                    startIcon={<FormatAlignCenterIcon />}
                    onClick={handleCenterHorizontally}
                    disabled={type === DisplayType.INTERACTIVE_OVERLAY}
                    size="small"
                  >
                    {trans('Centra orizzontalmente')}
                  </Button>
                </Box>
              </Grid>

              {/* Draggable screen editor */}
              <Grid item xs={12} container alignItems="center" justifyContent="center">
                <Grid item xs={12} md={9}>
                  <ScreenWidget
                    type={type}
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    image={image}
                    animationJSON={animationJSON}
                    onPositionChange={
                      (newX, newY) => {
                        if (type === DisplayType.INTERACTIVE_OVERLAY) return;
                        const diffX = newX + width - screenSize.width;
                        const diffY = newY + height - screenSize.height;
                        if (diffX > 0) setWidth(width - diffX);
                        if (diffY > 0) setHeight(height - diffY);
                        setX(newX);
                        setY(newY);
                      }
                    }
                    onSizeChange={
                      (newWidth, newHeight) => {
                        if (type === DisplayType.INTERACTIVE_OVERLAY) return;
                        setWidth(newWidth);
                        setHeight(newHeight);
                        setScaleRate(Math.round((newWidth / screenSize.width + newHeight / screenSize.height) * 50));
                      }
                    }
                    lockRatio={lockRatio}
                    frameURL={interURL}
                  />
                </Grid>
              </Grid>
              {/*
              <Grid item md={6} sm={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="channels-select">Canali</InputLabel>
                  <Select
                    variant="outlined"
                    label="Canali"
                    id="channels-select"
                    onChange={handleSelectChannel}
                    fullWidth
                  >
                    {channels.filter((c) => !chosedChannels.includes(c)).map((c) => (
                      <MenuItem key={c.name} value={c.id}>{c.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {chosedChannels.map((c) => (
                  <Chip
                    key={c.name}
                    label={c.name}
                    style={{ margin: 10 }}
                    onDelete={() => {}}
                  />
                ))}
              </Grid>
              */}

              <Grid item>
                <Fab
                  disabled={isLoading}
                  onClick={submitForm}
                  size="large"
                  variant="extended"
                  color="primary"
                >
                  <PublishIcon className={classes.extIcon} />
                  Upload asset
                </Fab>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default AssetBuilder;
