import createCache from '@emotion/cache';
import firebase from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './context/auth';
import * as serviceWorker from './serviceWorker';
import './utils/i18n';

let firebaseConfig;

try {
  firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
} catch {
  firebaseConfig = {
    databaseURL: 'https://panel-sse.firebaseio.com',
    apiKey: "AIzaSyAh8DrT0uyskrUk97kruBTBPy4Z-ufRh8o",
    authDomain: "karp-lac.firebaseapp.com",
    projectId: "karp-lac",
    storageBucket: "karp-lac.appspot.com",
    messagingSenderId: "375486285018",
    appId: "1:375486285018:web:8767650455f795e04411e3"
  };
}

firebase.initializeApp(firebaseConfig);

// eslint-disable-next-line react/prop-types
// const AuthComponent = ({ children }) => {
//   const [pending, setPending] = useState(true);

//   useEffect(() => {
//     const unregisterObserver = firebase.auth().onAuthStateChanged(() => {
//       setPending(false);
//     });

//     return () => unregisterObserver();
//   }, []);

//   return pending ? null
//     : (
//       <>
//         {children}
//       </>
//     );
// };

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

ReactDOM.render((
  <BrowserRouter>
    <AuthProvider>
      <App />
    </AuthProvider>
  </BrowserRouter>
), document.getElementById('root'));

serviceWorker.unregister();
