import BarChartIcon from '@mui/icons-material/BarChart';
import CreateIcon from '@mui/icons-material/Create';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import TvIcon from '@mui/icons-material/Tv';
import { Box, Drawer as MuiDrawer, List } from '@mui/material';
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import {
  Image as ImageIcon
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';
import Logo from '../../../components/Logo';
import NavItem from './NavItem';


export const DRAWER_WIDTH = 256;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: 0
  }
}));

const items = [
  {
    href: '/app/dashboard',
    icon: <BarChartIcon size={25} />,
    title: 'Dashboard',
  },
  {
    href: '/app/channels',
    icon: <TvIcon size={25} />,
    title: 'Canali',
  },
  {
    href: '/app/creatives',
    icon: <ImageIcon size={25} />,
    title: 'Creatives',
  },
  {
    href: '/app/campaigns',
    icon: <HorizontalSplitIcon size={25} />,
    title: 'Campagne',
  },
  {
    href: '/app/wizard',
    icon: <CreateIcon size={25} />,
    title: 'Wizard',
  }
];

const openedMixin = (theme) => ({
  width: [DRAWER_WIDTH, '!important'],
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiPaper-root': {
    backgroundColor: 'rgba(18, 18, 18, 0.4)',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)'
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const NavBar = ({
  open,
  onMouseEnter,
  onMouseLeave
}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const matchLocation = useCallback((path) => (path ? !!matchPath({ path, end: false }, pathname) : false), [pathname]);

  const content = (
    <>
      <DrawerHeader>
        <Logo />
      </DrawerHeader>
      <Box>
        <List disablePadding>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={t(item.title)}
              icon={item.icon}
              isActive={matchLocation}
            />
          ))}
        </List>
      </Box>

      <Box sx={{ flexGrow: 1 }} />
    </>
  );

  return (
    <RootStyle>
      <Drawer
        variant="permanent"
        anchor="left"
        PaperProps={{
          sx: {
            width: DRAWER_WIDTH,
          },
          onMouseEnter,
          onMouseLeave
        }}
        open={open}
      >
        {content}
      </Drawer>
    </RootStyle>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false,
};

export default NavBar;
